.methodConent {
    border: 1px solid #e6e8ec;
    border-radius: 4px;
    padding: 15px 24px 26px;
    margin-top: 25px;
    .methodTitle,
    .tableHead,
    .tableBody {
        display: flex;
        justify-content: space-between;
        li {
            width: 25%;
        }
    }
    .tableHead {
        color: #707a8a;
        margin-bottom: 14px;
    }
    .Title,
    .ALIPAYTitle,
    .WECHATTitle {
        display: flex;
        justify-content: space-between;
        margin-bottom: 41px;
        color: #1e2129;
        i {
            display: inline-block;
            width: 2px;
            height: 14px;
            background: #ffd140;
            vertical-align: middle;
            margin-right: 10px;
        }
        span {
            vertical-align: middle;
        }
        > div:nth-child(2) {
            span {
                cursor: pointer;
            }
        }
    }
    .ALIPAYTitle {
        i {
            background: #01a9f2;
        }
    }
    .WECHATTitle {
        i {
            background: green;
        }
    }
    .ALIPAY,
    .WECHAT {
        .tableHead,
        .tableBody {
            justify-content: flex-start;
        }
    }
}

.protalImage{
  position: relative;
  .closeBtn{
    position: absolute;
    right: 0;
    top: 0;
    display: block;
    background-color: #CCC;
    padding: 0.15rem 0.7rem;
    font-size: 1.3rem;
  }
}
@mixin tooltip {
  visibility: visible;
  opacity: 0;
  transform: scaleY(0);
  background-color: #23262f;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  transform-origin: top;
  transition: visibility 300ms, transform 300ms, opacity 300ms ease-out;
  position: absolute;
  top: calc(100% + 10px);
  z-index: 999;
  padding: 18px 12px;

  &:after {
    position: absolute;
    content: '';
    bottom: 100%;
    width: 0;
    height: 0;
  }
}

.showCenter {
  justify-content: center;
}

.showLeft {
  justify-content: left;
}

.showRight {
  justify-content: right;
}

.container {
  position: relative;
  display: flex;

  .tooltip {
    @include tooltip;
    &:after {      
      left: calc(50% - 5px);
    }
  }
  
  &:hover {
    .tooltip {
      visibility: visible;
      opacity: 1;
      transform: scaleY(1);
    }
  }

  .special {
    @include tooltip;
    background: #FFFFFF;
    width: min-content;
    height: min-content;
    padding: 0px;
    padding: 6px 18px;
    &:after {      
      left: calc(50% - 5px);
    }
  }
  
  &:hover {
    .special {
      visibility: visible;
      opacity: 1;
      transform: scaleY(1);
    }
  }

}

.feesDetailsDropDown {
  background-color: #fff;
  color: #23262f;
}
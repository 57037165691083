@import './../../style/color.scss';
.stage-commissionAccount{
    min-height: 880px;
    padding-left: 20px;
    padding-right: 20px;
    background: #fff;
    .ca-title{
        font-size: 32px;
        font-weight: 500;
        color: $fc6;
        padding-top: 32px;

        display: flex;
        justify-content: space-between;
        .ca-title-func{
            display: flex;
            align-items: center;
            .ca-title-func-btn{
                min-width: 88px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                border-radius: 4px;
                font-size: 14px;
                font-weight: 500;
                margin-left: 24px;
                cursor: pointer;
            }
            .ca-title-func-btn.exchange{
                color: $fc6;
                background: #FFE07F;
            }
            .ca-title-func-btn.exchange:hover{
                background: $active;
            }
            .ca-title-func-btn.history{
                color: $fc5;
                background: $bg11;
            }
        }
    }
    .ca-listBox{
        margin-top: 32px;
        .ca-listBox-list-row-td-wrapper{
            display: flex;
            font-size: 14px;
            font-weight: 500;
            .ca-listBox-list-row-td-wrapper-image{
                width: 24px;
                height: 24px;
                margin-right: 14px;
                img{
                    width: 100%;
                }
            }
            .ca-listBox-list-row-td-wrapper-text{
                display: flex;
                align-items: center;
            }
        }
    }
}
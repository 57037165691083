$bg1: #141416;
$bg2: #181A20;
$bg3: #15161A;
$bg4: #23262F;
$bg5: #353945;
$bg6: #242730;
$bg7: #777E90;
$bg8: #1E2129;
$bg9: #E6E8EC;
$bg10: #848E9C;
$bg11: #E8ECF1;

$fc1: #777E90;
$fc2: #E6E8EC;
$fc3: #494E5B;
$fc4: #353945;
$fc5: #1E2129;
$fc6: #181A20;
$fc7: #848E9C;

$lc1: #23262F;
$lc2: #181A20;
$lc3: #353945;
$lc4: #E6E8EC;
$lc5: #777E90;
$lc6: #1E2129;
$lc7: #E8ECF1;

$active: #FFD140;
$activeBlue: #48CDEE;
$raise: #02C076;
$fall: #F84960;

@import './../../style/color.scss';
.stage-followManagement{
    min-height: 920px;
    .fm-title{
        margin-left: -18px;
        margin-right: -20px;
        height: 88px;
        line-height: 88px;
        background: #fff;
        font-size: 32px;
        font-weight: 500;
        color: $fc5;
        padding-left: 20px;
    }
    .fm-infoBox{
        display: flex;
        justify-content: space-between;
        padding: 0 20px;
        .fm-infoBox-left{
            display: flex;
            .fm-infoBox-left-block{
                margin-right: 40px;
                .fm-infoBox-left-block-title{
                    color: $fc1;
                    font-size: 14px;
                    font-weight: 400;
                }
                .fm-infoBox-left-block-value{
                    color: $fc5;
                    font-family: DIN-Medium;
                    font-size: 24px;
                    font-weight: 500;
                }
            }
        }
        .fm-infoBox-right{
            display: flex;
            align-items: center;
            .fm-infoBox-right-btn{
                font-size: 14px;
                font-weight: 400;
                min-width: 80px;
                padding: 0 5px;
                height: 32px;
                line-height: 32px;
                text-align: center;
                border-radius: 4px;
                margin-left: 20px;
            }
            .first{
                color: $fc5;
                background: $active;
            }
            .second{
                color: $active;
                border: 1px solid $active;
            }
        }
    }
    .fm-listBox{
        border-top: 20px solid #fafafa;
        .fm-listBox-cat{
            height: 60px;
            display: flex;
            padding-left: 20px;
            background: #fff;
            .fm-listBox-cat-btn{
                color: #858e9d;
                font-size: 12px;
                font-weight: 700;
                cursor: pointer;
                margin-right: 20px;
                display: flex;
                align-items: center;
            }
            .fm-listBox-cat-btn.active{
                color: $active;
            }
        }
        .fm-listBox-title{
            text-align: center;
        }
        .fm-listBox-row{
            .fm-listBox-row-wrapper{
                .fm-listBox-row-wrapper-image{
                    width: 32px;
                    height: 32px;
                    border-radius: 32px;
                    overflow: hidden;
                    margin: auto;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
                .fm-listBox-row-wrapper-text{
                    font-size: 14px;
                    font-weight: 500;
                }
                .fm-listBox-row-wrapper-btn{
                    width: 50px;
                    height: 24px;
                    border: 1px solid #e5e5e5;
                    margin: auto;
                    cursor: pointer;
                }
                .ta-center{
                    text-align: center;
                }
            }
        }
    }
}
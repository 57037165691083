@import './../../style/color.scss';
.stage-bringSetting{
    min-height: 920px;
    .bs-bringSwitchBox{
        padding: 16px 20px;
        .bs-bringSwitchBox-titleBox{
            display: flex;
            .bs-bringSwitchBox-titleBox-title{
                font-size: 16px;
                font-weight: 500;
                color: #101010;
            }
            .bs-bringSwitchBox-titleBox-switchBox{
                margin-left: 5px;
            }
        }
        .bs-bringSwitchBox-desc{
            margin-top: 10px;
            font-size: 12px;
            font-weight: 400;
            color: #858e9d;
        }
    }
}

.module-styleView{
    .sv-title{
        padding: 20px 0;
        margin: 0 20px;
    }
    .sv-systemListBox{
        display: flex;
        flex-wrap: wrap;
        padding-left: 20px;
        margin-bottom: 10px;
        .sv-systemListBox-cell{
            padding: 7px 13px;
            font-size: 14px;
            color: #858e9d;
            border: 1px solid #e5e5e5;
            margin-right: 10px;
            margin-bottom: 10px;
            border-radius: 14px;
            cursor: pointer;
        }
        .sv-systemListBox-cell.active{
            border: 0;
            background-color: #faf7f2;
            color: $active;
        }
    }
    .sv-tips{
        margin: 0 20px;
        margin-bottom: 5px;
        font-size: 12px;
        color: #858e9d;
    }
    .sv-line{
        height: 1px;
        background: #f7f7f7;
        margin: 30px 0 10px;
    }
    .sv-myListBox{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 0 20px;
        .sv-myListBox-cell{
            width: 48%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 10px;
            border-radius: 4px;
            height: 45px;
            font-size: 14px;
            padding: 0 25px;
            .sv-myListBox-cell-text{}
            .sv-myListBox-cell-remove{
                cursor: pointer;
            }
        }
        .sv-myListBox-cell:nth-child(1) {
            color: #46CD93;
            background: #BEEED9;
        }
        .sv-myListBox-cell:nth-child(2) {
            color: #655BE6;
            background: #E2E0FA;
        }

        .sv-myListBox-cell:nth-child(3) {
            color: #FDBA45;
            background: #FFF2DC;
        }

        .sv-myListBox-cell:nth-child(4) {
            color: #F9392F;
            background: #FEDEDC;
        }
    }
}

.module-ideaView{
    .iv-title{
        color: #333;
        font-size: 16px;
        height: 56px;
        line-height: 56px;
        font-weight: 500;
        border-bottom: 1px solid #f7f7ff;
        padding: 0 25px;;
    }
    .iv-listBox{
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;
        padding: 0 25px;;
        .iv-listBox-cell{
            display: flex;
            width: 50%;
            margin-bottom: 40px;
            cursor: pointer;
            .iv-listBox-cell-checkBox{
                display: inline-block;
            }
            .iv-listBox-cell-content{
                display: inline-block;
                font-size: 14px;
                margin-left: 15px;
                height: 100%;
            }
        }
    }
    .iv-btn{
        width: 170px;
        height: 50px;
        background: #ffd140;
        line-height: 50px;
        text-align: center;
        color: #101010;
        margin: 0 auto 20px;
        border-radius: 4px;
        cursor: pointer;
    }
}
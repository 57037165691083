@import './../../style/color.scss';
.stage-channelManagement{
    padding-left: 20px;
    padding-right: 20px;
    background: #fff;
    .cm-title{
        font-size: 32px;
        font-weight: 500;
        color: $fc6;
        padding-top: 32px;;
    }
    .cm-createBox{
        display: flex;
        margin-top: 32px;
        margin-bottom: 32px;
        .cm-createBox-block{
            margin-right: 24px;
            .cm-createBox-block-title{
                font-size: 12px;
                font-weight: 400;
                color: $fc7;
            }
            .cm-createBox-block-wrapper{
                display: flex;
                align-items: center;
                margin-top: 12px;
                border: 1px solid #babdc2;
                border-radius: 4px;
                height: 40px;
                line-height: 40px;
                padding: 10px 8px;
                min-width: 244px;
                .cm-createBox-block-wrapper-label{
                    line-height: 1;
                    flex: 1;
                    font-size: 14px;
                    font-weight: 400;
                    color: $fc6;
                }
                .cm-createBox-block-wrapper-image{
                    margin-left: 4px;
                    cursor: pointer;
                }
                .cm-createBox-block-wrapper-inputBox{
                    flex: 1;
                    input{
                        box-shadow: none;
                        outline: none;
                        border: 0;
                        padding: 0;
                    }
                }
            }
            .cm-createBox-block-btn{
                min-width: 88px;
                height: 40px;
                line-height: 40px;
                font-size: 14px;
                font-weight: 500;
                color: $fc6;
                background: $active;
                margin-top: 30px;
                border-radius: 4px;
                text-align: center;
                cursor: pointer;
            }
        }
    }
    .cm-row{
        .cm-row-td-ru{
            display: flex;
            .cm-row-td-ru-image{
                display: flex;
                align-items: center;
                margin-left: 6px;
                cursor: pointer;
            }
            .cm-row-td-ru-content{
                font-family: DIN-Regular;
            }
        }
        .cm-row-td-remove{
            display: block;
            cursor: pointer;
            fill: $bg7;
            .C-Svg {
                display: block;
            }
        }
        .cm-row-td-remove:hover{
            fill: $active;
        }
    }
}
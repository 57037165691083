.personalInfo {
  width: 100%;
  height: 104px;
  padding: 0 calc((100% - 1200px) / 2);
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fafafa;

  .flex {
    display: flex;
    align-items: center;
  }

  .left {
    @extend .flex;
    display: flex;

    .personalName {
      font-weight: bold;
    }
    .dot {
      margin: 0 10px;
      font-size: 10px;
      display: flex;
      align-items: center;
      .redDot {
        height: 10px;
        width: 10px;
        background-color: #F84960;
        border-radius: 50%;
      }
      .greenDot {
        height: 10px;
        width: 10px;
        background-color: #02C076;
        border-radius: 50%;
      }
    }

    .personalImage {
      width: 38px;
      height: 38px;
      line-height: 38px;
      border-radius: 19px;
      background: #f1f1f1;
      text-align: center;
      font-size: 15px;
      margin-right: 10px;
    }

    .lineBox {
      display: flex;

      &:last-child {
        margin-top: 10px;
      }

      .edit {
        margin-left: 10px;
        margin-right: 10px;
        cursor: pointer;
      }

      .validIcon {
        margin-left: 5px;
      }
    }

    .kycBoxText {
      margin-left: 1rem;
      padding: 0 8px;
      height: 20px;
      background: #fef6d8;
      border-radius: 2px;
      color: #c99400;
      font-size: 12px;
    }
  }

  .right {
    @extend .flex;
    flex-direction: column;

    .lineBox {
      display: flex;
      align-self: flex-end;
      font-family: sans-serif;

      a{
        color: #000;
        text-decoration: underline;
        margin-left: 0.5rem;
      }

      &:last-child {
        margin-top: 10px;
      }

      .edit {
        margin-left: 10px;
        margin-right: 10px;
        cursor: pointer;
      }

      .dataValidBox {
        display: flex;
        color: #707a8a;

        &:not(:last-child) {
          margin-right: 20px;
        }

        .validIcon {
          margin-left: 5px;
        }
      }
    }

    .top {
      @extend .flex;

      img {
        cursor: pointer;
      }
    }

    span {
      &.title {
        color: #707a8a;
        margin-right: 5px;
      }

      &.money {
        font-size: 20px;
        margin-right: 5px;
      }
    }

    .rightArrow {
      margin-left: 10px;
      cursor: pointer;
    }

    .button {
      width: 94px;
      height: 40px;
      line-height: 40px;
      background: #ffd140;
      border-radius: 4px;
      text-align: center;
      margin-left: 40px;
      cursor: pointer;
    }
  }
}

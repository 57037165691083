.moduleCFind {
    .flex {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    h3 {
        font-size: 16px;
        margin-bottom: 24px;
    }
    .hintLine {
        @extend .flex;
        justify-content: space-between;
    }
    p {
        width: 739px;
        line-height: 22px;
        color: #707a8a;
    }
    .addbutton {
        @extend .flex;
        width: 260px;
        height: 40px;
        background: #eaecef;
        border-radius: 4px;
        cursor: pointer;
        span {
            &.addicon {
                margin-top: -4px;
                font-size: 30px;
                margin-right: 10px;
            }
        }
    }
}

.dropDownRow {
    display: flex !important;
    align-items: center;
    img {
        margin-right: 10px;
    }
}
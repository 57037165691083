@import './../../style/color.scss';
.stage-asset{
    background: #fafafa;
    height: 100%;
    .a-titleBox{
        background: #fff;
        height: 88px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        .a-titleBox-left{
            display: flex;
            align-items: center;
            .a-titleBox-left-title{
                font-size: 32px;    
                font-weight: 500;
                color: $fc5;
                margin-left: 42px;
            }
            .a-titleBox-left-hide{
                display: flex;
                border: 1px solid $lc4;
                border-radius: 4px;
                padding: 7px 9px;
                margin-left: 16px;
                cursor: pointer;
                .a-titleBox-left-hide-image{}
                .a-titleBox-left-hide-text{
                    font-size: 12px;
                    color: $fc5;
                    font-weight: 400;
                    line-height: 1;
                    color: $fc7;
                    margin-left: 5px;
                }
            }
        }
        .a-titleBox-right{
            display: flex;
            align-items: center;
            margin-right: 30px;
            .a-titleBox-right-buttons{
                .a-titleBox-right-buttons-btn{
                    font-size: 14px;
                    color: $fc5;
                    font-weight: 500;
                    padding: 7px 16px;
                    border: 1px solid $lc4;
                    border-radius: 4px;
                    margin-left: 16px;
                }
                .a-titleBox-right-buttons-btn.buy{
                    background: $active;
                }
                .a-titleBox-right-buttons-btn.transfer{
                    cursor: pointer;
                    background: transparent;
                }
            }
            .a-titleBox-right-record{
                font-size: 14px;
                color: $active;
                font-weight: 500;
            }
            .a-titleBox-right-line{
                height: 18px;
                width: 1px;
                margin: 0 16px;
                background: $bg9;
            }
        }
        
    }
    
    .a-data{
        background: #fff;
        border-radius: 6px;
        margin: 20px 20px 0;
        display: flex;
        height: 112px;
        .a-data-block{
            display: flex;
            flex: 1;
            flex-direction: column;
            justify-content: center;
            margin-left: 30px;
            .a-data-block-title{
                font-size: 14px;
                color: $fc7;
                font-weight: 400;
                line-height: 1;
            }
            .a-data-block-content{
                font-size: 32px;
                color: $fc5;
                font-weight: 500;
                line-height: 1;
                margin-top: 16px;
                font-family: DIN-Medium;
                span{
                    font-size: 14px;
                    line-height: 1;
                    font-weight: 500;
                    color: $fc7;
                }
            }
        }
    }
    .a-listBox{
        padding: 0 30px;
        margin: 20px 20px 0;
        background: #fff;
        .a-listBox-header{
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 80px;
            .a-listBox-header-left{
                display: flex;
                .a-listBox-header-left-input{}
                .a-listBox-header-left-btn{
                    padding: 8px 16px;
                    background: $active;
                    font-size: 14px;
                    color: $fc5;
                    font-weight: 500;
                    border-radius: 4px;
                    line-height: 1;
                    margin-left: 10px;
                }
            }
            .a-listBox-header-right{
                display: flex;
                .a-listBox-header-image{
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                }
                .a-listBox-header-text{
                    margin-left: 10px;
                }
            }
        }
        .a-listBox-list{
            // table header 样式
            .ant-table-thead{
                th{
                    padding: 0;
                    .a-listBox-list-title{
                        font-size: 12px;
                        line-height: 1;
                        font-weight: 400;
                        color: $fc7;
                        height: 44px;
                        line-height: 44px;
                        margin-left: 30px;
                    }
                }
                .ant-table-row-cell-last{
                    text-align: right;
                    padding-right: 30px;
                }
            }
            // 币种样式
            .a-listBox-list-nameBox{
                display: flex;
                align-items: center;
                margin-left: 14px;
                .a-listBox-list-nameBox-image{
                    width: 24px;
                    height: 24px;
                    svg, img{
                        width: 100%;
                    }
                }
                .a-listBox-list-nameBox-textBox{
                    margin-left: 10px;
                    .a-listBox-list-nameBox-textBox-name{
                        font-size: 14px;
                        color: $fc5;
                        font-weight: 500;
                        line-height: 1;
                        font-family: DIN-Medium;
                    }
                    .a-listBox-list-nameBox-textBox-desc{
                        font-size: 12px;
                        color: $fc7;
                        font-weight: 400;
                        line-height: 1;
                        margin-top: 5px;
                        max-width: 200px;
                        text-wrap: balance;
                        font-family: DIN-Regular;
                    }
                }
            }
            // 数量样式
            .a-listBox-list-total{
                margin-left: 14px;
                color: $fc5;
                font-size: 14px;
                font-weight: 500;
                font-family: DIN-Medium;
            }
            // 操作样式
            .a-listBox-list-action{
                text-align: right;
                margin-right: 14px;
                .a-listBox-list-action-btn{
                    font-size: 12px;
                    color: $active;
                    font-weight: 500;
                    line-height: 1;
                    margin-left: 20px;
                }
            }
        }
    }
    
}

.transfer-modal {
    font-family: 'PingFang SC';
    color: #141416;
    font-size: 14px;

    .transfer-title {
        text-align: center;
        font-size: 20px;
        margin-bottom: 20px;
        font-weight: 800;
    }

    .transfer-content {
        width: 440px;
    }

    .transfer-selectAccount {
        color: #141416;
        width: 441px;
        height: 50px;
        border: 1px solid #cecece;
        border-radius: 4px;
        margin-bottom: 20px;
        p {
            padding: 15px;
        }

        .ant-select {
            margin-top: 3%;
        }
    }

    .transfer-selectCurrency {
        @extend .transfer-selectAccount;
        margin-bottom: 5px;
        border: none;
        left: 1073px;

        .cashOut {
            border: 1px solid #CED4DA;
            border-radius: 4px;
            width: 100%;
            height: 48px;
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: space-between;
            padding: 0 15px;

            .input {
                font-size: 14px;
                height: 100%;
                width: 100%;
                background: transparent;

                &::placeholder {
                    font-size: 14px;
                    font-weight: 400;
                    color: #CCCCCC;
                }
            }

            .totalAll {
                cursor: pointer;
                display: inline-block;
                width: 30%;
                text-align: right;
                color: $active;
                font-size: 0.97222rem;
                line-height: 40px;
            }
        }

    }

    .transfer-selectAmount {
        display: flex;
        justify-content: space-between;
        width: 441px;
        margin-bottom: 20px;
        .transfer-selectAmount-btn {
            width: 100px;
            height: 50px;
            font-size: 14px;
            text-align: center;
            cursor: pointer;
            border: 1px solid #cecece;
            border-radius: 4px;
            p {
                padding: 15px;
            }
            // &.active {
            //     border: 1.5px solid #FFD140;
            // }
        }
        .transfer-selectAmount-btn:hover{
            border: 1.5px solid #FFD140;
        }
    }

    .transfer-transferable {
        width: 440px;
        margin-bottom: 30px;
        .transfer-transferable-content{
            &.right {
                float: right;
            }
        }
    }

    .transfer-footer {
        text-align: center;
        font-size: 16px;
        height: 50px;
        width: 441px;
        .transfer-button {
            font-weight: 700;
            border-radius: 3px;
            background: $active;
            padding: 15px 20px;
            cursor: pointer;
            &.inactive {
                opacity: 0.3;
                cursor: initial;
            }
        }
    }
}

.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: transparent;
}
@import './../../../style/color.scss';
.module-inviteView{
    width: 100%;
    height: 100%;
    padding: 0 20px;
    background: #fff;
    border-radius: 6px;
    .iv-header{
        display: flex;
        justify-content: space-between;
        .iv-header-text{
            font-size: 16px;
            font-weight: 500;
            color: $fc5;
            line-height: 1;
            margin: 20px 0;
        }
        .iv-header-image{}
    }
    .iv-inviteInfoBox{
        .iv-inviteInfoBox-row{
            display: flex;
            justify-content: space-between;
            height: 48px;
            background: #FAFAFA;
            padding: 0 20px;
            margin-bottom: 16px;
            .iv-inviteInfoBox-row-left{
                display: flex;
                align-items: center;
                font-size: 16px;
                font-weight: 400;
                color: #494E5B;
            }
            .iv-inviteInfoBox-row-right{
                display: flex;
                align-items: center;
                .iv-inviteInfoBox-row-right-content{
                    font-size: 16px;
                    font-family: DIN-Medium;
                    font-weight: 500;
                    color: black;
                }
                .iv-inviteInfoBox-row-right-image{
                    cursor: pointer;
                    margin-left: 13px;
                    display: flex;
                    align-items: center;
                }
            }
        }
    }
    .iv-showRatioBox{
        display: flex;
        padding-bottom: 20px;
        .iv-showRatioBox-block{
            background: #FEF6D8;
            flex: 1;
            height: 64px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .iv-showRatioBox-block-title{
                font-size: 12px;
                font-weight: 400;
                color: $fc1;
                line-height: 1;
            }
            .iv-showRatioBox-block-value{
                font-size: 24px;
                font-family: DIN-Bold;
                font-weight: 700;   
                color: $fc5;
                line-height: 1;
            }
        }
        .iv-showRatioBox-block:first-child,
        .iv-showRatioBox-block:nth-child(2){
            margin-right: 16px;
        }
    }
}
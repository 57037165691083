@import './../../style/color.scss';
.module-SLSPModal{
    display: flex;
    flex-direction: column;
    .slspm-title{
        font-size: 16px;
        color: $fc5;
        font-weight: 500;
        height: 70px;
        line-height: 70px;
        position: relative;
        padding: 0 24px;
    }
    .slspm-title::after{
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 1px;
        background: $bg9;
    }
    .slspm-body{
        padding: 0 24px;
        .slspm-body-cat{
            height: 42px;
            display: flex;
            position: relative;
            .slspm-body-cat-btn{
                font-size: 14px;
                color: $fc6;
                font-weight: 500;
                margin-right: 48px;
                display: flex;
                align-items: center;
                cursor: pointer;
            }
            .slspm-body-cat-btn.active{
                color: $fc5;
                border-bottom: 2px solid $active;
                z-index: 3;
            }
        }
        .slspm-body-cat::after{
            content: '';
            height: 2px;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            background: $bg9;
        }
    }
    .slspm-footer{
        display: flex;
        justify-content: space-between;
        padding: 0 24px 24px;
        margin-top: 20px;
        .slspm-footer-btn{
            width: 158px;
            height: 40px;
            font-size: 14px;
            line-height: 40px;
            font-weight: 500;
            color: $fc5;
            border-radius: 4px;
            text-align: center;
            cursor: pointer;
        }
        .slspm-footer-btn.cancel{
            color: $fc6;
            background: $bg9;
        }
        .slspm-footer-btn.save{
            background: $active;
        }

        .slspm-footer-btn.unableSave{
            background: $active;
            opacity: 0.5;
        }
    }
}
.module-SLSPModal.night{
    background: $bg2;
    .slspm-title{

        color: $fc2;
    }
    .slspm-title::after{
        background: #23262F;
    }
    .slspm-body{
        .slspm-body-cat{
            .slspm-body-cat-btn{
                color: $fc2;
            }
            .slspm-body-cat-btn.active{
                color: $fc2;
                border-bottom: 2px solid $active;
            }
        }
        .slspm-body-cat::after{
            background: #23262F;
        }
    }
    .slspm-footer{
        .slspm-footer-btn{
            color: $fc5;
        }
        .slspm-footer-btn.cancel{
            color: $fc2;
            background: $bg5;
        }
        .slspm-footer-btn.save{
            background: $active;
        }
    }
}
//input
.module-SLSPInputView{
    flex: 1;
    .SLSPIV-info{
        display: flex;
        justify-content: space-between;
        margin-top: 17px;
        .SLSPIV-info-title{
            display: flex;
            align-items: center;
            .SLSPIV-info-title-name{
                font-size: 16px;
                line-height: 1;
                font-weight: 500;
                color: $fc5;
                display: flex;
                align-items: center;
            }
            .SLSPIV-info-title-buy{
                font-size: 11px;
                line-height: 1;
                font-weight: 400;
                color: $fc2;
                height: 16px;
                margin-left: 16px;
                margin-right: 12px;
                display: flex;
                align-items: center;
                padding: 0 7px;
            }
            .SLSPIV-info-title-lever{
                font-size: 11px;
                line-height: 1;
                font-weight: 400;
                color: $fc2;
                height: 16px;
                margin-right: 12px;
                display: flex;
                align-items: center;
                padding: 0 7px;
            }
            .raise{
                background: $raise;
            }
            .fall{
                background: $fall;
            }
        }
        .SLSPIV-info-income{
            font-size: 16px;
            font-weight: 500;

        }
        .SLSPIV-info-income.raise{
            color: $raise;
        }
        .SLSPIV-info-income.fall{
            color: $fall;
        }
    }
    .SLSPIV-inputWrapper{
        margin-top: 20px;
        .SLSPIV-inputWrapper-title{
            font-size: 14px;
            color: $fc5;
            font-weight: 500;
        }
        .SLSPIV-inputWrapper-inputBox{
            border: 1px solid $lc4;
            border-radius: 4px;
            display: flex;
            align-items: center;
            height: 40px;
            margin-top: 10px;
            .SLSPIV-inputWrapper-inputBox-input{
                display: flex;
                flex: 1;
                .SLSPIV-inputWrapper-inputBox-input-title{
                    font-size: 14px;
                    color: $fc1;
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                    margin-left: 8px;
                }
                .SLSPIV-inputWrapper-inputBox-input-content{
                    flex: 1;
                    margin-left: 8px;
                    input{
                        color: $fc5;
                        font-size: 14px;
                        font-weight: 500;
                    }
                }
            }
            .SLSPIV-inputWrapper-inputBox-buttons{
                display: flex;
                .SLSPIV-inputWrapper-inputBox-buttons-btn{
                    width: 20px;
                    height: 20px;
                    color: $fc6;
                    background: $bg9;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    border-radius: 4px;
                    line-height: 1;
                    margin-right: 10px;
                }
            }
        }
        .SLSPIV-inputWrapper-dataBox{
            display: flex;
            justify-content: space-between;
            margin-top: 10px;
            .SLSPIV-inputWrapper-dataBox-data{
                font-size: 12px;
                color: $fc6;
                font-weight: 400;
                line-height: 1;
            }
        }
    }
}

.module-SLSPInputView.night{
    .SLSPIV-info{
        .SLSPIV-info-title{
            .SLSPIV-info-title-name{
                color: $fc2;
            }
            .SLSPIV-info-title-buy{
                color: $fc2;
            }
            .SLSPIV-info-title-lever{
                color: $fc2;
            }
            .raise{
                background: $raise;
            }
            .fall{
                background: $fall;
            }
        }
        // .SLSPIV-info-income{

        // }
        .SLSPIV-info-income.raise{
            color: $raise;
        }
        .SLSPIV-info-income.fall{
            color: $fall;
        }
    }
    .SLSPIV-inputWrapper{
        .SLSPIV-inputWrapper-title{
            color: $fc2;
        }
        .SLSPIV-inputWrapper-inputBox{
            border: 1px solid transparent;
            background: $bg4;
            .SLSPIV-inputWrapper-inputBox-input{
                .SLSPIV-inputWrapper-inputBox-input-title{
                    color: $fc1;
                }
                .SLSPIV-inputWrapper-inputBox-input-content{
                    color: $fc2;
                    input{
                        color: $fc2;
                        background: $bg4;
                        width:100%;
                    }
                }
            }
            .SLSPIV-inputWrapper-inputBox-buttons{
                display: flex;
                .SLSPIV-inputWrapper-inputBox-buttons-btn{
                    color: $fc2;
                    background: $bg5;
                }
            }
        }
        .SLSPIV-inputWrapper-dataBox{
            display: flex;
            justify-content: space-between;
            margin-top: 10px;
            .SLSPIV-inputWrapper-dataBox-data{
                font-size: 12px;
                color: $fc2;
                font-weight: 400;
                line-height: 1;
            }
        }
    }
}
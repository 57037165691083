$FONT_TITLE: #333;
$FONT_NAV: #C7C7CC;
$font_dark: #101010;
$FONT_LIGHT: #C7C7CC;
$FONT_FOOTER: #818181;

$LINE_COLOR: #ddd;

$ACTIVE_COLOR: #F3AD00;

$DARK_COLOR: #181A20;
$LIGHT_COLOR: #2C3940;
$white: #FFF;
$milk_white: #F5F5F5;

$fail_color: #CF4855;

$RAISE: #8AB152;
$FALL: #CD5A5B;

$theme: #1B262D;
$font: #C7C7CC;
$font1: #A7A8AC;
$active: #F3AD00;
$activeBlue: #48CDEE;

$raise: #2CBC70;
$fall: #CF4855;

$raise-btn: #2CBC70;
$fall-btn: #CF4855;
$theme: #181A20;
$theme-light: #FFFFFF;
$bg: #243048;
$bg-light: #CFCFCF;
$select: #1E2A3E;
$select-light: #EEEEEE;
$line: #2A3439;
$line-light: #EEEEEE;
$line-grey: #A7A7A7;
$font2: #232E34;
$font999: #999;
$fontccc: #CCCCCC;
$inputBorder: #CED4DA;

$lightGray: #858E9D;

$account-margin: 20px;


$raise-btn-reverse: transparentize($raise-btn, 0.1);
$fall-btn-reverse: transparentize($fall-btn, 0.1);

$headerHeight: 48px;
$baseWidth: 1440;
$baseHeight: 1024;
$baseSize: $baseWidth/100;

@mixin screen($res-max) {
  @media only screen and (min-width: #{$res-max}px) {
    html, body {
      font-size: 14px;
    }
  }
}

@function dynamic($val) {
  @if unitless($val) == true {
    @return #{$val/$baseHeight * 100}vh;
  } @else {
    @return #{($val / ($val * 0 + 1)) / $baseHeight * 100}vh;
  }
}

@function rem($val) {
  @if unitless($val) == true {
    @return #{$val/$baseSize}rem;
  } @else {
    @return #{($val / ($val * 0 + 1)) / $baseSize}rem;
  }
}

@mixin triangle($direction, $size, $tend, $color) {
  @content;
  width: 0;
  height: 0;
  @if $direction == top {
    border-bottom: $tend solid $color;
    border-left: $size solid transparent;
    border-right: $size solid transparent;
  } @else if $direction == right {
    border-left: $tend solid $color;
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
  } @else if $direction == bottom {
    border-top: $tend solid $color;
    border-left: $size solid transparent;
    border-right: $size solid transparent;
  } @else if $direction == left {
    border-right: $tend solid $color;
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
  }
}

@mixin rightTriangle($deg:45) {
  width: rem(10);
  height: rem(10);
  border: $lightGray solid;
  border-width: rem(1) rem(1) 0 0;
  transform: rotate($deg+deg)
}

@mixin flex-row($align:center) {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: $align;
  align-items: center;
}

@mixin flex-row-align($align:center) {
  @include flex-row();
  justify-content: $align;
}

@mixin flex-column($align:center) {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: $align;
}

@mixin flex-column-center() {
  @include flex-column();
  justify-content: center;
}

@mixin font-style($size:14,$weight:bold) {
  font-size: $size+px;
  font-weight: $weight;
}

@mixin font-lineHeight($size:14,$weight:normal) {
  font-size: $size+px;
  line-height: $size+px;
  font-weight: $weight;
}

.ant-tooltip-inner {
  background: #fff;
  color: #333;

}

.ant-tooltip-arrow::before {
  background: white;
}

.ant-input-number:hover {
  border-color: $active;
}

.ant-input-number-focused {
  border-color: transparent;
}

@mixin webp-jpg($url) {
  @at-root &.webp {
    background-image: url($url + '.webp');
  }
  @at-root &.org {
    background-image: url($url + '.jpg');
  }
}

@mixin webp-png($url) {
  @at-root &.webp {
    background-image: url($url + '.webp');
  }
  @at-root &.org {
    background-image: url($url + '.png');
  }
}

@mixin un-select() {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  cursor: default;
}

@mixin un-select-only() {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

@mixin un-wrap() {
  white-space: nowrap;
  word-break: keep-all;
}

//@mixin flex-row($align:center) {
//  display: flex;
//  width: 100%;
//  align-items: $align;
//}

//@mixin flex-column($align:center) {
//  @include flex-row();
//  flex-direction: column;
//  justify-content: $align;
//}

@mixin page-box() {
  margin: 0 $account-margin 20px $account-margin;
  box-shadow: 0 0 5px 0 #D1D1D1;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
}

@mixin modal-base() {
  .ant-modal-content {
    background: none;
  }
  .ant-btn:hover,
  .ant-btn:focus {
    color: white;
    border-color: $ACTIVE_COLOR;
  }

  .ant-modal-close-x {
    height: 44px;
    line-height: 44px;
  }

  .ant-modal-header {
    text-align: center;
    border: none;
    height: 44px;
    padding: 0;

    .ant-modal-title {
      height: 44px;
      line-height: 44px;
      font-weight: normal;
    }

  }

  .ant-modal-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    min-height: 120px;
    text-align: center;
  }
  .ant-modal-footer {
    border: none;

    div {
      display: flex;
      justify-content: center;
    }

    .ant-btn {
      width: 200px;
      height: 40px;
      line-height: 40px;
      font-size: 18px;
      font-weight: 500;
      border: none;
      margin: 0 15px;
    }
  }
}

@mixin modal-light() {
  .ant-modal-header {
    background: $active;

    .ant-modal-title {
      color: #fff;
    }
  }
  .ant-modal-body {
    background: #ffffff;
    color: #333333;
  }
  .ant-modal-footer {
    background: #ffffff;
  }
  .ant-btn {
    background: #EAEAEA !important;
    color: #6E6F72 !important;
  }
  .ant-btn-primary {
    background: #FFD140 !important;
    //border-color: #e2be30;
    color: #1E2129 !important;
  }
}

@mixin modal-night() {
  .ant-modal-header {
    background: $bg;

    .ant-modal-title {
      color: #ffffff;
    }
  }
  .ant-modal-body {
    background: $theme;
    color: #FFFFFF;
  }
  .ant-modal-footer {
    background: $theme;
  }
  .ant-btn {
    background: $bg !important;
    color: #FFFFFF !important;
  }
  .ant-btn-primary {
    background: #FFD140 !important;
    color:#1E2129 !important;
  }
}

@mixin emulate-input-night() {
  border-color: $bg;
  background: $theme;
  > label {
    color: #5E6469;
  }
  > input {
    color: #fff;
  }
  > .controller {
    .btn {
      border-left: 1px solid $bg;
      color: #C8C8C8;
    }
  }
}

@mixin common-crypto-title() {
  text-align: center;
  font-size: 22px;
  font-weight: 500;
  margin: 40px 0;
}

@mixin common-crypto-page() {
  display: flex;
  position: relative;
  margin-bottom: 20px;
  height: 50px;
  padding-right: 200px;
  //@at-root &.zh-CN {
  //  > .title {
  //    width: 100px;
  //  }
  //}
  > .title {
    text-align: right;
    height: 50px;
    line-height: 50px;
    margin-right: 30px;
    font-size: rem(16px);
    font-weight: 500;
    width: 170px;
  }

  > .content {
    width: 450px;

    > div {
      outline: none !important;
    }

    &.text-btn {
      @include common-button();
      text-align: center;
      font-size: 14px;
      color: $active;
      text-decoration: underline;
    }

    &.buttons {
      display: flex;
      justify-content: space-between;

      .btn {
        background: #F9F9F9;
        text-align: center;
        height: 44px;
        line-height: 44px;
        border: 1px solid #DDDDDD;
        width: 213px;
        font-size: 16px;
        @include common-button();
      }

      .active {
        background: $active;
        border: none;
      }
    }

    &.submit {
      border: 0;
      background: $active;
      height: 51px;
      line-height: 51px;
      font-size: 16px;
      border-radius: 5px;
      cursor: pointer;
      text-align: center;
      margin-top: 20px;
      @include common-button();

      &.error {
        background: #E4E4E4;
        color: #333333;
        @include un-select();
      }
    }

    .emulate-input {
      height: 100%;
      margin: 0;
      border-color: #DDDDDD;
      border-radius: 4px;

      &.exhibit {
        > input {
          text-indent: 42px;
        }
      }

      > label {
        left: 20px;
      }

      > input {
        color: #666666;
        font-size: 14px;
        text-indent: 20px;
      }

      .icon {
        position: absolute;
        left: 12px;
      }
    }

    .emulate-select-country {
      height: 50px;
      margin: 0;
      border-color: #DDDDDD;

      .emulate-select-options {
        top: 49px;
      }
    }

    .fakeInput {
      position: relative;
      height: 50px;
      line-height: 50px;
      background: #F9F9F9;
      font-size: 14px;
      color: #999999;
      padding-left: 20px;
    }
  }


}

@mixin common-button($radius:2) {
  @include un-select();
  border: none;
  border-radius: rem($radius);
  cursor: pointer;
  &:focus {
    outline: 0;
  }
}

@mixin common-button-animation() {
  &:hover {
    box-shadow: 0 2px 3px #f2f2f2;
  }
  &:active {
    box-shadow: 0 2px 3px #ececec inset;
  }
}

@mixin common-input() {
  display: block;
  width: 100%;
  padding: rem(10px);
  border: none;
  outline: none;
  font-size: rem(14px);
  background: transparent;

  &::placeholder {
    @include font-style(16, 400);
    color: #999;
  }
}

//被逼添加zmage样式，如果能解决移除这段样式，样式不丢失，那就尽量移除
.wrapperLayer__25Osd {
  position: fixed;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
}

/* 与 anim.ts 同步 */
.baseButton__18U5B {
  z-index: 1000;
  box-sizing: border-box;
  cursor: pointer;
}
.baseButton__18U5B > svg {
  display: block;
  width: 2rem;
  height: 2rem;
}
.controls__3BU3E {
  box-sizing: border-box;
  position: absolute;
  top: 0.6rem;
  right: 0.6rem;
  opacity: 0;
  display: flex;
  z-index: 1000;
  border-radius: 5rem;
  transform: translateX(100%);
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms;
}
.controls__3BU3E.show__1tLY6 {
  opacity: 0.8;
  transform: translateX(0);
}
.controls__3BU3E .pinButton__MnPeR {
  z-index: 1000;
  box-sizing: border-box;
  cursor: pointer;
  margin: 0.4em 0;
  width: 2rem;
  height: 2rem;
  transition: transform 175ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1);
}
.controls__3BU3E .pinButton__MnPeR > svg {
  display: block;
  width: 2rem;
  height: 2rem;
}
.controls__3BU3E .pinButton__MnPeR:hover {
  opacity: 0.8 !important;
  transform: scale(1.1) !important;
}
.controls__3BU3E .pinButton__MnPeR:active {
  opacity: 1 !important;
  transform: scale(1) !important;
}
.controls__3BU3E .pinButton__MnPeR:first-of-type {
  margin-left: 0.4rem;
}
.controls__3BU3E .pinButton__MnPeR:last-of-type {
  margin-right: 0.4rem;
}
.controls__3BU3E .rotate__1DjN2 {
  z-index: 1000;
  box-sizing: border-box;
  cursor: pointer;
  margin: 0.4em 0;
  width: 2rem;
  height: 2rem;
  transition: transform 175ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1);
}
.controls__3BU3E .rotate__1DjN2 > svg {
  display: block;
  width: 2rem;
  height: 2rem;
}
.controls__3BU3E .rotate__1DjN2:hover {
  opacity: 0.8 !important;
  transform: scale(1.1) !important;
}
.controls__3BU3E .rotate__1DjN2:active {
  opacity: 1 !important;
  transform: scale(1) !important;
}
.controls__3BU3E .rotate__1DjN2:first-of-type {
  margin-left: 0.4rem;
}
.controls__3BU3E .rotate__1DjN2:last-of-type {
  margin-right: 0.4rem;
}
.controls__3BU3E .rotate__1DjN2 svg {
  width: 1.75rem;
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1);
}
.controls__3BU3E .rotateLeft__1KOxL {
  z-index: 1000;
  box-sizing: border-box;
  cursor: pointer;
  margin: 0.4em 0;
  width: 2rem;
  height: 2rem;
  transition: transform 175ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1);
}
.controls__3BU3E .rotateLeft__1KOxL > svg {
  display: block;
  width: 2rem;
  height: 2rem;
}
.controls__3BU3E .rotateLeft__1KOxL:hover {
  opacity: 0.8 !important;
  transform: scale(1.1) !important;
}
.controls__3BU3E .rotateLeft__1KOxL:active {
  opacity: 1 !important;
  transform: scale(1) !important;
}
.controls__3BU3E .rotateLeft__1KOxL:first-of-type {
  margin-left: 0.4rem;
}
.controls__3BU3E .rotateLeft__1KOxL:last-of-type {
  margin-right: 0.4rem;
}
.controls__3BU3E .rotateLeft__1KOxL svg {
  width: 1.75rem;
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1);
}
.controls__3BU3E .rotateLeft__1KOxL:hover svg {
  transform: rotate(-30deg);
}
.controls__3BU3E .rotateRight__3kYbB {
  z-index: 1000;
  box-sizing: border-box;
  cursor: pointer;
  margin: 0.4em 0;
  width: 2rem;
  height: 2rem;
  transition: transform 175ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1);
}
.controls__3BU3E .rotateRight__3kYbB > svg {
  display: block;
  width: 2rem;
  height: 2rem;
}
.controls__3BU3E .rotateRight__3kYbB:hover {
  opacity: 0.8 !important;
  transform: scale(1.1) !important;
}
.controls__3BU3E .rotateRight__3kYbB:active {
  opacity: 1 !important;
  transform: scale(1) !important;
}
.controls__3BU3E .rotateRight__3kYbB:first-of-type {
  margin-left: 0.4rem;
}
.controls__3BU3E .rotateRight__3kYbB:last-of-type {
  margin-right: 0.4rem;
}
.controls__3BU3E .rotateRight__3kYbB svg {
  width: 1.75rem;
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1);
}
.controls__3BU3E .rotateRight__3kYbB:hover svg {
  transform: rotate(30deg);
}
.controls__3BU3E .download__1o8td {
  z-index: 1000;
  box-sizing: border-box;
  cursor: pointer;
  margin: 0.4em 0;
  width: 2rem;
  height: 2rem;
  transition: transform 175ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1);
}
.controls__3BU3E .download__1o8td > svg {
  display: block;
  width: 2rem;
  height: 2rem;
}
.controls__3BU3E .download__1o8td:hover {
  opacity: 0.8 !important;
  transform: scale(1.1) !important;
}
.controls__3BU3E .download__1o8td:active {
  opacity: 1 !important;
  transform: scale(1) !important;
}
.controls__3BU3E .download__1o8td:first-of-type {
  margin-left: 0.4rem;
}
.controls__3BU3E .download__1o8td:last-of-type {
  margin-right: 0.4rem;
}
.controls__3BU3E .download__1o8td svg {
  margin-top: -0.06rem;
  width: 1.75rem;
}
.controls__3BU3E .zoom__15F8y {
  z-index: 1000;
  box-sizing: border-box;
  cursor: pointer;
  margin: 0.4em 0;
  width: 2rem;
  height: 2rem;
  transition: transform 175ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1);
}
.controls__3BU3E .zoom__15F8y > svg {
  display: block;
  width: 2rem;
  height: 2rem;
}
.controls__3BU3E .zoom__15F8y:hover {
  opacity: 0.8 !important;
  transform: scale(1.1) !important;
}
.controls__3BU3E .zoom__15F8y:active {
  opacity: 1 !important;
  transform: scale(1) !important;
}
.controls__3BU3E .zoom__15F8y:first-of-type {
  margin-left: 0.4rem;
}
.controls__3BU3E .zoom__15F8y:last-of-type {
  margin-right: 0.4rem;
}
.controls__3BU3E .close__wP1v9 {
  z-index: 1000;
  box-sizing: border-box;
  cursor: pointer;
  margin: 0.4em 0;
  width: 2rem;
  height: 2rem;
  transition: transform 175ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1);
}
.controls__3BU3E .close__wP1v9 > svg {
  display: block;
  width: 2rem;
  height: 2rem;
}
.controls__3BU3E .close__wP1v9:hover {
  opacity: 0.8 !important;
  transform: scale(1.1) !important;
}
.controls__3BU3E .close__wP1v9:active {
  opacity: 1 !important;
  transform: scale(1) !important;
}
.controls__3BU3E .close__wP1v9:first-of-type {
  margin-left: 0.4rem;
}
.controls__3BU3E .close__wP1v9:last-of-type {
  margin-right: 0.4rem;
}
.sideButton__3xQsl {
  z-index: 1000;
  box-sizing: border-box;
  cursor: pointer;
  opacity: 0;
  position: absolute;
  top: 50%;
  padding: 0.4rem;
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1);
}
.sideButton__3xQsl > svg {
  display: block;
  width: 2rem;
  height: 2rem;
}
.sideButton__3xQsl:hover {
  opacity: 0.8 !important;
  transform: translateX(0) translateY(-50%) !important;
}
.sideButton__3xQsl:active {
  opacity: 1 !important;
}
.sideButton__3xQsl.show__1tLY6 {
  opacity: 0.8;
}
.flipLeft__3VB5y {
  z-index: 1000;
  box-sizing: border-box;
  cursor: pointer;
  opacity: 0;
  position: absolute;
  top: 50%;
  padding: 0.4rem;
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1);
  left: 0;
  padding-left: 0.6rem;
  border-radius: 0 0.5em 0.5em 0;
  transform: translateX(-100%) translateY(-50%);
}
.flipLeft__3VB5y > svg {
  display: block;
  width: 2rem;
  height: 2rem;
}
.flipLeft__3VB5y:hover {
  opacity: 0.8 !important;
  transform: translateX(0) translateY(-50%) !important;
}
.flipLeft__3VB5y:active {
  opacity: 1 !important;
}
.flipLeft__3VB5y.show__1tLY6 {
  opacity: 0.8;
}
.flipLeft__3VB5y:active {
  transform: translate(-0.2em) translateY(-50%) !important;
}
.flipLeft__3VB5y.show__1tLY6 {
  opacity: 0.8;
  transform: translate(-0.2em) translateY(-50%);
}
.flipRight__1EqiX {
  z-index: 1000;
  box-sizing: border-box;
  cursor: pointer;
  opacity: 0;
  position: absolute;
  top: 50%;
  padding: 0.4rem;
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1);
  right: 0;
  padding-right: 0.6rem;
  border-radius: 0.5rem 0 0 0.5rem;
  transform: translateX(100%) translateY(-50%);
}
.flipRight__1EqiX > svg {
  display: block;
  width: 2rem;
  height: 2rem;
}
.flipRight__1EqiX:hover {
  opacity: 0.8 !important;
  transform: translateX(0) translateY(-50%) !important;
}
.flipRight__1EqiX:active {
  opacity: 1 !important;
}
.flipRight__1EqiX.show__1tLY6 {
  opacity: 0.8;
}
.flipRight__1EqiX:active {
  transform: translate(0.2em) translateY(-50%) !important;
}
.flipRight__1EqiX.show__1tLY6 {
  opacity: 0.8;
  transform: translate(0.2em) translateY(-50%);
}
.pages__IwQb3 {
  box-sizing: border-box;
  display: flex;
  position: absolute;
  left: 50%;
  bottom: 0.6rem;
  z-index: 110;
  opacity: 0;
  border-radius: 2rem;
  transform: translate(-50%, 100px);
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1);
}
.pages__IwQb3.show__1tLY6 {
  opacity: 0.8;
  transform: translate(-50%, 0);
}
.pages__IwQb3.mobile__1L-Sn {
  bottom: 2rem;
}
.pages__IwQb3 .dot__3z2uK {
  cursor: pointer;
  margin: 0.45rem 0.25rem;
  display: block;
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 1.2rem;
  background: black;
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1), width 350ms cubic-bezier(0.6, 0, 0.1, 1);
}
.pages__IwQb3 .dot__3z2uK:first-of-type {
  margin-left: 0.6rem;
}
.pages__IwQb3 .dot__3z2uK:last-of-type {
  margin-right: 0.6rem;
}
.pages__IwQb3 .blackDot__2Zvud {
  cursor: pointer;
  margin: 0.45rem 0.25rem;
  display: block;
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 1.2rem;
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1), width 350ms cubic-bezier(0.6, 0, 0.1, 1);
  cursor: initial;
  width: 1rem;
  background: black;
}
.pages__IwQb3 .blackDot__2Zvud:first-of-type {
  margin-left: 0.6rem;
}
.pages__IwQb3 .blackDot__2Zvud:last-of-type {
  margin-right: 0.6rem;
}
.pages__IwQb3 .whiteDot__31yN0 {
  cursor: pointer;
  margin: 0.45rem 0.25rem;
  display: block;
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 1.2rem;
  background: black;
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1), width 350ms cubic-bezier(0.6, 0, 0.1, 1);
  background: #999;
}
.pages__IwQb3 .whiteDot__31yN0:first-of-type {
  margin-left: 0.6rem;
}
.pages__IwQb3 .whiteDot__31yN0:last-of-type {
  margin-right: 0.6rem;
}
.pages__IwQb3 .whiteDot__31yN0:hover {
  opacity: 0.8 !important;
  transform: scale(1.1) !important;
}
.pages__IwQb3 .whiteDot__31yN0:active {
  opacity: 1 !important;
  transform: scale(1) !important;
}

/* 与 anim.ts 同步 */
.imageLayer__207N- {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1), -webkit-clip-path 350ms cubic-bezier(0.6, 0, 0.1, 1);
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1), clip-path 350ms cubic-bezier(0.6, 0, 0.1, 1);
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1), clip-path 350ms cubic-bezier(0.6, 0, 0.1, 1), -webkit-clip-path 350ms cubic-bezier(0.6, 0, 0.1, 1);
  position: absolute;
  left: 50%;
  top: 50%;
  will-change: transform, top, opacity, clip-path;
}
.imageLayer__207N-.zooming__2StSE {
  transition-timing-function: cubic-bezier(0, 0.1, 0.1, 1);
  -ms-transition-duration: 0ms;
}
.imageLayer__207N-.invalidate__lVCQH {
  opacity: 0 !important;
  pointer-events: none;
}

/* 与 anim.ts 同步 */
.loadingContainer__13wfa {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  position: absolute;
  left: 50%;
  top: 50%;
  opacity: 0;
  transition: opacity cubic-bezier(0.6, 0, 0.1, 1) 175ms;
}
.loadingContainer__13wfa.show__29ULf {
  opacity: 1;
}
.loadingContainer__13wfa .reload__2xUtP {
  border: 2px solid;
  border-radius: 5px;
  font-size: 1rem;
  padding: 0.5rem;
  cursor: pointer;
  outline: none;
  transform: translate(-50%, -50%);
}
.loadingContainer__13wfa .reload__2xUtP:hover {
  opacity: 0.8;
}
.loadingContainer__13wfa .reload__2xUtP:hover svg {
  transform: rotate(30deg);
}
.loadingContainer__13wfa .reload__2xUtP:active {
  opacity: 1;
}
.loadingContainer__13wfa .reload__2xUtP svg {
  display: block;
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1);
}
.loadingContainer__13wfa .loading__2jKYg {
  width: 24px;
  height: 24px;
  transform: translate(-50%, -50%);
  -webkit-animation: spin__1Iuta 1s linear infinite;
          animation: spin__1Iuta 1s linear infinite;
}
@-webkit-keyframes fadeIn__3yb2M {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn__3yb2M {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fadeOut__1lwK5 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeOut__1lwK5 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes spin__1Iuta {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
@keyframes spin__1Iuta {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

/* 与 anim.ts 同步 */
.backgroundLayer__L59ju {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: zoom-out;
  background-color: #ffffff;
  transition: opacity 0.2s;
  will-change: opacity;
  -webkit-tap-highlight-color: transparent;
}


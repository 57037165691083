$offset: 187;
$duration: 1.4s;

.loading{
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 9999;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  @keyframes ldio-32iowmee2tm {
    0% { background: #f8b26a }
    12.5% { background: #f8b26a }
    12.625% { background: #1b262d }
    100% { background: #1b262d }
  }
  .ld-io{
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
    div {
      position: absolute;
      width: 40.8px;
      height: 40.8px;
      background: #1b262d;
      animation: ldio-32iowmee2tm 1s linear infinite;
      box-sizing: content-box;
    }
  }
  .loading-io-spinner-blocks{
    width: 204px;
    height: 204px;
    display: inline-block;
    overflow: hidden;
    background: none;
  }
}
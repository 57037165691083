@charset "UTF-8";
.module-banner {
  background: #181A20;
  padding: 20px 0 40px; }
  .module-banner .subBanner {
    position: relative;
    background-color: #181A20;
    display: flex;
    flex-direction: column;
    border-radius: rem(5);
    width: 1200px;
    margin: 0 auto; }
    .module-banner .subBanner .noticeBox {
      display: flex;
      justify-content: space-between;
      padding: 0 rem(15px);
      height: rem(40px); }
      .module-banner .subBanner .noticeBox .imageBox {
        display: flex;
        align-items: center; }
        .module-banner .subBanner .noticeBox .imageBox img {
          width: rem(23);
          height: rem(23);
          border-radius: rem(23); }
      .module-banner .subBanner .noticeBox .noticeCarousel {
        height: rem(20) !important;
        font-weight: bold; }
        .module-banner .subBanner .noticeBox .noticeCarousel div {
          height: rem(20) !important; }
        .module-banner .subBanner .noticeBox .noticeCarousel .messageLink {
          font-size: 15px;
          margin-left: rem(10);
          color: #fff;
          display: inline-block;
          width: 100%;
          font-size: rem(30);
          line-height: rem(30);
          font-weight: 400;
          line-height: rem(20);
          white-space: nowrap;
          /*不允许换行*/
          overflow: hidden;
          /*超出隐藏*/
          text-overflow: ellipsis;
          /*文本超出三点代替*/
          margin-left: 10px;
          font-family: 'PingFangSC,PingFangSC-Medium'; }
          .module-banner .subBanner .noticeBox .noticeCarousel .messageLink b {
            margin: 0 10px; }
  .module-banner .content {
    width: 1200px;
    margin: 0 auto; }
    .module-banner .content .b-image {
      height: 110px;
      display: flex;
      overflow: hidden; }
      .module-banner .content .b-image img {
        width: 96%;
        height: 100%;
        margin: 0 auto 0 0;
        border-radius: 8px; }

.module-bannerArrow {
  position: absolute;
  top: 40%;
  z-index: 5;
  background: rgba(21, 21, 24, 0.7);
  border-radius: 24px;
  cursor: pointer; }
  .module-bannerArrow.left {
    left: 15px; }
  .module-bannerArrow.right {
    right: 15px;
    transform: rotate(180deg); }
  .module-bannerArrow:hover {
    background: #fff; }

.c2cWrapper{
  // border-top: 70px solid;
  // border-color: linear-gradient(to right, rgba(18, 20, 28, 0.6) 0%, rgba(18, 20, 28, 0.6) 50%, rgba(transparent, 0.1) 100%);
  .header{
    border-bottom: 1px solid #EAECEF;
    .headerBanner{
      background-image: url('./images/banner.png');
      height: 15.6vw;
      background-size: contain;
      padding-top: 1vw;
      font-size: 2vw;
      display: flex;
      align-items: center;
      flex-direction: column;

      h1{
        color: #FFF;
        text-align: center;
        margin: 1vw;
      }

      p{
        width: 40vw;
        color: #B7BDC6;
        text-align: center;
        font-size: 1.5vw;
      }
    }
  }

  .body{
    padding-left: 12vw;
    padding-right: 12vw;

    .main{
      display: grid;
      grid-template-columns: 68% 32%;
      padding-bottom: 5vw;
      padding-top: 3rem;

      .stepTips{
        padding-top: 3rem;
        h1{
          font-weight: bold;
        }
        .stepList{
          margin-top: 2rem;
          margin-left: 2rem;
          padding-left:3rem;
          border-left: 2px dashed #BBBBBB;
          color: #707A8A;
          position: relative;
          font-weight: bold;
          li{
            position: relative;
          }
          li:not(:first-child):not(:last-child){
            margin-top:4rem;
            margin-bottom:4rem;
          }
  
          li:not(:last-child)::before{
            content: " ";
            position:absolute;
            background-image: url('./images/step.svg');
            width: 34px;
            height: 34px;
            background-repeat: no-repeat;
            left: -58px;
            top: -7px;
            background-color: #FFF;
          }
  
          li:last-child::before{
            content: " ";
            position:absolute;
            background-image: url('./images/final-step.svg');
            width: 34px;
            height: 34px;
            background-repeat: no-repeat;
            left: -58px;
            top: -7px;
            background-color: #FFF;
          }
        }
      }

      .tradeWrapper{
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

        .tradeTab{
          font-size: 1.5rem;
          font-weight: bold;
          display: grid;
          grid-template-columns: 1fr 1fr;
          text-align: center;
          padding: 1rem;
        }
        
        .tradeForm{
          > div {
            padding: 1rem 2rem;
          }
        }

        .btnBuy{
          width: 100%;
          background-color: #02C076;
          color: #fff;
          font-size: 1rem;
          font-weight: bold;
          border: 0px;
          padding: 0.75rem;
        }

        .btnSell{
          width: 100%;
          background-color: #F84960;
          color: #fff;
          font-size: 1rem;
          font-weight: bold;
          border: 0px;
          padding: 0.75rem;
        }

        .numberCurrencyGroupField{
          border: 1px solid #EAECEF;
          height: 54px;
          .number-currency-group-field{
            display: grid;
            grid-template-columns: 70% 30%;
            height: 54px;
            align-content: center;

            .number-input{
              border: 0px;
              height: 40px;
              font-size: 1.5rem;
              color: #B7BDC6;
              margin-left: 1rem;
            }
          }
        }

        .paymentField{
          border: 1px solid #EAECEF;
          height: 54px;
            display: grid;
            grid-template-columns: 100%;
            align-content: center;
        }

        .currency-selector{
          width: 100%;
          & > * {
            border: 0px;
          }

          & > *:focus {
            box-shadow: none;
          }

          img{
            padding-right: 1rem;
          }
        }
      }
    }
  }

  .c2cDetailWrapper{
    
    .detailHeader{
      padding: 1.5rem calc((100vw - 1200px) / 2);
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      line-height: 2rem;
      align-items: center;
      background-color: #FAFAFA;

      .detailTitle{
        font-size: 2rem;
        font-weight: bold;
      }
      span{
        color: #707A8A;
        padding: 1rem;
      }

      img{
        margin-left:1rem;
        cursor: pointer;
      }
    }

    .detailBody{
      padding: 1.5rem calc((100vw - 1200px) / 2);
      margin: 0 auto;
      display: grid;
      grid-template-columns: auto 35%;
      grid-gap: 2rem;

      .main{
        .step {
          margin-top: 1rem;
          margin-bottom: 1rem;
          padding-bottom: 2rem;
          border-bottom: 1px solid #E6E8EC;
          color: #848E9C;
          h3{
            font-size: 2rem;
            font-weight: bold;
            margin-bottom: 0.25rem;
          }
        }
        .info{
          border-bottom: 1px solid #E6E8EC;
          padding-bottom: 2rem;
          h5{
            font-size: 1.3rem;
            font-weight: bold;
          }
          .infoItem{
            display: flex;
            font-size: 1.2rem;
            > div{
              width: 200px;
              display: flex;
              flex-direction: column;
            }

            span{
              font-size: 1rem;
            }
          }
        }

        .status{
          margin-bottom: 2rem;
          h2{
            font-weight: bold;
            margin-bottom: 0.3rem;
            strong{
              color: #FFD140;
              margin-left:1rem;
            }
          }
        }

        .option{
          display: flex;
          padding:1rem 0;
          span{
            cursor: pointer;
            color: #FFD140;
            padding-left:1.5rem;
            padding-right:1.5rem;
            border-left: 2px solid #E6E8EC;

            a{
              color: #FFD140;
            }

            &:first-child{
              padding-left:0rem;
              border-left: 0px solid #E6E8EC;
            }
          }
        }

        .finish{
          margin-top: 1rem;
          h5{
            font-size: 1.3rem;
            font-weight: bold;
          }

          .payment{
            background-color: #FAFAFA;
            padding-left: 1.5rem;

            span{
              padding-left: 1.5rem;
              &.BANK{
                border-left: 4px solid #FFD140;
              }
              &.ALIPAY{
                border-left: 4px solid #01A9F2;
              }
              &.WECHAT{
                border-left: 4px solid #2DC100;
              }
            }
          }
        }

        .payment{
          margin-top: 1rem;
          padding: 1.5rem 0;

          h5{
            font-size: 1.3rem;
            font-weight: bold;
          }

          .paymentWrapper{
            font-size: 1.3rem;
            border: 1px solid #E6E8EC;
            display: grid;
            grid-template-areas: "header header"
                                 "list info";
            grid-template-columns: 35% auto;
            .paymentHeader{
              grid-area: header;
              padding: 1.5rem 1.5rem 0 1.5rem;
              .paymentTooltip{
                border: 1px solid #F0B90B;
                background-color: #FEF6D8;
                font-size: 1rem;
                padding: 0.5rem 1.5rem;
              }
            }
            .paymentList{
              grid-area: list;
              box-shadow: inset -1px 0 1px #eaeaea;
              .paymentTitle{
                cursor: pointer;
                font-weight: bold;
                box-shadow: inset -1px -1px 0px 0 #eaeaea;
                background: white;
                margin-left: 2rem;
                padding: 1.5rem 0;
                &.active{
                  box-shadow: inset 0px -1px 0px 0 #eaeaea;
                }
                
                > div{
                  padding-left: 1rem;

                  &.BANK{
                    border-left: 4px solid #FFD140;
                  }
                  &.ALIPAY{
                    border-left: 4px solid #01A9F2;
                  }
                  &.WECHAT{
                    border-left: 4px solid #2DC100;
                  }
                }
              }
            }
            
            .paymentInfo{
              grid-area: info;
              padding: 1rem 0rem 1rem 3rem;
              > div{
                padding: 0.5rem 0;
                font-weight: bold;
                span{
                  color: #848E9C;
                  display: block;
                  font-size: 1.1rem;
                }

                img{
                  width: 125px;
                  height: 125px;
                  cursor: pointer;
                }
              }
            }
          }
        }

        .footer{
          button{
            cursor: pointer;
            border: 0px;
            padding: 0.75rem 3rem;
            color: #000;
            font-size: 1.1rem;
            font-weight: bold;
            background-color: #EAECEF;
            border-radius: 3px;

            &.confirm{
              margin-right: 1rem;
              background-color: #F7D32C;
              &:disabled{
                background-color: #EAECEF;
                cursor: initial;
                opacity: 0.5;
              }
            }
            &.appeal{
                opacity: 1;
              &:disabled{
                background-color: #EAECEF;
                cursor: initial;
                opacity: 0.5;
              }
            }
          }
        }

        .hint{
          margin-top: 2rem;
          background-color: #F5F5F5;
          padding: 1.5rem;

          h5{
            font-size: 1.3rem;
            font-weight: bold;
          }

          ul{
            list-style: auto;
            margin-left: 1rem;
            line-height: 1.75rem;
          }
        }
        .appeal{
          h5{
            font-size: 1.3rem;
            font-weight: bold;
          }

          button{
            padding: 0.6rem 2rem;
            font-size: 1rem;
            font-weight: bold;
            background-color: rgba(182,188,199,0.2);
            border-radius: 25px;
            border: 0;
            margin-right: 1.5rem;
            img{
              margin-right: 0.75rem;
            }
          }

          .negotiate_result{
            padding-top: 2rem;
            padding-bottom: 2rem;
            border-bottom: 1px solid #E6E8EC;
            button{
              cursor: pointer;
            }
          }

          .negotiate_log{
            margin-top: 2rem;
            margin-bottom: 2rem;
            background-color: rgba(182,188,199,0.2);
            padding: 1rem 1.5rem;
            display: grid;
            grid-template-columns: 1fr 5%;
            h5{
              margin-bottom: 0;
              &:last-child{
                font-family: cursive;
              }
            }
          }

          .negotiate_hint{
            margin-top: 2rem;
            margin-bottom: 2rem;
            background-color: rgba(182,188,199,0.2);
            padding: 1rem 1.5rem;
            li{
              font-weight: bold;
              margin-bottom: 1rem;
            }
          }
        }
      }

      .chat{
        .c2cChat{
          display: flex;
          flex-direction: column;
          height: 100%;
          box-shadow: 0px 6px 12px 0px rgba(36, 58, 120, 0.08);
          .chatHeader{
            background-color: #F5F5F5;
            padding: 1rem;
            flex-grow: 0;
            .chatAdvertiser{
              .chatAdvertiserAvatar{
                align-self: center;
                img, span{
                  display: inline-block;
                  background-color: #DFDFDF;
                  width: 2.25rem;
                  height: 2.25rem;
                  border-radius: 50%;
                  margin-right: 1rem;
                  font-size: 1.25rem;
                  font-weight: bold;
                  text-align: center;
                  line-height: 2.25rem;
                }
              }

              .chatAdvertiserInfo{
                margin-left: 3.25rem;
                span{
                  color: #707A8A;
                }
              }
            }
          }

          .chatBody{
            flex-grow: 1;
            overflow: scroll;
            height: 530px;
            .chatContent{
              padding: 1rem;
              .messageWrapper{
                display: flex;
                flex-direction: column;
                > div {
                  font-weight: bold;
                  padding-left: 1rem;
                  padding-right: 1rem;
                  margin-bottom: 1rem;

                  &.timeMessage{
                    font-weight:normal;
                    text-align: center;
                  }

                  &.systemMessage{
                    line-height: 2.5rem;
                    background-color: #FAFAFA;
                    text-align: center;
                    border-radius: 17px;
                  }

                  &.selfMessage{
                    line-height: 3rem;
                    background-color: #F5F5F5;
                    text-align: right;
                    border-radius: 8px 8px 0 8px;
                    width: fit-content;
                    align-self: flex-end;
                  }

                  &.otherMessage{
                    line-height: 3rem;
                    background-color: #FEF6D8;
                    text-align: right;
                    border-radius: 8px 8px 8px 0px;
                    width: fit-content;
                    align-self: flex-start;
                  }

                  img{
                    max-width: 300px;
                    max-height: 300px;
                  }
                }
              }
            }
          }

          .chatFooter{
            display: grid;
            grid-template-columns: 75% 25%;
            flex-grow: 0;
            padding: 0.75rem 1.25rem;
            border-top: 1px solid #E6E8EC;
            .chatMessageInput{
              input{
                width: 100%;
                font-size: 1.2rem;
                border: 1px solid #E6E8EC;
                border-radius: 8px;
                height: 4rem;
                padding: 0 1rem;
              }
            }

            .chatButtonGroup{
              display: flex;
              align-items: center;
              padding: 0 1rem;
              justify-content: space-between;

              & > div {
                cursor: pointer;
              }

              .sendMessage{
                background-image: url('./images/sendMessage.svg');
                width: 2rem;
                height: 2rem;
                background-repeat: no-repeat;
                background-size: cover;

                &:hover{
                  background-image: url('./images/sendMessageHover.svg');
                }
              }
            }
          }
        }
      }

      span{
        color: #848E9C;
      }
    }
  }

  .c2cComplaintWrapper{
    .c2cComplaintHeader{
      padding: 1.5rem calc((100% - 1200px) / 2);
      display: grid;
      grid-template-columns: 3fr 1fr;
      background-color: #FAFAFA;
      h1{
        margin: 0;
        font-weight: bold;
      }
      span{
        margin-right:1rem;
        color: #707A8A;
        font-size: 0.9rem;
      }
      img{
        margin-left:1rem;
        cursor: pointer;
      }
      .right{
        color: #1E2129;
        text-align: right;
      }
    }

    .c2cComplaintBody{
      padding: 1.5rem calc((100% - 1200px) / 2);
      .tooltip{
        padding: 1rem;
        background-color: #FEF6D8;
        img{
          margin-right: 1rem;
        }
      }

      .main{
        padding: 1.5rem calc((100% - 550px) / 2);

        .field{
          margin-bottom: 1rem;
          label{
            display: block;
            color: #848E9C;
            padding-bottom: 1rem;
          }
          p{
            font-size: 1.5rem;
            font-weight: bold;
          }
          span{
            color: #848E9C;
            font-size: 0.9rem;
          }
          input{
            padding: 1.5rem;
          }

          .evidence {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            padding: rem(15);
            .upload{
              display: flex;
              position: relative;
              z-index: 1;
              width: 150px;
              height: 150px;
              border: 1px solid #E6E8EC;
              border-radius: 5px;
              margin: 10px 3px 10px 20px;
              background-color: #FAFAFA;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              .uploadImg{
                width: 150px;
                height: 150px;
              }
            }
            .closeIcon {
              position: absolute;
              right: 0;
              top: 0;
            }
          }

          .ant-select-selection--single {
            height: 34px;
            border: 1px solid #d9d9d9;
            border-radius: 4px;
            .ant-select-selection-selected-value{
              line-height: 34px;
            }
          }
        }
      }

      .log{
        margin: 1.5rem calc((100% - 650px) / 2);
        border: 1px solid #E6E8EC;
        padding: 1rem;
        
        .field{
          border-bottom: 1px solid #E6E8EC;
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;

          .listItem{
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            width: rem(75);
            height: rem(75);
            background: #eeeff2;
            margin-right: rem(12);
            margin-bottom: rem(15);
            .itemImg{
              width: 100%;
              object-fit: cover;
            }
            .closeIcon{
              position: absolute;
              right: 0;
              top: 0;
            }
          }

          &:last-child{
            border-bottom: 0px solid #E6E8EC;
          }

          p{
            padding: 0.5rem 0;
            margin-left: 20px;
            font-size: 13px; // 1.5rem;
            font-weight: bold;
          }
          label{
            display: block;
            color: #848E9C;
            padding: 0.5rem 0;
          }
        }
      }

      .evidence {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        padding: rem(15);
        .upload{
          display: flex;
          position: relative;
          z-index: 1;
          width: 150px;
          height: 150px;
          border: 1px solid #E6E8EC;
          border-radius: 5px;
          margin: 10px 3px 10px 20px;
          background-color: #FAFAFA;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          .uploadImg{
            width: 150px;
            height: 150px;
            cursor: pointer;
          }
        }
        .attach{
          position: absolute;
          right: 0;
          top: 0;
        }
      }
      
      .footer{
        padding: 1.5rem calc((100% - 550px) / 2);
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 1rem;

        &.single{
          padding: 1.5rem calc((100% - 250px) / 2);
          grid-template-columns: 1fr;
        }

        button{
          border: 0px;
          padding: 0.75rem 3rem;
          color: #000;
          font-size: 1.1rem;
          font-weight: bold;
          background-color: #EAECEF;
          border-radius: 3px;
          cursor: pointer;

          &.confirm{
            margin-right: 1rem;
            background-color: #F7D32C;
            &:disabled{
              background-color: #EAECEF;
            }

            img{
              margin-right: 0.5rem;
            }
          }
        }
      }
    }
  }

  
}

.optionalWrapper{
  width: 1200px;
  margin: 0 auto;

  .optionalFilter{
    display: flex;
    padding-top: 1rem;
    padding-bottom: 1rem;
    justify-content: space-between;
    .optionalFilterLeft{
      display: flex;
      .optionalFilterType{
        border: 1px solid #EAECEF;
        padding: 0.25rem;
        button{
          cursor: pointer;
          color: #848E9C;
          background-color: #fff;
          padding: 0.3rem 1.5rem;
          height: 100%;
          border: 0px;

          &.optionalFilterBuy.active{
            background-color: #02C076;
            color: #FFF;
          }

          &.optionalFilterSell.active{
            background-color: #F84960;
            color: #FFF;
          }
        }
      }
      .optionalFilterAsset{
        margin-left:1rem;
        .optionalFilterAssetList{
          display: flex;
          & > div{
            margin: 0 0.75rem;
            padding: 0.5rem 0;
            color: #848E9C;
            cursor: pointer;
            &.active{
              color: #000;
              border-bottom: 4px solid #FFD140;
              padding-bottom: 0.5rem;
            }
          }
        }
      }
    }
    
    .optionalFilterFlat {
      display: flex;
      margin-left: 1rem;
      align-items: center;
    }

    .optionalFilterOption{
      width: 150px;
      .ant-select{
        width: 100%;
        .ant-select-selection{
          border: 1px solid #d9d9d9;
        }
      }
    }
  }
  

  .optionalList{
    .optionalListHeader{
      background-color: #F5F5F5;
      border-top: 1px solid #EAECEF;
      display: grid;
      grid-template-columns: 25% 15% 25% 25% 10%;
      padding: 1rem 2rem;
      color: #848E9C;
      font-weight: bold;
    }

    .optionalListBody{
      .optionalListItem{
        display: grid;
        grid-template-columns: 25% 15% 25% 25% 10%;
        padding: 2rem 2rem;
        border-bottom: 1px solid #E6E8EC;

        .itemPrice{
          align-self: center;
          span {
            font-size: 1.75rem;
          }
        }

        .itemAmount{
          align-self: center;
          .itemAmountRow{
            line-height: 1.75rem;
            span{
              color: #707A8A;
              padding-right: 1.5rem;
              font-size: 0.9rem;
            }
          }
        }

        .itemTrade{
          button{
            cursor: pointer;
            width: 115px;
            background-color: #02C076;
            color: #FFFFFF;
            border: 0px;
            margin: 0 auto;
            padding: 0.75rem 0;
            border-radius: 5px;

            &.sell{
              background-color: #F84960;
            }

            &:disabled, &.sell:disabled{
              border: 1px solid #EAECEF;
              background-color: #fff;
              color: #000;
              margin: 0 auto;
              padding: 0.75rem 0;
              border-radius: 0px;
              font-weight: bold;
            }

            
          }
        }

        .itemPaymentWay{
          align-self: center;
          span{
            background-color: #FAFAFA;
            padding: 0.3rem 0.5rem;
            margin-right: 0.5rem;
            font-weight: bold;
            &.alipay{
              color:#01A9F2;
            }
            &.bank{
              color:#F0B90B;
            }
            &.wechat{
              color:#2DC100;
            }
          }
        }

        .itemTrade{
          align-self: center;
        }
        
      }
    }

    .optionalListFooter{
      padding: 1rem 2rem;
      display: flex;
      justify-content: end;
    
      .ant-pagination-item-active {
        border-color: #F3AD00;
        a {
          color: #F3AD00;
        }
      }
    }
  }
}

.tradeModalWrapper{
  .tradeModalHeader{
    padding-bottom: 1rem;
    border-bottom: 1px solid #E6E8EC;
  }

  .tradeModalBody{
    .tradeModalBodyRow{
      display: flex;
      margin-top:0.75rem;
      margin-bottom:0.75rem;
      .itemPaymentWay{
        margin-bottom:0.75rem;
        align-self: center;
        span{
          background-color: #FAFAFA;
          padding: 0.3rem 0.5rem;
          margin-right: 0.5rem;
          font-weight: bold;
          &.alipay{
            color:#01A9F2;
          }
          &.bank{
            color:#F0B90B;
          }
          &.wechat{
            color:#2DC100;
          }
        }
      }

      .tradeModalBodyCol{
        width: 50%;
        &.price{
          color: #02C076;
        }
        .right {
          float: right;
          color: #707A8A;
        }
      }

      &.numberInput{
        border: 1px solid #EAECEF;
        padding: 0.75rem;
        margin-top:1rem;
        .tradeModalBodyCol:first-child{
          width: 75%;
          .number-input{
            width: 100%;
          }
        }
        .tradeModalBodyCol:last-child{
          width: 25%;
          text-align: right;
        }
      }

      &.addBtn{
        width: 100%;
        .drop-down-list-section {
          cursor: pointer;
          min-height: 44px;
          border: #EAECEF 1px solid;
          padding: 0.7rem;
          width: 100%;
          display: flex;
          justify-content: space-between;
          img {
            padding-right: 0.5em;
          }
          .drop-down-list-section-image{
              display: flex;
              align-items: center;
          }
        }
        .addReceiveMethod{
          border: #000 1px solid;
          cursor: pointer;
          height: 42.5px;
          width: 100%;
          text-align: center;
          padding: 3.5px 0;
          .addReceiveMethodBtn{
            font-size: 20px;
          }
        }
      }

      h6{
        font-size: 1rem;
        font-weight: bold;
        color: #1E2129;
        margin-bottom: 0em;
      }

      span{
        color: #707A8A;
        padding-right: 1.5rem;
        font-size: 0.9rem;
      }

      .currency-selector{
        width: 100%;
        & > * {
          border-color: #EAECEF;
          border-radius: 0px;
          border: 1px solid #EAECEF;
          height: 40px;
        }

        & > *:focus {
          box-shadow: none;
        }

        img{
          padding-right: 1rem;
        }
      }

      .ant-select-selection{
        border: 1px solid #EAECEF;
        .ant-select-selection__rendered{
          line-height: 38px !important;
        }
      }
    }
  }

  .tradeModalFooter {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-gap: 0.5rem;

    button{
      border: 0px;
      border-radius: 3px;
      font-weight: bold;
      padding: 0.5rem;
      cursor: pointer;

      &.confirm{
        color: #FFF;
        background-color: #02C076;
      }
    }
  }

  a{
    color: #FFD140;
  }

  &.sell{
    .tradeModalBody{
      .tradeModalBodyCol{
        width: 50%;
        &.price{
          color: #F84960;
        }
      }
    }

    .tradeModalFooter {
      button{
        &.confirm{
          background-color: #F84960;
        }
      }
    }
  }
}

.confirmModalWrapper{
  .confirmModalBody{
    background-image: url('./images/confirm.svg');
    padding-top: 12rem;
    background-repeat: no-repeat;
    background-position: center 40%;
    text-align: center;
  }

  .confirmModalFooter {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.5rem;

    button{
      border: 0px;
      border-radius: 3px;
      font-weight: bold;
      padding: 0.5rem;

      &.confirm{
        color: #FFF;
        background-color: #02C076;
      }
    }
  }

  a{
    color: #FFD140;
  }

  &.sell{
    .tradeModalFooter {
      button{
        &.confirm{
          background-color: #F84960;
        }
      }
    }
  }
}

.confirmationContent{
  .confirmationBody {
    text-align: center;
    padding: 5px 30px;

    .confirmation_icon {
      text-align: center;
      height: 50%;
      width: 100%;
      margin-bottom: 20px;
    }

    .confirmation_title {
      font-size: 20px;
      font-weight: bolder;
      margin-bottom: 20px;
    }

    .confirmation_desc {
      font-size: 15px;
      text-align: justify;
      color: #707A8A;
      margin-bottom: 20px;
    }

    .confirmation_agree {
      font-size: 15px;
      font-weight: bold;
      input {
        cursor: pointer;
      }
      span {
        margin-left: 10px;
      }
    }
  }

  .confirmationFooter {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: 2fr 2fr;
    grid-gap: 0.5rem;

    button{
      border: 0px;
      border-radius: 3px;
      font-weight: bold;
      padding: 0.5rem;
      cursor: pointer;
    }

    button:disabled,
    button[disabled]{
      cursor:initial;
      background-color: #EFEFEF;
      opacity: 0.5;
    }

    button:last-of-type {
      background-color: #F7D32C;
    }
  }
}

.itemAdvertiser{
  display: flex;
  .advertiserAvatar {
    align-self: center;
    img, span{
      display: flex;
      background-color: #F1F1F1;
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      margin-right: 1rem;
      align-items: center;
      justify-content: center;
      font-size: 1.5rem;
      font-weight: bold;
    }
  }

  .advertiserInfo{
    .advertiserName{
      display: flex;
      font-size: 1.2rem;
      line-height: 2.5rem;

      img{
        margin-left:0.5rem;
      }
      .dot {
        margin: 0 10px;
        font-size: 10px;
        display: flex;
        align-items: center;
        .redDot {
          height: 10px;
          width: 10px;
          background-color: #F84960;
          border-radius: 50%;
        }
        .greenDot {
          height: 10px;
          width: 10px;
          background-color: #02C076;
          border-radius: 50%;
        }
      }
    }

    .advertiserHistory{
      display: flex;
      color: #707A8A;

      .advertiserVolume{
        padding-right: 0.5rem;
        border-right: 1px solid #E6E8EC;
        line-height: 1rem;
      }

      .advertiserSuccessRate{
        padding-left: 0.5rem;
        line-height: 1rem;
      }
    }
  }
}

.aboutUs{
  background-color: #FAFAFA;
  padding-top: 3rem;
  
  h1{
    text-align: center;
    font-weight: bold;
  }
  
  .ourPros{
    display: flex;
    width: 1200px;
    margin: 0 auto;
    padding: 3rem 0;

    .ourProsItem{
      padding-left: 1.5vw;
      padding-right: 1.5vw;
      text-align: center;

      .prosIcon{
        text-align: center;
      }

      .prosTitle{
        font-size: 1.2rem;
        text-align: center;
        font-weight: bold;
        padding-bottom: 1rem;
        padding-top: 1rem;
      }

      .prosDesc{
        color: #474D57;
      }
    }
  }
}

.faq{
  width: 1200px;
  margin: 0 auto;
  padding: 3rem 0;
  h1{
    text-align: center;
    font-weight: bold;
  }

  .faqItem:nth-child(odd){
    background-color: #F5F5F5;
  }

  .faqItem{
    font-size: 1.25rem;
    padding: 1rem 1.5rem;
    position: relative;
    cursor: pointer;

    &::after{
      content: " ";
      background-image: url('./images/arrow-down.svg');
      width: 15px;
      height: 15px;
      position: absolute;
      background-repeat: no-repeat;
      right: 15px;
      top: 25px;
    }

    &.active::after{
      background-image: url('./images/arrow-up.svg');
    }

    .faqAnswer{
      padding: 1rem 2rem;
    }
  }
}


.orderQuestion{
  .header{
    text-align: center;
    h2{
      font-weight: bold;
    }
  }

  .body{
    .quote{
      background-color: #F5F5F5;
      padding: 2rem 3.5rem;
      margin-bottom: 1rem;

      h3{
        position: relative;
        font-weight: bold;
        &::before{
          content: "";
          background: url(./images/question.svg);
          position: absolute;
          width: 20px;
          height: 20px;
          left: -30px;
          background-repeat: no-repeat;
          background-size: cover;
        }
      }

      p{
        color: #707A8A;
        padding-bottom: 1rem;
      }
    }
  }

  .footer{
    margin-top: 1rem;
    span{
      cursor: pointer;
      display: block;
      text-align: center;
      color: #000;
      font-size: 1.15rem;
      font-weight: bold;
      width: 100%;
      background-color: #FFD140;
      padding: 0.5rem;
    }
  }

  h3{
    font-weight: bold;
    margin-bottom: 0rem;
  }

  span{
    color: #848E9C;
  }

  ul li{
    margin: 1.5rem 0;
    span{
      color: #000;
      font-weight: bold;
    }
  }
}

.ant-select-dropdown-menu-item{
  img{
    margin-right: 1rem;
  }
}

.ant-modal {
  top: 30px !important;
  .ant-modal-content {
      border-radius: 10px;
  }
}

.popupChangePwdAlert {
  margin: 15px 0;
  color: #141416;
}
.alert {
  font-size: 20px;
  font-family: PingFangSC-Medium;
}
.info {
  font-size: 14px;
  font-family: PingFangSC-Regular;
}

.popupChangePwdFooter{
  margin: 30px 0 15px 8%;
  button {
      width: 370px;
      height: 50px;
      background-color: #ffd140;
      font-family: PingFangSC-Medium;
      border-radius: 5px;
      border: none;
      cursor: pointer;
      color: #1e2129; //#141416;
  }
}

.addReceiveMethod-list{
  cursor: pointer;
  padding: 25px 0; 
  text-align: center;
  span {
    padding: 10px 160px;
    border: 1px solid #ccc;
  }
}

.addReceiveMethod-list:hover {
  background-color: #e6f7ff;
}
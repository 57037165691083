@import './../../style/color.scss';
.stage-agent{
    min-height: 880px;
    background: #fafafa;
    padding-left: 20px;
    padding-right: 20px;
    .a-banner{
        margin-top: 20px;
        height: 200px;
        background: url('./../../../res/agent/agent-home.png') no-repeat;
        display: flex;
        flex-direction: column;
        justify-content:  center;
        padding-left: 150px;
        border-radius: 6px;
        .a-banner-title{
            color: #fff;
            font-size: 40px;
            font-weight: 600;
        }
        .a-banner-btn{
            // margin-top: 5px;
            display: flex;
            .a-banner-btn-text{
                color: $fc6;
                background: $active;
                cursor: pointer;
                height: 40px;
                line-height: 40px;
                text-align: center;
                min-width: 200px;
                border-radius: 4px;
            }
        }
    }
    .a-mid{
        display: flex;
        margin-top: 20px;
        .a-mid-left{
            width: 65%;
            display: flex;
            justify-content: space-between;
            .a-mid-left-wrapper{
                flex: 1;
                
            }
            .a-mid-left-wrapper:nth-child(2){
                margin: 0 20px;
                .module-commissionView{
                    
                }
               
            }
            
        }
        .a-mid-right{
            margin-left: 20px;
            flex: 1;
        }
        
    }
    .a-bottom{
        margin-top: 20px;
    }
}

.module-quickTradingChartView{
    background: #fff;
    padding-top: 20px;
    padding-left: 30px;
    padding-right: 30px;
    .qtcv-catBox{
        display: flex;
        .qtcv-catBox-cat{
            font-size: 14px;
            color: #151519;
            font-weight: 500;
            margin-right: 30px;
            line-height: 1;
            position: relative;
            cursor: pointer;
        }
        .qtcv-catBox-cat.active::after{
            content: "";
            position: absolute;
            bottom: -21px;
            left: 0;
            width: 100%;
            height: 3px;
            background: $active;
        }
    }
    .qtcv-filterBox{
        margin-top: 48px;
        display: flex;
        .qtcv-filterBox-typeBox{
            display: flex;
            .qtcv-filterBox-typeBox-type{
                margin-right: 24px;
                font-size: 14px;
                color: $fc7;
                font-weight: 500;
                border-radius: 6px;
                min-width: 104px;
                padding: 0 5px;
                height: 34px;
                line-height: 34px;
                text-align: center;
                cursor: pointer;
            }
            .qtcv-filterBox-typeBox-type.active{
                background: $bg9;
                color: $fc5;
            }
        }
        .qtcv-filterBox-pickerBox{
            margin-right: 24px;
            .qtcv-filterBox-pickerBox-picker{
                height: 34px;
                .ant-calendar-picker-input{
                    height: 34px;
                    input{
                        font-size: 14px;
                        font-family: DIN-Medium;
                        font-weight: 500;
                        color: $fc6;
                        width: 71px;
                    }
                }
            }
        }
        .qtcv-filterBox-switchBox{
            margin-right: 24px;
            .module-switchView{
                height: 34px;
            }
        }
    }
    .qtcv-chartBox{
        margin-top: 30px;
    }
    .qtcv-listBox{
        margin-top: 34px;
        .ant-table-thead{
            .ant-table-row-cell-last{
                text-align: right;
            }
        }
        .qtcv-listBox-list-row-first{
            padding-left: 30px;
        }
        .qtcv-listBox-list-row-last{
            padding-right: 30px;
            text-align: right;
        }
    }
}
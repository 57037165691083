@import './../../trade/style/color.scss';
.module-miningModal{
    width: 430px;
    min-height: 256px;
    background: $bg2;
    padding: 0 15px;
    display: flex;
    flex-direction: column;
    .mm-top{
        .mm-top-title{
            font-size: 16px;
            font-weight: 500;
            color: $fc2;
            height: 69px;
            line-height: 69px;
        }
        .mm-top-line{
            // width: 400px;
            height: 1px;
            background: #23262f;
            box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.30); 
        }
    }
    .mm-mid{
        flex: 1;
        display: flex;
        align-items: center;
        .mm-mid-text{
            font-size: 14px;
            font-weight: 500;
            color: $fc2;
            padding: 0 20px;
            span{
                color: $active;
            }
        }
    }
    .mm-bottom{
        display: flex;
        margin-bottom: 26px;
        .mm-bottom-btn{
            flex: 1;
            font-size: 14px;
            font-weight: 500;
            border-radius: 4px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            cursor: pointer;
        }
        .mm-bottom-btn.ok{
            background: $active;
            color: $fc5;
            margin-left: 12px;
        }
        .mm-bottom-btn.cancel{
            background: $bg5;
            color: $fc2;
            margin-right: 12px;
        }
    }
}
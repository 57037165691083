@import './../../../style/color.scss';
.module-commissionView{
    height: 100%;
    width: 100%;
    border-radius: 6px;
    overflow: hidden;
    .cv-title{
        width: 100%;
        height: 64px;
        background: linear-gradient(90deg,#ffea74, #ffd140);
        border-radius: 6px 6px 0px 0px;
        font-size: 16px;
        color: $fc5;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .cv-content{
        position: relative;
        height: calc(100% - 64px); // .cv-title height: 64px;
        background: #fff;
        // font-size: 48px;
        font-size: 35px;
        text-align: center;
        font-family: DIN-Medium;
        font-weight: 500;
        color: $fc5;
        .cv-content-image{
            position: absolute;
            left: 0;
            height: 100%;
            opacity: 0.8;
            svg{
                width: 90%;
                height: 90%;
            }
        }
        .cv-content-text{
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            text-align: center;
            justify-content: center;
            p{
                font-size: 35px;
                color: $fc5;
                z-index: 999;
            }
            p:last-child,
            div {
                font-size: 20px;
                color: $fc7;
                z-index: 999;
            }
            div {
                width: 100%;
                display: flex;
                text-align: center;
                justify-content: center;
                div {
                    min-width: 40%;
                    width: 40%;
                    padding: 0 10x;
                    display: flex;
                    justify-content: space-evenly;
                    div:first-child {
                        width: 100%;
                        min-width: 100%;
                    }
                    div:last-child {
                        width: 100px;
                    }
                }
            }
        }
    }
    
}
@import './../../style/color.scss';
.stage-assetMain{
    min-width: 1200px;
    height: 100%;
    display: flex;
    flex-direction: column;
    background: #fafafa;
    border-top: 70px solid;
    // border-color: linear-gradient(to right, rgba(18, 20, 28, 0.6) 0%, rgba(18, 20, 28, 0.6) 50%, rgba(transparent, 0.1) 100%);
    .am-main{
        display: flex;
        .am-menu{
            width: 17%;
            // min-width: 320px;
            box-shadow: 2px 0px 4px 0px rgba(241,241,241,0.50); 
            background: #fff;
            z-index: 5;
            .assetMenu{
                border-right: 0;
                .am-titleBox{
                    display: flex;
                    align-items: center;
                    .am-titleBox-image{}
                    .am-titleBox-text{
                        font-size: 14px;
                        color: $fc5;
                        font-weight: 400;
                    }
                }
                .ant-menu-item{
                    padding-left: 0 !important;
                    padding-right: 0 !important;
                    .am-item{
                        font-size: 14px;
                        color: $fc5;
                        font-weight: 400;
                        padding-left: 40px;
                    }
                    .am-item.active{
                        background: #f5f5f5;
                        border-left: 4px solid $active;
                        padding-left: 36px;
                    }
                }
                .ant-menu-submenu-title{
                    padding-left: 10px !important;
                }
            }
        }
        .am-content{
            // margin-left: 0px;
            // margin-right: 20px;
            width: 83%;
            flex: 1;
            background: #fff;
            min-height: 880px;
        }
    }
}
@import './../../../style/color.scss';
.module-transferView{
    .tv-header{
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid $lc7;
        padding-bottom: 25px;
        .tv-header-title{
            font-size: 16px;
            color: $fc5;
            font-weight: 500;
        }
        .tv-header-close{
            cursor: pointer;
        }
    }
    .tv-block{
        margin-top: 24px;
        .tv-block-titleBox{
            display: flex;
            justify-content: space-between;
            .tv-block-titleBox-title{
                font-size: 16px;
                color: $fc5;
                font-weight: 500;
                line-height: 1;
            }
            .tv-block-titleBox-desc{
                font-size: 12px;
                color: $fc1;
                font-weight: 400;
                line-height: 1;
                display: flex;
                align-items: center;
                span{
                    font-family: DIN-Regular;
                }
            }
        }
        .tv-block-switchBox{
            display: flex;
            justify-content: space-between;
            border: 1px solid #babdc2;
            height: 48px;
            border-radius: 4px;
            margin-top: 12px;
            .tv-block-switchBox-left{
                display: flex;
                align-items: center;
                margin-left: 20px;
                .tv-block-switchBox-left-image{
                    width: 24px;
                    height: 24px;
                    img{
                        width: 100%;
                    }
                }
                .tv-block-switchBox-left-text{
                    font-size: 14px;
                    font-family: DIN-Medium;
                    font-weight: 500;
                    color: $fc5;
                    margin-left: 14px;
                }
            }
            .tv-block-switchBox-right{
                margin-right: 20px;
                display: flex;
                align-items: center;
            }
        }
        .tv-block-inputBox{
            display: flex;
            border: 1px solid #babdc2;
            border-radius: 4px;
            margin-top: 12px;
            height: 48px;
            input{
                border: 0;
                box-shadow: none;
                outline: none;
                width: none;
                flex: 1;
                height: 100%;
                margin-left: 20px;
            }
            .tv-block-inputBox-currency{
                color: $fc5;
                font-size: 14px;
                font-family: DIN-Medium;
                font-weight: 500;
                display: flex;
                align-items: center;
                margin-right: 14px;
            }
            .tv-block-inputBox-btn{
                font-size: 14px;
                color: $active;
                font-weight: 500;
                cursor: pointer;
                margin-right: 20px;
                display: flex;
                align-items: center;
            }
        }
    }
    .tv-btn{
        height: 48px;
        line-height: 48px;
        text-align: center;
        font-size: 14px;
        color: $fc5;
        font-weight: 500;
        background: $active;
        cursor: pointer;
        margin-top: 24px;
        border-radius: 4px;
    }
    .tv-btn-disable {
        @extend .tv-btn;
        opacity: 0.5;
        cursor: default;
    }

}

.tv-dpBox{
    .ant-dropdown-menu{
        overflow-y: scroll;
        height: 500px;
        .ant-dropdown-menu-item{
            font-size: 14px;
            font-family: DIN-Regular;
            font-weight: 400;
            color: $fc5;
            .tv-dpBox-cell{
                display: flex;
                align-items: center;
                height: 48px;
                .tv-dpBox-cell-image{
                    width: 24px;
                    height: 24px;
                    img{
                        width: 100%;
                    }
                }
                .tv-dpBox-cell-text{
                    font-size: 14px;
                    font-family: DIN-Medium;
                    font-weight: 500;
                    color: $fc5;
                    margin-left: 14px;
                }
            }
        }
        .ant-dropdown-menu-item:hover{
            background: #F5F5F5;
        }
    }
}
@import '../../../../trade/style/color.scss';
.top {
    // padding-top: 70px;
    // background-color: black;
    .CTOCNav{
        padding: 0 calc((100% - 1200px) / 2);
        margin: 0 !important;
        width: 100%;
        height: 60px;
        line-height: 60px;
        border-bottom: 1px solid #EAECEF;
        .content{
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            a{
                position: relative;
                &.active{
                    &::before{
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        display: block;
                        content: '';
                        width: 100%;
                        height: 4px;
                        background: $active;
                    }
                }
            }
        }
        
        .left{
            display: flex;
            color: #848E9C;
            span{
                color: #1E2129;
            }
        }
        .right{
            display: flex;
            a{
                margin-left: 38px;
                color: $lc6;
                img{
                padding-right: 0.5rem;
                }
                &:nth-child(2){
                    margin-right: 38px;
                }
            }
            .more{
                display: flex;
                align-items: center;
                cursor: pointer;
                .moredown{
                    width: 0; 
                    height: 0; 
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent;
                    border-top: 5px solid $bg8; 
                    margin-left: 9px;
                }
            }
        }
    }
}
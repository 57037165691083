.spin-loading {
  position:absolute;
  width: 100%;
  top:50%;
  transform: translateY(-50%);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  //background: rgba(0,0,0,0.4);
  //.ant-spin-dot-item {
  //  background: linear-gradient(to right, #FFCD6D, #FFB31F) !important;
  //  opacity: 1;
  //}
}

@import './../../style/color.scss';
.stage-position{
    background: #fafafa;
    height: 100%;
    display: flex;
    flex-direction: column;
    .p-titleBox{
        display: flex;
        align-items: center;
        height: 88px;
        background: #fff;
        .p-titleBox-title{
            font-size: 32px;
            line-height: 1;
            font-weight: 500;
            color: $fc5;
            margin-left: 42px;
        }
        .p-titleBox-pTypeBox{
            display: flex;
            margin-left: 20px;
            .p-titleBox-pTypeBox-btn{
                font-size: 16px;
                line-height: 1;
                font-weight: 500;
                color: $fc5;
                width: 122px;
                height: 32px;
                line-height: 32px;
                border: 1px solid #eaecef;
                text-align: center;
                cursor: pointer;
            }
            .p-titleBox-pTypeBox-btn:nth-child(1){
                border-left: 0;
                // border-right: 0;
            }
            .p-titleBox-pTypeBox-btn.active{
                background: $active;
            }
        }
    }
    .p-dataBox{
        background: #fff;
        margin: 20px 20px 0;
        display: flex;
        flex: 1;
        flex-direction: column;
        .p-dataBox-head{
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #EAECEF;
            height: 59px;
            margin-left: 30px;
            margin-right: 30px;
            .p-dataBox-head-tTypeBox{
                display: flex;
                align-items: center;
                .p-dataBox-head-tTypeBox-btn{
                    font-size: 14px;
                    color: $fc6;
                    line-height: 1;
                    font-weight: 500;
                    margin-right: 24px;
                    cursor: pointer;
                    position: relative;
                    display: flex;
                    align-items: center;
                    height: 100%;
                }
                .p-dataBox-head-tTypeBox-btn.active{
                    color: $fc5;
                }
                .p-dataBox-head-tTypeBox-btn.active::after{
                    content: "";
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: -22px;
                    height: 4px;
                    background: $active;
                }
            }
            .p-dataBox-head-otherBox{
                display: flex;
                align-items: center;
                .p-dataBox-head-otherBox-profitBox{
                    display: flex;
                    align-items: center;
                    .p-dataBox-head-otherBox-profitBox-title{
                        font-size: 14px;
                        line-height: 1;
                        font-weight: 500;
                        color: $fc5;
                    }
                    .p-dataBox-head-otherBox-profitBox-content{
                        font-size: 14px;
                            line-height: 1;
                            font-weight: 500;
                        span{
                            font-size: 12px;
                            color: $fc6;
                            margin-left: 4px;
                        }
                    }
                    .p-dataBox-head-otherBox-profitBox-content.raise{
                        color: $raise;
                    }
                    .p-dataBox-head-otherBox-profitBox-content.fall{
                        color: $fall;
                    }
                }
                .p-dataBox-head-otherBox-allClose{
                    font-size: 14px;
                    line-height: 1;
                    font-weight: 500;
                    color: $fc5;
                    background: $active;
                    padding: 3px 12px;
                    border-radius: 4px;
                    margin-left: 24px;
                    cursor: pointer;
                }
            }
        }

        .p-dataBox-filterBox{
            display: flex;
            margin-top: 20px;
            padding: 0 30px;
            .p-dataBox-filterBox-block{
                margin-right: 20px;
                .p-dataBox-filterBox-block-title{
                    font-size: 14px;
                    color: $fc6;
                    line-height: 1;
                    font-weight: 400;
                }
                .p-dataBox-filterBox-block-ddWrapper{
                    margin-top: 10px;
                    .p-dataBox-filterBox-block-ddBox{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 0 20px;
                        border: 1px solid $lc4;
                        border-radius: 4px;
                        height: 40px;
                        width: 200px;   
                        cursor: pointer;
                        .p-dataBox-filterBox-block-ddBox-text{
                            font-size: 14px;
                            color: $fc5;
                            font-weight: 400;
                            line-height: 1;
                        }
                        .p-dataBox-filterBox-block-ddBox-image{
                            fill: $bg7;
                        }
                    }
                    .p-dataBox-filterBox-block-ddWrapper-picker{
                        .ant-calendar-picker-input{
                            height: 40px;
                            width: 220px;
                            .ant-calendar-range-picker-input{
                                width: 80px;
                            }
                        }
                        input{
                            text-align: center;
                        }
                        span{
                            display: flex;
                            align-items: center;
                        }
                    }
                    .p-dataBox-filterBox-block-ddWrapper-search{}
                }
            }
            .p-dataBox-filterBox-buttonBox{
                display: flex;
                align-items: flex-end;
                margin-right: 20px;
                .p-dataBox-filterBox-buttonBox-btn{
                    font-size: 14px;
                    color: $fc5;
                    line-height: 1;
                    font-weight: 500;
                    padding: 13px 26px;
                    border-radius: 4px;
                    cursor: pointer;
                }
                .reset{
                    border: 1px solid $lc4;
                }
                .search{
                    background: $active;
                }
            }
        }
        .p-dataBox-listBox{
            padding: 0 30px;
            flex: 1;
            margin-top: 20px;
            .p-dataBox-listBox-list-tr{
                td:first-child{
                    border-left: 2px solid ;
                }
            }
            .p-dataBox-listBox-list-tr.raise-1{
                td:first-child{
                    border-left-color: $raise;
                }
            }
            .p-dataBox-listBox-list-tr.fall-1{
                td:first-child{
                    border-left-color: $fall;
                }
            }
            .p-dataBox-listBox-list{
                .ant-table-thead{
                    th{
                        padding: 0;
                        .a-listBox-list-title{
                            font-size: 12px;
                            line-height: 1;
                            font-weight: 400;
                            color: $fc6;
                            height: 44px;
                            line-height: 44px;
                            margin-left: 30px;
                        }
                    }
                    .ant-table-row-cell-last{
                        text-align: right;
                        padding-right: 30px;
                    }
                }
                .p-dataBox-listBox-list-cell{
                    margin-left: 14px;
                    font-family: DIN-Regular;
                    .p-dataBox-listBox-list-cell-nameBox{
                        display: flex;
                        .p-dataBox-listBox-list-cell-nameBox-name{
                            font-size: 14px;
                            color: $fc5;
                            font-weight: 400;
                            line-height: 1;
                        }
                        .p-dataBox-listBox-list-cell-nameBox-lever{
                            padding: 2px 5px;
                            border: 1px solid $lc6;
                            color: $fc5;
                            line-height: 1;
                            font-size: 10px;
                            font-weight: 400;
                            margin-left: 5px;
                            span{
                                color: $fc5;
                                line-height: 1;
                                font-size: 10px;
                                font-weight: 400;
                                
                            }
                        }
                    }
                    .p-dataBox-listBox-list-cell-action{
                        display: flex;
                        justify-content: flex-end;
                        .p-dataBox-listBox-list-cell-action-btn{
                            font-size: 12px;
                            color: $fc5;
                            font-weight: 400;
                            line-height: 1;
                            padding: 8px 16px;
                            border: 1px solid $lc4;
                            border-radius: 4px;
                            margin-left: 10px;
                            cursor: pointer;
                        }
                    }
                    .p-dataBox-listBox-list-cell-idBox{
                        display: flex;
                        .p-dataBox-listBox-list-cell-idBox-id{}
                        .p-dataBox-listBox-list-cell-idBox-image{
                            cursor: pointer;
                            display: flex;
                            align-items: center;
                            margin-left: 5px;
                        }
                    }
                    .raise{
                        color: $raise;
                    }
                    .fall{
                        color: $fall;
                    }
                }
            }
        }
    }
}

.position-dd-menu{
    max-height: 220px;
    overflow: auto;
    .position-dd-menu-search{
        display: flex;
        border: 1px solid $lc4;
        margin: 20px 20px 0;
        height: 40px;
        width: 290px;
        border-radius: 4px;
        .position-dd-menu-search-image{
            fill: $bg10;
            display: flex;
            align-items: center;
            margin-left: 15px;
        }
        .position-dd-menu-search-inputBox{
            flex: 1;
            margin-left: 10px;
            input{
                outline: none;
                border: 0;
                box-shadow: none;
                padding: 0;
                height: 100%;
            }
        }
        .position-dd-menu-search-close{
            cursor: pointer;
            display: flex;
            align-items: center;
            margin-left: 10px;
            margin-right: 15px;
        }
    }
    .ant-dropdown-menu-item{
        
    }
    .position-dd-menu-cell{
        display: flex;
        align-items: center;
        height: 40px;
        cursor: pointer;
        .position-dd-menu-cell-image{
            width: 24px;
            height: 24px;
            margin-right: 15px;
            svg{
                width: 100%;
            }
        }
        .position-dd-menu-cell-text{
            font-size: 14px;
            line-height: 1;
            font-weight: 400;
            color: $fc5;
            width: 100%;
            
        }
        .position-dd-menu-cell-text:hover{
            color: $active;
        }
    }
    //选中dd的样式
    .ant-dropdown-menu-item:hover{
        background: transparent;
        .position-dd-menu-cell{
            .position-dd-menu-cell-text{
                color: $active;
            }
        }
    }
}
.personalInfo {
    width: 100%;
    height: 104px;
    padding: 0 calc((100% - 1200px) / 2);
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #FAFAFA;

    .flex {
        display: flex;
        align-items: center;
    }

    .left {
        @extend .flex;

        .personalName {
            font-weight: bold;
        }

        .personalImage {
            width: 38px;
            height: 38px;
            line-height: 38px;
            border-radius: 19px;
            background: #F1F1F1;
            text-align: center;
            font-size: 15px;
            margin-right: 10px;
        }

        .lineBox {
            display: flex;

            &:last-child {
                margin-top: 10px;
            }

            .edit {
                margin-left: 10px;
                margin-right: 10px;
                cursor: pointer;
            }

            .dataValidBox {
                display: flex;
                color: #707A8A;

                &:not(first-child) {
                    margin-right: 20px;
                }

                .validIcon {
                    margin-left: 5px;
                }
            }
        }

        .kycBoxText {
          margin-left: 1rem;
            padding: 0 8px;
            height: 20px;
            background: #fef6d8;
            border-radius: 2px;
            color: #C99400;
            font-size: 12px;
        }
    }


    .right {
        @extend .flex;

        .top {
            @extend .flex;

            img {
                cursor: pointer;
            }
        }

        span {
            &.title {
                color: #707A8A;
                margin-right: 5px;
            }

            &.money {
                font-size: 20px;
                margin-right: 5px;
            }
        }

        .rightArrow {
            margin-left: 10px;
            cursor: pointer;
        }

        .button {
            width: 94px;
            height: 40px;
            line-height: 40px;
            background: #FFD140;
            border-radius: 4px;
            text-align: center;
            margin-left: 40px;
            cursor: pointer;
        }
    }

}
.stage-IOSDownloadGuide{
    display: flex;
    flex-direction: column;
    height: 100%;
    .main{
        width: 1200px;
        margin: 0 auto;
        flex: 1;
        .s-title{
            font-size: 32px;
            font-weight: 700;
            margin: 20px 0;
        }
        .step-1{
            .step-1-title{
                font-size: 18px;
                font-weight: 700;
            }
            .qr-box{
                width: 150px;
                height: 150px;
                margin-left: -10px;
                img{
                    width: 100%
                }
            }
            .qr-desc{
                font-weight: 400;
                font-size: 12px;
                color: #7F7F7F;
            }
            .img-0-box{
                width: 345px;
                margin: 50px 0;
                img{
                    width: 100%;
                }
            }
            .step-1-desc{
                font-weight: 400;
                font-size: 18px;
            }
        }
        .step-2{
            margin-top: 50px;
            margin-bottom: 50px;
            .step-2-title{
                font-size: 18px;
                font-weight: 700;
            }
            .step-2-images{
                margin-top: 30px;
                display: flex;
                justify-content: space-between;
                .img-1-box{
                    width: 345px;
                    img{
                        width: 100%;
                    }
                }
                .img-2-box{
                    width: 345px;
                    margin-top: 20px;
                    img{
                        width: 100%;
                    }
                }
                .img-3-box{
                    width: 345px;
                    img{
                        width: 100%;
                    }
                }
                .img-4-box{
                    width: 345px;
                    img{
                        width: 100%;
                    }
                }
            }
        }
    }
}
@import './../../../style/color.scss';
.module-switchView{
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 214px;
    border: 1px solid #babdc2;
    border-radius: 4px;
    height: 40px;
    padding: 0 14px;
    cursor: pointer;
    .sw-title{

    }
    .sw-image{}
}
.dpBox{
    max-height: 300px;
    overflow: hidden;
    padding: 5px;
    .ant-dropdown-menu{
        overflow: auto;
        max-height: 300px;
        background: #fff;
        .ant-dropdown-menu-item{
            font-size: 14px;
            font-family: DIN-Regular;
            font-weight: 400;
            color: $fc5;
        }
        .ant-dropdown-menu-item:hover{
            background: #F5F5F5;
        }
    }
}
@import './../../../style/color.scss';
.module-transferHistoryView{
    .thv-header{
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid $lc7;
        padding-bottom: 25px;
        .thv-header-title{
            font-size: 16px;
            color: $fc5;
            font-weight: 500;
        }
        .thv-header-close{
            cursor: pointer;
        }
    }
    .thv-listBox{
        .thv-listBox-list-row{
            .thv-listBox-list-row-td-wrapper{
                display: flex;
                .thv-listBox-list-row-td-wrapper-image{
                    width: 24px;
                    height: 24px;
                    margin-right: 14px;
                    svg{
                        width: 100%;
                    }
                }
                .thv-listBox-list-row-td-wrapper-text{
                    font-size: 16px;
                    font-family: DIN-Regular;
                    font-weight: 400;
                    color: $fc5;;
                }
            }
        }
    }
}
$bg1: #1B1E26;

$tc1: #494e5b;
$tc2: #1e2129;
$tc3: #848E9C;

.module-download{
    background: $bg1;
    padding: 48px 0;
    .content{
        width: 1200px;
        margin: 0 auto;
        .d-title{
            color: $tc2;
            font-size: 34px;
            font-weight: 600;
            text-align: center;
            color: #fff;
        }
        .d-desc{
            font-size: 16px;
            font-weight: 400;
            text-align: center;
            color: $tc3;
            margin-top: 16px;
        }
        .d-content{
            display: flex;
            margin-top: 38px;
            .d-content-image{
                width: 693px;
                img{
                    width: 100%;
                }
            }
            .d-content-info{
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                margin-left: 50px;
                .d-content-info-qrBox{
                    display: flex;
                    .d-content-info-qrBox-image{
                        width: 86px;
                        height: 86px;
                        img{
                            width: 100%;
                        }
                    }
                    .d-content-info-qrBox-text{
                        margin-left: 50px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-evenly;
                        .d-content-info-qrBox-text-title{
                            font-size: 14px;
                            font-weight: 600;
                            text-align: left;
                            color: #fff;
                            font-family: Arial, Arial-Regular;
                        }
                        .d-content-info-qrBox-text-desc{
                            font-size: 12px;
                            font-family: ArialMT, ArialMT-Regular;
                            font-weight: 400;
                            text-align: left;
                            color: $tc3;
                        }
                    }
                    
                }
                .d-content-info-row{
                    display: flex;
                    margin-bottom: 30px;
                    .d-content-info-row-button{
                        margin: 0 22px;
                        .d-content-info-row-button-img{
                            fill: #fff;
                        }
                        .d-content-info-row-button-title{
                            font-size: 16px;
                            font-family: ArialMT, ArialMT-Regular;
                            font-weight: 400;
                            text-align: center;
                            color: #fff;
                            margin-top: 13px;
                        }
                    }
                }
            }
        }
    }
}
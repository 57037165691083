.marqueeCon {
  display: flex;
  padding-top: 20px;
  .marquee{
    display: flex;
    overflow: hidden;
    width: 100%;
    .rotateMe {
      display: flex;
      animation: marquee 80s linear infinite;
      animation-delay: 1s;
      img{
        max-width: 15%;
        margin: 0 10px !important;
        object-fit: contain;
      }
      &:hover {
        animation-play-state: paused;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .marqueeCon {
    .marquee{
      .rotateMe {
        > img {
          max-width: 10%;
          margin: 0 10px !important;
        }
      }
    }
  }
}


@keyframes marquee {
  0% {
    transform: translate(0%, 0);
  }

  100% {
    transform: translate(-50%, 0);
  }
}
@import "../../style/variable";

$theme: #181A1F;
$theme-light: #FFFFFF;
$bg: #1E2026;
$bg-light: #CFCFCF;
$select: #1E2026;
$select-light: #EEEEEE;
$line: #2A3439;
$line-light: #EEEEEE;
$line-grey: #A7A7A7;
$font: #C7C7CC;
$font1: #ABBCD2;
$font2: #12161C;
$active: #FFD140;
$color1: #858E9D;
$up: #2CBC70;
$down: #D04B61;
$btn-linear: linear-gradient(to right, #FFCD6D, #FFB31F);
$WHITE: #fff;

$account-margin: 20px;



@mixin webp-jpg($url) {
  @at-root &.webp {
    background-image: url($url + '.webp');
  }
  @at-root &.org {
    background-image: url($url + '.jpg');
  }
}

@mixin webp-png($url) {
  @at-root &.webp {
    background-image: url($url + '.webp');
  }
  @at-root &.org {
    background-image: url($url + '.png');
  }
}

@mixin un-select() {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  cursor: default;
}

@mixin un-select-only() {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

@mixin un-wrap() {
  white-space: nowrap;
  word-break: keep-all;
}

@mixin page-box() {
  margin: 0 $account-margin 20px $account-margin;
  box-shadow: 0 0 5px 0 #D1D1D1;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
}

@mixin modal-base() {
  .ant-modal-content {
    background: none;
  }
  .ant-btn:hover,
  .ant-btn:focus {
    border-color: #e3b914;
  }

  .ant-modal-close-x {
    height: 44px;
    line-height: 44px;
  }

  .ant-modal-header {
    text-align: center;
    border: none;
    height: 44px;
    padding: 0;

    .ant-modal-title {
      height: 44px;
      line-height: 44px;
      font-weight: normal;
    }

  }

  .ant-modal-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    min-height: 120px;
    text-align: center;
  }
  .ant-modal-footer {
    border: none;

    div {
      display: flex;
      justify-content: center;
    }

    .ant-btn {
      width: 200px;
      height: 40px;
      line-height: 40px;
      font-size: 18px;
      font-weight: 500;
      border: none;
      margin: 0 15px;
    }
  }
}

@mixin modal-light() {
  .ant-modal-header {
    background: $btn-linear;

    .ant-modal-title {
      color: #333333;
    }
  }
  .ant-modal-body {
    background: #ffffff;
    color: #333333;
  }
  .ant-modal-footer {
    background: #ffffff;
  }
  .ant-btn {
    background: #EAEAEA !important;
    color: #6E6F72 !important;
  }
  .ant-btn-primary {
    background: $btn-linear !important; //border-color: #e2be30;
    color: #333333 !important;
  }
}

@mixin modal-night() {
  .ant-modal-header {
    background: $bg;

    .ant-modal-title {
      color: #ffffff;
    }
  }
  .ant-modal-body {
    background: $theme;
    color: #FFFFFF;
  }
  .ant-modal-footer {
    background: $theme;
  }
  .ant-btn {
    background: $bg !important;
    color: #FFFFFF !important;
  }
  .ant-btn-primary {
    background: $active !important;
    color: #333333 !important;
  }
}

@mixin emulate-input-night() {
  border-color: $bg;
  background: $theme;
  > label {
    color: #5E6469;
  }
  > input {
    color: #fff;
  }
  > .controller {
    .btn {
      border-left: 1px solid $bg;
      color: #C8C8C8;
    }
  }
}

@mixin common-crypto-title() {
  text-align: center;
  font-size: 22px;
  font-weight: 500;
  margin: 40px 0;
}

@mixin common-crypto-page() {
  display: flex;
  position: relative;
  margin-bottom: 20px;
  height: 50px;
  padding-right: 200px;
  //@at-root &.zh-CN {
  //  > .title {
  //    width: 100px;
  //  }
  //}
  > .title {
    text-align: right;
    height: 50px;
    line-height: 50px;
    margin-right: 30px;
    font-size: rem(16px);
    font-weight: 500;
    width: 170px;
  }

  > .content {
    width: 450px;

    > div {
      outline: none !important;
    }

    &.text-btn {
      @include common-button();
      text-align: center;
      font-size: 14px;
      color: $active;
      text-decoration: underline;
    }

    &.buttons {
      display: flex;
      justify-content: space-between;

      &.collapse {
        justify-content: flex-start;

        .btn {
          &:first-child {
            margin-right: 30px;
          }
        }
      }

      .btn {
        background: #F9F9F9;
        text-align: center;
        height: 44px;
        line-height: 44px;
        border: 1px solid #DDDDDD;
        width: 213px;
        font-size: 16px;
        @include common-button();
      }

      .active {
        background: $active;
        border: none;
      }
    }

    &.submit {
      border: 0;
      background: $active;
      height: 51px;
      line-height: 51px;
      font-size: 16px;
      border-radius: 5px;
      cursor: pointer;
      text-align: center;
      margin-top: 20px;
      @include common-button();

      &.error {
        background: #E4E4E4;
        color: #333333;
        @include un-select();
      }
    }

    .emulate-input {
      height: 100%;
      margin: 0;
      border-color: #DDDDDD;
      border-radius: 4px;

      &.exhibit {
        > input {
          text-indent: 42px;
        }
      }

      > label {
        left: 20px;
      }

      > input {
        color: #666666;
        font-size: 14px;
        text-indent: 20px;
      }

      .icon {
        position: absolute;
        left: 12px;
      }
    }

    .emulate-select-country {
      height: 50px;
      margin: 0;
      border-color: #DDDDDD;

      .emulate-select-options {
        top: 49px;
      }
    }

    .fakeInput {
      position: relative;
      height: 50px;
      line-height: 50px;
      background: #F9F9F9;
      font-size: 14px;
      color: #999999;
      padding-left: 20px;
    }
  }


}


@mixin common-button-animation() {
  &:hover {
    box-shadow: 0 2px 3px #f2f2f2;
  }
  &:active {
    box-shadow: 0 2px 3px #ececec inset;
  }
}

@mixin submit-btn() {
  position: relative;
  right: 0;
  font-size: rem(14px);
  border-radius: 4px;
  color: #fff;
  display: inline-block;
  background-color: #5E96FF;
  width: rem(96px);
  height: rem(35px);
  text-align: center;
  line-height: rem(35px);
  cursor: pointer;
}

@import './../../style/color.scss';
.stage-agentMain{
    min-width: 1200px;
    height: 100%;
    display: flex;
    flex-direction: column;
    background: #fafafa;
    border-top: 70px solid;
    .am-main{
        display: flex;
        .am-menu{
            width: 17%;
            max-width: 320px;
            box-shadow: 2px 0px 4px 0px rgba(241,241,241,0.50); 
            background: #fff;
            margin-right: 1px;
            .am-menu-wrapper{
                display:  flex;
                justify-content: space-between;
                cursor: pointer;
                .am-menu-wrapper-image{
                    display: flex;
                    align-items: center;
                    margin-right: 7px;
                }
            }
            .am-menu-row{
                height: 48px;
                display: flex;
                align-items: center;
                font-weight: 400;
                font-size: 14px;
                box-shadow: 2px 0px 4px 0px rgba(241,241,241,0.50);
                cursor: pointer;
                .am-menu-row-image{
                    fill: $bg7;
                    margin-left: 20px;
                    margin-right: 10px;
                }
                .am-menu-row-text{
                    color: $fc5;
                    
                }
            }
            .am-menu-row.active{
                font-weight: 500;
                border-left: 4px solid $active;
                background: #F1F1F1;
                .am-menu-row-image{
                    fill: $active;
                    margin-left: 16px;
                }
            }
            .am-menu-row.hide{
                max-height: 0;
                // transition-timing-function: ease-in;
                // transition: 0.2s;
                transition: max-height 0.15s ease-in;
                overflow: hidden
            }
            .am-menu-row.show{
                max-height: 48px;
                // transition-timing-function: ease-out;
                // transition: 0.2s;
                transition: max-height 0.15s ease-out;
            }
            .am-menu-antRow{
                .ant-menu-submenu-title{
                    font-weight: 400;
                    font-size: 14px;
                    color: $fc5;
                }
            }
        }
        .am-content{
            // padding-left: 20px;
            // padding-right: 20px;
            flex: 1;
            // background: #fafafa;
            overflow: hidden;
        }
    }
}
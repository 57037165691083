@import "variable";
/*文字排版*/
.fs12 {
  font-size: 12px;
}

.fs13 {
  font-size: 13px;
}

.fs14 {
  font-size: 14px;
}

.fs18 {
  font-size: 18px;
}

.fs16 {
  font-size: 16px;
}

.fs20 {
  font-size: 20px;
}

.fs30 {
  font-size: 30px;
}

.fs40 {
  font-size: 40px;
}

.fb {
  font-weight: bold;
}

.fn {
  font-weight: normal;
}

.t2 {
  text-indent: 2em;
}

.lh100 {
  line-height: 100%;
}

.lh150 {
  line-height: 150%;
}

.lh180 {
  line-height: 180%;
}

.lh200 {
  line-height: 200%;
}

.lh300 {
  line-height: 300%;
}

.lh500 {
  line-height: 500%;
}

.lh600 {
  line-height: 600%;
}

.lh700 {
  line-height: 700%;
}

.lh800 {
  line-height: 800%;
}

.lh900 {
  line-height: 900%;
}

.lh1000 {
  line-height: 1000%;
}

.unl {
  text-decoration: underline;
}

.no_un {
  text-decoration: none;
}


/*定位*/
.tl {
  text-align: left;
}

.tc {
  text-align: center;
}

.tr {
  text-align: right;
}

.bc {
  margin-left: auto;
  margin-right: auto;
}

/*全称是“。blockCenter”，作用为使块级元素居中。直接试用她是不能使块级元素居中的，还需设定宽度。*/
.fl {
  float: left;
  display: inline;
}

/*设置display：inline 为了解决IE6的双外边距bug。在IE6下，如果对元素设置了浮动，同时设置了margin-left或者margin-right，margin值会加倍，如设置margin-left：10px在IE6下会显示为margin-left：20px。解决办法就是设置display：inline。*/
.fr {
  float: right;
  display: inline;
}

.cb {
  clear: both;
}

.cl {
  clear: left;
}

.cr {
  clear: right;
}

.clearfix:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.clearfix {
  display: inline-block;
}

/*用于在父容器直接清除子元素浮动。*/
* html .clearfix {
  height: 1%;
}

/*只在IE6中生效*/
.Clearfix {
  display: block;
}

.vm {
  vertical-align: middle;
}

.pr {
  position: relative;
}

.pa {
  position: absolute;
}

.abs-right {
  position: absolute;
  right: 0;
}

.zoom {
  zoom: 1;
}

/*(IE特有属性)触发hasLayout*/
.hidden {
  visibility: hidden;
}

.none {
  display: none;
}


/*长度高度*/
.w10 {
  width: 10px;
}

.w20 {
  width: 20px;
}

.w30 {
  width: 30px;
}

.w40 {
  width: 40px;
}

.w50 {
  width: 50px;
}

.w60 {
  width: 60px;
}

.w70 {
  width: 70px;
}

.w80 {
  width: 80px;
}

.w90 {
  width: 90px;
}

.w100 {
  width: 100px;
}

.w200 {
  width: 200px;
}

.w250 {
  width: 250px;
}

.w300 {
  width: 300px;
}

.w400 {
  width: 400px;
}

.w500 {
  width: 500px;
}

.w600 {
  width: 600px;
}

.w700 {
  width: 700px;
}

.w800 {
  width: 800px;
}

.w {
  width: 100%;
}

.h50 {
  height: 50px;
}

.h80 {
  height: 80px;
}

.h100 {
  height: 100px;
}

.h200 {
  height: 200px;
}

.h {
  height: 100%;
}


/*边距*/
.m10 {
  margin: 10px;
}

.m15 {
  margin: 15px;
}

.m30 {
  margin: 30px;
}

.mt5 {
  margin-top: 5px;
}

.mt10 {
  margin-top: 10px;
}

.mt15 {
  margin-top: 15px;
}

.mt20 {
  margin-top: 20px;
}

.mt30 {
  margin-top: 30px;
}

.mt40 {
  margin-top: 40px;
}

.mt50 {
  margin-top: 50px;
}

.mt100 {
  margin-top: 100px;
}

.mb5 {
  margin-bottom: 5px;
}

.mb10 {
  margin-bottom: 10px;
}

.mb15 {
  margin-bottom: 15px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb30 {
  margin-bottom: 30px;
}

.mb50 {
  margin-bottom: 50px;
}

.mb100 {
  margin-bottom: 100px;
}

.ml5 {
  margin-left: 5px;
}

.ml10 {
  margin-left: 10px;
}

.ml15 {
  margin-left: 15px;
}

.ml20 {
  margin-left: 20px;
}

.ml25 {
  margin-left: 25px;
}

.ml30 {
  margin-left: 30px;
}

.ml50 {
  margin-left: 50px;
}

.ml100 {
  margin-left: 100px;
}

.mr5 {
  margin-right: 5px;
}

.mr10 {
  margin-right: 10px;
}

.mr15 {
  margin-right: 15px;
}

.mr20 {
  margin-right: 20px;
}

.mr30 {
  margin-right: 30px;
}

.mr40 {
  margin-right: 40px;
}

.mr50 {
  margin-right: 50px;
}

.mr100 {
  margin-right: 100px;
}


.p10 {
  padding: 10px;
}

.p15 {
  padding: 15px;
}

.p30 {
  padding: 30px;
}

.pt5 {
  padding-top: 5px;
}

.pt10 {
  padding-top: 10px;
}

.pt15 {
  padding-top: 15px;
}

.pt20 {
  padding-top: 20px;
}

.pt30 {
  padding-top: 30px;
}

.pt50 {
  padding-top: 50px;
}

.pt100 {
  padding-top: 100px;
}

.pb5 {
  padding-bottom: 5px;
}

.pb10 {
  padding-bottom: 10px;
}

.pb15 {
  padding-bottom: 15px;
}

.pb20 {
  padding-bottom: 20px;
}

.pb30 {
  padding-bottom: 30px;
}

.pb50 {
  padding-bottom: 50px;
}

.pb100 {
  padding-bottom: 100px;
}

.pl5 {
  padding-left: 5px;
}

.pl10 {
  padding-left: 10px;
}

.pl15 {
  padding-left: 15px;
}

.pl20 {
  padding-left: 20px;
}

.pl30 {
  padding-left: 30px;
}

.pl50 {
  padding-left: 50px;
}

.pl100 {
  padding-left: 100px;
}

.pr5 {
  padding-right: 5px;
}

.pr10 {
  padding-right: 10px;
}

.pr15 {
  padding-right: 15px;
}

.pr20 {
  padding-right: 20px;
}

.pr30 {
  padding-right: 30px;
}

.pr50 {
  padding-right: 50px;
}

.pr100 {
  padding-right: 100px;
}

.shadow {
  box-shadow: 0 0 5px rgba($color: #000000, $alpha: 0.1);
}

.br2 {
  border-radius: 2px;
  overflow: hidden;
}

.br5 {
  border-radius: 5px;
  overflow: hidden;
}

.br10 {
  border-radius: 10px;
  overflow: hidden;
}

body {
  color: #333;
}

.center-area {
  width: 1080px;
  margin-left: auto;
  margin-right: auto;
}

.raise {
  color: $raise;
}

.fall {
  color: $fall;
}

.white {
  color: #fff;
}

.bold {
  font-weight: bold;
}

p {
  margin: 0;
  padding: 0;
  // cursor: pointer;
  margin-bottom: 0em !important;
  -webkit-margin-before: 0em;
  -webkit-margin-after: 0em;
}

ul, ol, dl {
  margin-bottom: 0;
}

ul, li {
  list-style: none;
}

i, em, b {
  font-style: normal;
}

input {
  outline: none;
}

/*控制整个滚动条*/
::-webkit-scrollbar {
  background-color: #fff;
  width: 5px;
  height: 5px;
  background-clip: padding-box;
}

/*滚动条两端方向按钮*/
::-webkit-scrollbar-button {
  background-color: white;
}

/*滚动条中间滑动部分*/
::-webkit-scrollbar-thumb {
  background-color: lightgrey;
  border-radius: 5px;
}

/*滚动条右下角区域*/
::-webkit-scrollbar-corner {
  background-color: white;
}

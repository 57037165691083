@import './../../style/color.scss';
.stage-userStatement{
    min-height: 880px;
    padding-left: 20px;
    padding-right: 20px;
    background: #fff;
    .us-title{
        font-size: 32px;
        font-weight: 500;
        color: $fc6;
        padding-top: 32px;;
    }
    .us-filterBox{
        display: flex;
        margin-top: 24px;
        margin-bottom: 24px;
        .us-filterBox-inputBox{
            margin-right: 24px;
            input{
                box-shadow: none;
                outline: none;
                height: 40px;
                width: 214px;
            }
            input:focus {
                border-color: $active;
            }
        }
        .us-filterBox-pickerBox{
            margin-right: 24px;
            .us-filterBox-pickerBox-picker{
                height: 40px;
                .ant-calendar-picker-input{
                    height: 40px;
                    input{
                        font-size: 14px;
                        font-family: DIN-Medium;
                        font-weight: 500;
                        color: $fc6;
                        width: 71px;
                    }
                }
            }
        }
        .us-filterBox-switchBox{
            margin-right: 24px;
        }
        .us-filterBox-btn{
            width: 88px;
            height: 40px;
            line-height: 40px;
            background: $active;
            border-radius: 4px;
            font-size: 14px;
            color: $fc6;
            font-weight: 500;
            text-align: center;
            cursor: pointer;
        }
    }

    .us-dataBox{
        display: flex;
        margin: 24px 0;
        .us-dataBox-row{
            display: flex;
            align-items: center;
            margin-right: 24px;
            .us-dataBox-row-title{
                font-size: 14px;    
                font-weight: 500;
                color: $fc6;
            }
            .us-dataBox-row-content{
                font-size: 14px;
                font-family: DIN-Medium;
                font-weight: 500;
            }
            .red{
                color: $fall;
            }
            .green{
                color: $raise;
            }
        }
    }
    .us-dataBox-list-row{
        font-family: DIN-Regular;
        font-weight: 400;
        font-size: 14px;
    }
}
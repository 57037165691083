@import './../../../style/color.scss';
.stage-incomeInfoView{
    background: #fff;
    border-right: 6;
    overflow: hidden;
    .iiv-catBox{
        display: flex;
        .iiv-catBox-cat{
            font-size: 14px;
            color: $fc7;
            font-weight: 500;
            margin-right: 30px;
            position: relative;
            cursor: pointer;
        }
        .iiv-catBox-cat.active{
            color: #151519;
        }
        .iiv-catBox-cat.active::after{
            content: "";
            position: absolute;
            left: 0;
            bottom: -18px;
            height: 4px;
            width: 100%;
            background: $active;
            
        }
    }
    .iiv-infoBox{
        padding: 30px;
        .iiv-infoBox-row{
            display: flex;
            .iiv-infoBox-row-block{
                flex: 1;
                width: 50%;
                .iiv-infoBox-row-block-title{
                    font-size: 14px;
                    font-weight: 400;
                    color: $fc5;
                    line-height: 1;
                }
                .iiv-infoBox-row-block-titleSmall{
                    font-size: 12px;
                    font-weight: 400;
                    color: $fc7;
                    margin-top: 1px;
                    //line-height: 1;
                }
                .iiv-infoBox-row-block-content{
                    font-size: 32px;
                    font-family: DIN, DIN-Medium;
                    font-weight: 500;
                    color: $fc5;
                    line-height: 1;
                    margin-top: 8px;
                    display: flex;
                    flex-direction: column;
                }
                .first {
                    font-size: 30px;
                    span{
                        font-size: 18px;
                        color: $fc7;
                        // text-align: center;
                        margin-top: 10px;
                        margin-bottom: -10px;
                    }
                }
            }
            .iiv-infoBox-row-block:first-child{
                border-right: 1px solid $lc4;
            }
            .iiv-infoBox-row-block:last-child{
                div{
                    padding-left: 30px;
                }
            }
        }
        .iiv-infoBox-row:last-child{
            border-top: 1px solid $lc4;
            .iiv-infoBox-row-block{
                padding-bottom: 10px;
                .iiv-infoBox-row-block-title{
                    margin-top: 24px;
                }
            }
        }
        .iiv-infoBox-row:first-child{
            .iiv-infoBox-row-block{
                padding-top: 10px;
                .iiv-infoBox-row-block-content{
                    margin-bottom: 24px;
                }
            }
        }
    }
}
.content{
    width: 1280px;
    margin: 0 auto;
    .tabs{
        display: flex;
        border-bottom: 1px solid #EAECEF;
        color: #848E9C;
        .tab{
            padding: 22px 0;
            cursor: pointer;
            &:first-child{
                margin-right: 40px;
            }
            &.active{
                position: relative;
                &::before{
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    display: block;
                    content: '';
                    width: 100%;
                    height: 4px;
                    background: #FFD140;
                }
               
            }
        }
    }
    .tabContent{
        margin: 40px 0;
        border: 1px solid #E6E8EC;
        padding: 27px 24px;
    }
}
@import './../../trade/style/color.scss';
.module-verticalQuoteView{
    width: 1200px;
    margin: 0 auto;
    .vqv-list{
        .vqv-list-row{
            display: flex;
            justify-content: space-between;
            .vqv-list-row-block{
                flex: 1;
                .vqv-list-row-block-nameBox{
                    display: flex;
                    .vqv-list-row-block-nameBox-name{
                        font-size: 16px;
                        font-family: DIN-Regular;
                        font-weight: 400;
                        color: #fff;
                    }
                    .vqv-list-row-block-nameBox-rate{
                        font-size: 16px;
                        font-family: DIN-Regular;
                        font-weight: 400;
                        &.green{
                            color: $raise;
                        }
                        &.red{
                            color: $fall;
                        }
                    }
                }
                .vqv-list-row-block-price{
                    font-size: 24px;
                    font-family: DIN-Medium;
                    font-weight: 500;
                    color: #fff;
                }
                .vqv-list-row-block-fiat{
                    font-size: 14px;
                    font-family: DIN-Regular;
                    font-weight: 400;
                    color: #BABDC2;
                }
            }
        }
    }
}
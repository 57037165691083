.paymentMethodContent {
    height: 800px;
    margin: 0 auto;
    h2 {
        padding: 0 calc((100% - 1200px) / 2);
        font-size: 32px;
        line-height: 80px;
        font-weight: bold;
        background-color: #fafafa;
    }
}

.collectionMethodCard {
    height: 800px;
}

.collectionMethodCard,
.collectionMethod {
    width: 512px;
    margin: 0 auto;
    > div {
        margin-top: 40px;
        font-size: 16px;
        .title {
            color: #848e9c;
            margin-bottom: 12px;
            font-size: 14px;
        }
        input {
            width: 100%;
            height: 40px;
            line-height: 40px;
            border: 1px solid #e6e8ec;
            border-radius: 8px;
            padding: 12px;
        }
        h3 {
            font-size: 14px;
        }
        img {
            margin-right: 10px;
        }
    }
    .hintBlock {
        display: flex;
        align-items: flex-start;
        width: 100%;
        padding: 12px 20px;
        background: #fef6d8;
        margin-bottom: 16px;
        img {
            margin-top: 3px;
        }
    }

    .remarkText {
        font-size: 12px;
        color: #848e9c;
    }
    .buttonContent {
        display: flex;
        justify-content: space-between;
        margin-bottom: 118px;
        span, button {
            width: calc(50% - 8px);
            height: 40px;
            line-height: 40px;
            text-align: center;
            border-radius: 2px;
            border: 0px;
            font-size: 16px;
            cursor: pointer;
            &.cancelButton {
                background: #eaecef;
            }
            &.confirmButton {
                background: #ffd140;
                &:disabled{
                  background: #eaecef;
                }
            }
        }
    }
    .upload {
        .tips{
            font-size: 12px;
            margin-top: 12px;
            color: #848E9C;
        }
        .uploadcontent {
            width: 120px;
            height: 120px;
            border: 1px solid #e6e8ec;
            border-radius: 8px;
            text-align: center;
            cursor: pointer;

            img {
                display: block;
                margin-top: 33px;
                margin-left: 46px;
                margin-bottom: 10px;
            }
        }
        .thumbnailcontent {
            position: relative;
             width: 120px;
            .thumbnail {
                width: 100%;
                margin-bottom: 10px;
            }
            .close {
                position: absolute;
                top: 0;
                right: -10px;
                cursor: pointer;
            }
        }
        .againbutton{
            color: #FFD140;
            cursor: pointer;
            font-size: 14px;
        }
    }
}

.show {
    // >div:first-child{
    display: block;
    // }
}
.hidden {
    //  >div:first-child{
    display: none;
    // }
}

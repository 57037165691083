@import './../../style/color.scss';
.stage-teamManagement{
    padding-left: 20px;
    padding-right: 20px;
    background: #fff;
    .tm-title{
        font-size: 32px;
        font-weight: 500;
        color: $fc6;
        padding-top: 32px;
        line-height: 1;
    }
    .tm-catBox{
        display: flex;
        margin-top: 60px;
        .tm-catBox-cat{
            font-size: 14px;
            color: $fc7;
            font-weight: 500;
            line-height: 1;
            margin-right: 24px;
            cursor: pointer;
            position: relative;
        }
        .tm-catBox-cat.active{
            color: $fc6;
        }
        .tm-catBox-cat.active::after{
            content: '';
            width: 100%;
            height: 4px;
            background: $active;
            position: absolute;
            bottom: -16px;
            left: 0;
        }
    }

    .tm-infoBox{
        display: flex;
        margin-top: 16px;
        .tm-infoBox-block{
            margin-right: 150px;
            .tm-infoBox-block-title{
                font-size: 14px;
                font-weight: 500;
                color: $fc6;
                line-height: 1;
            }
            .tm-infoBox-block-content{
                font-size: 20px;
                font-family: DIN-Medium;
                font-weight: 500;
                color: $fc6;
                line-height: 1;
                margin-top: 8px;
            }
        }
    }
    .tm-listBox{
        margin-top: 24px;
        .tm-listBox-filter{
            display: flex;
            flex-wrap: wrap;
            .tm-funcBox-filter-switchBox{
                margin-right: 24px;
                margin-bottom: 12px;
                >input{
                    border-color: #BABDC2;
                    box-shadow: none;
                    height: 40px;
                }
                input:focus{
                    border-color: $active;
                }
            }
            .tm-funcBox-filter-pickerBox{
                margin-right: 24px;
                margin-bottom: 12px;
                .tm-funcBox-filter-pickerBox-picker{
                    .ant-calendar-picker-input{
                        height: 40px;
                        input{
                            font-size: 14px;
                            font-family: DIN-Medium;
                            font-weight: 500;
                            color: $fc6;
                            width: 71px;
                        }
                    }
                }
            }
            .tm-funcBox-filter-btn{
                min-width: 88px;
                height: 40px;
                line-height: 40px;
                background: $active;
                border-radius: 4px;
                font-size: 14px;
                color: $fc6;
                font-weight: 500;
                text-align: center;
                cursor: pointer;
            }
        }
        .tm-listBox-wrapper{
            margin-top: 24px;
        }
    }
}
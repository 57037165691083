@charset "UTF-8";
.ant-tooltip-inner {
  background: #fff;
  color: #333; }

.ant-tooltip-arrow::before {
  background: white; }

.ant-input-number:hover {
  border-color: #F3AD00; }

.ant-input-number-focused {
  border-color: transparent; }

.wrapperLayer__25Osd {
  position: fixed;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999; }

/* 与 anim.ts 同步 */
.baseButton__18U5B {
  z-index: 1000;
  box-sizing: border-box;
  cursor: pointer; }

.baseButton__18U5B > svg {
  display: block;
  width: 2rem;
  height: 2rem; }

.controls__3BU3E {
  box-sizing: border-box;
  position: absolute;
  top: 0.6rem;
  right: 0.6rem;
  opacity: 0;
  display: flex;
  z-index: 1000;
  border-radius: 5rem;
  transform: translateX(100%);
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms; }

.controls__3BU3E.show__1tLY6 {
  opacity: 0.8;
  transform: translateX(0); }

.controls__3BU3E .pinButton__MnPeR {
  z-index: 1000;
  box-sizing: border-box;
  cursor: pointer;
  margin: 0.4em 0;
  width: 2rem;
  height: 2rem;
  transition: transform 175ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1); }

.controls__3BU3E .pinButton__MnPeR > svg {
  display: block;
  width: 2rem;
  height: 2rem; }

.controls__3BU3E .pinButton__MnPeR:hover {
  opacity: 0.8 !important;
  transform: scale(1.1) !important; }

.controls__3BU3E .pinButton__MnPeR:active {
  opacity: 1 !important;
  transform: scale(1) !important; }

.controls__3BU3E .pinButton__MnPeR:first-of-type {
  margin-left: 0.4rem; }

.controls__3BU3E .pinButton__MnPeR:last-of-type {
  margin-right: 0.4rem; }

.controls__3BU3E .rotate__1DjN2 {
  z-index: 1000;
  box-sizing: border-box;
  cursor: pointer;
  margin: 0.4em 0;
  width: 2rem;
  height: 2rem;
  transition: transform 175ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1); }

.controls__3BU3E .rotate__1DjN2 > svg {
  display: block;
  width: 2rem;
  height: 2rem; }

.controls__3BU3E .rotate__1DjN2:hover {
  opacity: 0.8 !important;
  transform: scale(1.1) !important; }

.controls__3BU3E .rotate__1DjN2:active {
  opacity: 1 !important;
  transform: scale(1) !important; }

.controls__3BU3E .rotate__1DjN2:first-of-type {
  margin-left: 0.4rem; }

.controls__3BU3E .rotate__1DjN2:last-of-type {
  margin-right: 0.4rem; }

.controls__3BU3E .rotate__1DjN2 svg {
  width: 1.75rem;
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1); }

.controls__3BU3E .rotateLeft__1KOxL {
  z-index: 1000;
  box-sizing: border-box;
  cursor: pointer;
  margin: 0.4em 0;
  width: 2rem;
  height: 2rem;
  transition: transform 175ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1); }

.controls__3BU3E .rotateLeft__1KOxL > svg {
  display: block;
  width: 2rem;
  height: 2rem; }

.controls__3BU3E .rotateLeft__1KOxL:hover {
  opacity: 0.8 !important;
  transform: scale(1.1) !important; }

.controls__3BU3E .rotateLeft__1KOxL:active {
  opacity: 1 !important;
  transform: scale(1) !important; }

.controls__3BU3E .rotateLeft__1KOxL:first-of-type {
  margin-left: 0.4rem; }

.controls__3BU3E .rotateLeft__1KOxL:last-of-type {
  margin-right: 0.4rem; }

.controls__3BU3E .rotateLeft__1KOxL svg {
  width: 1.75rem;
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1); }

.controls__3BU3E .rotateLeft__1KOxL:hover svg {
  transform: rotate(-30deg); }

.controls__3BU3E .rotateRight__3kYbB {
  z-index: 1000;
  box-sizing: border-box;
  cursor: pointer;
  margin: 0.4em 0;
  width: 2rem;
  height: 2rem;
  transition: transform 175ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1); }

.controls__3BU3E .rotateRight__3kYbB > svg {
  display: block;
  width: 2rem;
  height: 2rem; }

.controls__3BU3E .rotateRight__3kYbB:hover {
  opacity: 0.8 !important;
  transform: scale(1.1) !important; }

.controls__3BU3E .rotateRight__3kYbB:active {
  opacity: 1 !important;
  transform: scale(1) !important; }

.controls__3BU3E .rotateRight__3kYbB:first-of-type {
  margin-left: 0.4rem; }

.controls__3BU3E .rotateRight__3kYbB:last-of-type {
  margin-right: 0.4rem; }

.controls__3BU3E .rotateRight__3kYbB svg {
  width: 1.75rem;
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1); }

.controls__3BU3E .rotateRight__3kYbB:hover svg {
  transform: rotate(30deg); }

.controls__3BU3E .download__1o8td {
  z-index: 1000;
  box-sizing: border-box;
  cursor: pointer;
  margin: 0.4em 0;
  width: 2rem;
  height: 2rem;
  transition: transform 175ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1); }

.controls__3BU3E .download__1o8td > svg {
  display: block;
  width: 2rem;
  height: 2rem; }

.controls__3BU3E .download__1o8td:hover {
  opacity: 0.8 !important;
  transform: scale(1.1) !important; }

.controls__3BU3E .download__1o8td:active {
  opacity: 1 !important;
  transform: scale(1) !important; }

.controls__3BU3E .download__1o8td:first-of-type {
  margin-left: 0.4rem; }

.controls__3BU3E .download__1o8td:last-of-type {
  margin-right: 0.4rem; }

.controls__3BU3E .download__1o8td svg {
  margin-top: -0.06rem;
  width: 1.75rem; }

.controls__3BU3E .zoom__15F8y {
  z-index: 1000;
  box-sizing: border-box;
  cursor: pointer;
  margin: 0.4em 0;
  width: 2rem;
  height: 2rem;
  transition: transform 175ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1); }

.controls__3BU3E .zoom__15F8y > svg {
  display: block;
  width: 2rem;
  height: 2rem; }

.controls__3BU3E .zoom__15F8y:hover {
  opacity: 0.8 !important;
  transform: scale(1.1) !important; }

.controls__3BU3E .zoom__15F8y:active {
  opacity: 1 !important;
  transform: scale(1) !important; }

.controls__3BU3E .zoom__15F8y:first-of-type {
  margin-left: 0.4rem; }

.controls__3BU3E .zoom__15F8y:last-of-type {
  margin-right: 0.4rem; }

.controls__3BU3E .close__wP1v9 {
  z-index: 1000;
  box-sizing: border-box;
  cursor: pointer;
  margin: 0.4em 0;
  width: 2rem;
  height: 2rem;
  transition: transform 175ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1); }

.controls__3BU3E .close__wP1v9 > svg {
  display: block;
  width: 2rem;
  height: 2rem; }

.controls__3BU3E .close__wP1v9:hover {
  opacity: 0.8 !important;
  transform: scale(1.1) !important; }

.controls__3BU3E .close__wP1v9:active {
  opacity: 1 !important;
  transform: scale(1) !important; }

.controls__3BU3E .close__wP1v9:first-of-type {
  margin-left: 0.4rem; }

.controls__3BU3E .close__wP1v9:last-of-type {
  margin-right: 0.4rem; }

.sideButton__3xQsl {
  z-index: 1000;
  box-sizing: border-box;
  cursor: pointer;
  opacity: 0;
  position: absolute;
  top: 50%;
  padding: 0.4rem;
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1); }

.sideButton__3xQsl > svg {
  display: block;
  width: 2rem;
  height: 2rem; }

.sideButton__3xQsl:hover {
  opacity: 0.8 !important;
  transform: translateX(0) translateY(-50%) !important; }

.sideButton__3xQsl:active {
  opacity: 1 !important; }

.sideButton__3xQsl.show__1tLY6 {
  opacity: 0.8; }

.flipLeft__3VB5y {
  z-index: 1000;
  box-sizing: border-box;
  cursor: pointer;
  opacity: 0;
  position: absolute;
  top: 50%;
  padding: 0.4rem;
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1);
  left: 0;
  padding-left: 0.6rem;
  border-radius: 0 0.5em 0.5em 0;
  transform: translateX(-100%) translateY(-50%); }

.flipLeft__3VB5y > svg {
  display: block;
  width: 2rem;
  height: 2rem; }

.flipLeft__3VB5y:hover {
  opacity: 0.8 !important;
  transform: translateX(0) translateY(-50%) !important; }

.flipLeft__3VB5y:active {
  opacity: 1 !important; }

.flipLeft__3VB5y.show__1tLY6 {
  opacity: 0.8; }

.flipLeft__3VB5y:active {
  transform: translate(-0.2em) translateY(-50%) !important; }

.flipLeft__3VB5y.show__1tLY6 {
  opacity: 0.8;
  transform: translate(-0.2em) translateY(-50%); }

.flipRight__1EqiX {
  z-index: 1000;
  box-sizing: border-box;
  cursor: pointer;
  opacity: 0;
  position: absolute;
  top: 50%;
  padding: 0.4rem;
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1);
  right: 0;
  padding-right: 0.6rem;
  border-radius: 0.5rem 0 0 0.5rem;
  transform: translateX(100%) translateY(-50%); }

.flipRight__1EqiX > svg {
  display: block;
  width: 2rem;
  height: 2rem; }

.flipRight__1EqiX:hover {
  opacity: 0.8 !important;
  transform: translateX(0) translateY(-50%) !important; }

.flipRight__1EqiX:active {
  opacity: 1 !important; }

.flipRight__1EqiX.show__1tLY6 {
  opacity: 0.8; }

.flipRight__1EqiX:active {
  transform: translate(0.2em) translateY(-50%) !important; }

.flipRight__1EqiX.show__1tLY6 {
  opacity: 0.8;
  transform: translate(0.2em) translateY(-50%); }

.pages__IwQb3 {
  box-sizing: border-box;
  display: flex;
  position: absolute;
  left: 50%;
  bottom: 0.6rem;
  z-index: 110;
  opacity: 0;
  border-radius: 2rem;
  transform: translate(-50%, 100px);
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1); }

.pages__IwQb3.show__1tLY6 {
  opacity: 0.8;
  transform: translate(-50%, 0); }

.pages__IwQb3.mobile__1L-Sn {
  bottom: 2rem; }

.pages__IwQb3 .dot__3z2uK {
  cursor: pointer;
  margin: 0.45rem 0.25rem;
  display: block;
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 1.2rem;
  background: black;
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1), width 350ms cubic-bezier(0.6, 0, 0.1, 1); }

.pages__IwQb3 .dot__3z2uK:first-of-type {
  margin-left: 0.6rem; }

.pages__IwQb3 .dot__3z2uK:last-of-type {
  margin-right: 0.6rem; }

.pages__IwQb3 .blackDot__2Zvud {
  cursor: pointer;
  margin: 0.45rem 0.25rem;
  display: block;
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 1.2rem;
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1), width 350ms cubic-bezier(0.6, 0, 0.1, 1);
  cursor: initial;
  width: 1rem;
  background: black; }

.pages__IwQb3 .blackDot__2Zvud:first-of-type {
  margin-left: 0.6rem; }

.pages__IwQb3 .blackDot__2Zvud:last-of-type {
  margin-right: 0.6rem; }

.pages__IwQb3 .whiteDot__31yN0 {
  cursor: pointer;
  margin: 0.45rem 0.25rem;
  display: block;
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 1.2rem;
  background: black;
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1), width 350ms cubic-bezier(0.6, 0, 0.1, 1);
  background: #999; }

.pages__IwQb3 .whiteDot__31yN0:first-of-type {
  margin-left: 0.6rem; }

.pages__IwQb3 .whiteDot__31yN0:last-of-type {
  margin-right: 0.6rem; }

.pages__IwQb3 .whiteDot__31yN0:hover {
  opacity: 0.8 !important;
  transform: scale(1.1) !important; }

.pages__IwQb3 .whiteDot__31yN0:active {
  opacity: 1 !important;
  transform: scale(1) !important; }

/* 与 anim.ts 同步 */
.imageLayer__207N- {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1), -webkit-clip-path 350ms cubic-bezier(0.6, 0, 0.1, 1);
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1), clip-path 350ms cubic-bezier(0.6, 0, 0.1, 1);
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1), clip-path 350ms cubic-bezier(0.6, 0, 0.1, 1), -webkit-clip-path 350ms cubic-bezier(0.6, 0, 0.1, 1);
  position: absolute;
  left: 50%;
  top: 50%;
  will-change: transform, top, opacity, clip-path; }

.imageLayer__207N-.zooming__2StSE {
  transition-timing-function: cubic-bezier(0, 0.1, 0.1, 1);
  -ms-transition-duration: 0ms; }

.imageLayer__207N-.invalidate__lVCQH {
  opacity: 0 !important;
  pointer-events: none; }

/* 与 anim.ts 同步 */
.loadingContainer__13wfa {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: absolute;
  left: 50%;
  top: 50%;
  opacity: 0;
  transition: opacity cubic-bezier(0.6, 0, 0.1, 1) 175ms; }

.loadingContainer__13wfa.show__29ULf {
  opacity: 1; }

.loadingContainer__13wfa .reload__2xUtP {
  border: 2px solid;
  border-radius: 5px;
  font-size: 1rem;
  padding: 0.5rem;
  cursor: pointer;
  outline: none;
  transform: translate(-50%, -50%); }

.loadingContainer__13wfa .reload__2xUtP:hover {
  opacity: 0.8; }

.loadingContainer__13wfa .reload__2xUtP:hover svg {
  transform: rotate(30deg); }

.loadingContainer__13wfa .reload__2xUtP:active {
  opacity: 1; }

.loadingContainer__13wfa .reload__2xUtP svg {
  display: block;
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1); }

.loadingContainer__13wfa .loading__2jKYg {
  width: 24px;
  height: 24px;
  transform: translate(-50%, -50%);
  -webkit-animation: spin__1Iuta 1s linear infinite;
  animation: spin__1Iuta 1s linear infinite; }

@-webkit-keyframes fadeIn__3yb2M {
  0% {
    opacity: 0; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn__3yb2M {
  0% {
    opacity: 0; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes fadeOut__1lwK5 {
  0% {
    opacity: 1; }
  50% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@keyframes fadeOut__1lwK5 {
  0% {
    opacity: 1; }
  50% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@-webkit-keyframes spin__1Iuta {
  0% {
    transform: translate(-50%, -50%) rotate(0deg); }
  100% {
    transform: translate(-50%, -50%) rotate(360deg); } }

@keyframes spin__1Iuta {
  0% {
    transform: translate(-50%, -50%) rotate(0deg); }
  100% {
    transform: translate(-50%, -50%) rotate(360deg); } }

/* 与 anim.ts 同步 */
.backgroundLayer__L59ju {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: zoom-out;
  background-color: #ffffff;
  transition: opacity 0.2s;
  will-change: opacity;
  -webkit-tap-highlight-color: transparent; }

.24
MEXModal .ant-btn:hover,
.24
MEXModal .ant-btn:focus {
  border-color: #e3b914; }

.24
MEXModal .ant-form-item {
  margin-bottom: 10px; }

.24
MEXModal .ant-input-number:hover {
  border-color: #e3b914; }

.24
MEXModal .ant-input-number-focused {
  border-color: #e3b914;
  box-shadow: 0 0 0 2px rgba(227, 185, 20, 0.2); }

.24
MEXModal .ant-modal-header {
  text-align: center;
  border: none; }

.24
MEXModal .switch-type {
  display: flex;
  justify-content: space-around;
  padding: 10px; }
  .24
MEXModal .switch-type div {
    width: 50%;
    text-align: center;
    cursor: pointer; }

.24
MEXModal .label {
  display: block;
  margin-bottom: 0.55556rem; }

.24
MEXModal .row {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.38889rem; }

.24
MEXModal .record-modal {
  max-height: 480px;
  overflow-y: auto; }
  .24
MEXModal .record-modal .record-row {
    border-bottom: 1px solid #4D5F69;
    margin-bottom: 20px; }
    .24
MEXModal .record-modal .record-row span {
      font-size: 14px; }
    .24
MEXModal .record-modal .record-row .stamp {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: center;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 20px; }
    .24
MEXModal .record-modal .record-row .title {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: center;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 10px; }
    .24
MEXModal .record-modal .record-row .value {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: center;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 20px; }

.24
MEXModal.withdraw-record .grid {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 4px rgba(186, 186, 186, 0.5);
  margin-bottom: 15px;
  padding: 10px 20px; }
  .24
MEXModal.withdraw-record .grid:last-child {
    margin-bottom: 0; }
  .24
MEXModal.withdraw-record .grid > .grid-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
    justify-content: space-between;
    line-height: 25px; }
    .24
MEXModal.withdraw-record .grid > .grid-row > span {
      font-size: 14px; }
      .24
MEXModal.withdraw-record .grid > .grid-row > span:first-child {
        color: #737A84; }
      .24
MEXModal.withdraw-record .grid > .grid-row > span:last-child {
        color: #333333;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 330px;
        overflow: hidden; }
  .24
MEXModal.withdraw-record .grid > .grid-bar {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #D9DCE5;
    margin-top: 10px;
    height: 54px;
    line-height: 54px; }
    .24
MEXModal.withdraw-record .grid > .grid-bar .scoreboard {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: center;
      align-items: center; }
      .24
MEXModal.withdraw-record .grid > .grid-bar .scoreboard span {
        font-size: 16px;
        text-align: center;
        height: 30px;
        line-height: 30px; }
      .24
MEXModal.withdraw-record .grid > .grid-bar .scoreboard.enable .board {
        background: #CC4941; }
      .24
MEXModal.withdraw-record .grid > .grid-bar .scoreboard .board {
        width: 30px;
        border-radius: 2px;
        background: #BCC4C7;
        color: #FFFFFF; }
      .24
MEXModal.withdraw-record .grid > .grid-bar .scoreboard .colon {
        width: 20px;
        color: #CC4941; }
    .24
MEXModal.withdraw-record .grid > .grid-bar > .btn {
      -webkit-touch-callout: none;
      /* iOS Safari */
      -webkit-user-select: none;
      /* Safari */
      -khtml-user-select: none;
      /* Konqueror HTML */
      -moz-user-select: none;
      /* Firefox */
      -ms-user-select: none;
      /* Internet Explorer/Edge */
      user-select: none;
      cursor: default;
      border: none;
      border-radius: 0.13889rem;
      cursor: pointer;
      font-size: 16px;
      font-weight: 500;
      color: #232E34;
      background: #E2BE30;
      border-radius: 2px;
      padding: 0 24px;
      height: 34px;
      line-height: 34px; }
      .24
MEXModal.withdraw-record .grid > .grid-bar > .btn:focus {
        outline: 0; }
    .24
MEXModal.withdraw-record .grid > .grid-bar > .status {
      -webkit-touch-callout: none;
      /* iOS Safari */
      -webkit-user-select: none;
      /* Safari */
      -khtml-user-select: none;
      /* Konqueror HTML */
      -moz-user-select: none;
      /* Firefox */
      -ms-user-select: none;
      /* Internet Explorer/Edge */
      user-select: none;
      cursor: default;
      font-size: 16px;
      font-weight: 500; }

.night-BitopModal .ant-modal-header {
  background: #1E2026; }
  .night-BitopModal .ant-modal-header .ant-modal-title {
    color: #ffffff; }

.night-BitopModal .ant-modal-body {
  background: #181A1F;
  color: #FFFFFF; }

.night-BitopModal .ant-modal-footer {
  background: #181A1F; }

.night-BitopModal .ant-btn {
  background: #1E2026 !important;
  color: #FFFFFF !important; }

.night-BitopModal .ant-btn-primary {
  background: #FFD140 !important;
  color: #333333 !important; }

.night-BitopModal .ant-modal-header {
  background-color: #FFF; }
  .night-BitopModal .ant-modal-header .ant-modal-title {
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    color: #101010; }

.night-BitopModal .ant-modal-body {
  background-color: #FFF;
  padding: 20px; }
  .night-BitopModal .ant-modal-body .settingContent {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center; }
    .night-BitopModal .ant-modal-body .settingContent .currencyInfo {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: center;
      align-items: center;
      justify-content: space-between;
      height: 93px; }
      .night-BitopModal .ant-modal-body .settingContent .currencyInfo .infoTitle:last-child span {
        justify-content: flex-end; }
      .night-BitopModal .ant-modal-body .settingContent .currencyInfo .infoTitle .icon svg, .night-BitopModal .ant-modal-body .settingContent .currencyInfo .infoTitle .icon img {
        width: 25px;
        height: 25px;
        margin-right: 10px; }
      .night-BitopModal .ant-modal-body .settingContent .currencyInfo .infoTitle span {
        display: flex;
        align-items: center;
        font-size: 16px;
        line-height: 16px;
        font-weight: 500;
        color: #101010; }
      .night-BitopModal .ant-modal-body .settingContent .currencyInfo .infoTitle p {
        font-size: 14px;
        line-height: 14px;
        font-weight: 400;
        margin-top: 20px;
        color: #858E9D; }
  .night-BitopModal .ant-modal-body .switch-type {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    border-bottom: 1px solid #ECEFF1;
    border-top: 1px solid #ECEFF1; }
    .night-BitopModal .ant-modal-body .switch-type .tabs {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: center;
      align-items: center;
      color: #858E9D;
      flex: 1;
      font-size: 18px;
      line-height: 18px;
      font-weight: 600;
      cursor: pointer; }
    .night-BitopModal .ant-modal-body .switch-type .tabs-active {
      color: #e2be30;
      position: relative; }
  .night-BitopModal .ant-modal-body .inputContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: flex-start;
    height: 126px;
    margin-top: 20px; }
    .night-BitopModal .ant-modal-body .inputContainer .headerTitle {
      font-size: 14px;
      line-height: 14px;
      font-weight: 400;
      color: #858E9D;
      margin-bottom: 15px !important;
      user-select: none; }
    .night-BitopModal .ant-modal-body .inputContainer .inputCon {
      height: 49px;
      background-color: #F4F5F7; }
    .night-BitopModal .ant-modal-body .inputContainer .footTips {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: center;
      align-items: center;
      justify-content: space-between;
      margin-top: 15px;
      user-select: none;
      color: #858E9D; }
      .night-BitopModal .ant-modal-body .inputContainer .footTips span {
        display: flex; }
  .night-BitopModal .ant-modal-body .depositModal {
    margin-top: 0; }
  .night-BitopModal .ant-modal-body .depositInfo {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: space-between;
    justify-content: space-around;
    height: 135px;
    border-bottom: 1px solid #ECEFF1;
    border-top: 1px solid #ECEFF1;
    user-select: none; }
    .night-BitopModal .ant-modal-body .depositInfo .row {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: center;
      align-items: center;
      justify-content: space-between;
      color: #858E9D; }
      .night-BitopModal .ant-modal-body .depositInfo .row span {
        flex: 1;
        text-align: center; }
        .night-BitopModal .ant-modal-body .depositInfo .row span:last-child {
          text-align: end; }
      .night-BitopModal .ant-modal-body .depositInfo .row .label {
        display: flex; }
        .night-BitopModal .ant-modal-body .depositInfo .row .label p:first-child {
          text-align: justify;
          text-align-last: justify;
          width: 50px; }
        .night-BitopModal .ant-modal-body .depositInfo .row .label p:last-child {
          margin-left: 10px; }
  .night-BitopModal .ant-modal-body .depositTotal {
    user-select: none;
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: space-between;
    font-size: 14px;
    line-height: 14px;
    font-weight: 400;
    color: #101010; }
    .night-BitopModal .ant-modal-body .depositTotal .totalRow {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: center;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px; }

.night-BitopModal .ant-modal-footer {
  text-align: center;
  background-color: #FFF; }
  .night-BitopModal .ant-modal-footer .ant-btn {
    width: 230px;
    height: 50px;
    border-radius: 4px;
    border: 1px solid #CED4DA;
    background-color: #FFF !important;
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    color: #101010 !important; }
  .night-BitopModal .ant-modal-footer .ant-btn:last-child {
    background: #F3AD00 !important; }

.night-BitopModal .record-modal .record-row .stamp span {
  color: #999999; }
  .night-BitopModal .record-modal .record-row .stamp span:first-child {
    color: #E3B914; }

.night-BitopModal .record-modal .record-row .title span {
  color: #101010;
  margin: 0 10px;
  width: 70px; }

.night-BitopModal .record-modal .record-row .value {
  display: flex; }
  .night-BitopModal .record-modal .record-row .value span {
    color: #999999;
    margin: 0 10px;
    width: 70px; }

.light-BitopModal .ant-modal-header {
  background: linear-gradient(to right, #FFCD6D, #FFB31F); }
  .light-BitopModal .ant-modal-header .ant-modal-title {
    color: #333333; }

.light-BitopModal .ant-modal-body {
  background: #ffffff;
  color: #333333; }

.light-BitopModal .ant-modal-footer {
  background: #ffffff; }

.light-BitopModal .ant-btn {
  background: #EAEAEA !important;
  color: #6E6F72 !important; }

.light-BitopModal .ant-btn-primary {
  background: linear-gradient(to right, #FFCD6D, #FFB31F) !important;
  color: #333333 !important; }

.light-BitopModal .ant-modal-header {
  background: #e3b914; }
  .light-BitopModal .ant-modal-header .ant-modal-title {
    color: #333333;
    font-size: 18px; }

.light-BitopModal .switch-type {
  background-color: #fafafa; }
  .light-BitopModal .switch-type .tabs {
    color: #999; }
  .light-BitopModal .switch-type .tabs-active {
    color: #e2be30;
    position: relative; }
  .light-BitopModal .switch-type .tabs-active::after {
    position: absolute;
    content: " ";
    width: 30px;
    height: 2px;
    background-color: #e2be30;
    bottom: -5px;
    left: 44%; }

.light-BitopModal .record-modal .record-row .stamp span {
  color: #333333; }
  .light-BitopModal .record-modal .record-row .stamp span:first-child {
    color: #E3B914; }

.light-BitopModal .record-modal .record-row .title span {
  color: #333333; }

.light-BitopModal .record-modal .record-row .value span {
  color: #333333; }

.setting-popover .ant-popover-inner {
  background-color: #2c3940; }

.setting-popover .ant-popover-inner-content {
  color: #fff; }

.container {
  height: 70px;
  background: linear-gradient(to right, rgba(18, 20, 28, 0.8) 0%, rgba(18, 20, 28, 0.8) 50%, rgba(transparent, 0.1) 100%);
  backdrop-filter: blur(5px);
  color: #EEEEEE;
  width: 100%;
  font-family: IBM_Plex_Sans;
  z-index: 999;
  position: absolute;

  &.c2c {
    position: relative;
    background: #141416;
  }

  .desktop, .mobile {
    display: flex;
    justify-content: space-between;
    height: 100%;
    font-weight: 600;
    .nav {
      display: flex;
      align-items: center;
      figure {
        display: block !important;
        cursor: pointer;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 40px;
        margin-inline-end: 40px;
        unicode-bidi: isolate;
      }

      .navLinks {
        display: flex;
        margin-left: 20px;
        > div {
          &:hover {
            color: #48CDEE;
          }
        }

        .btnCon {
          font-size: 14px;
          text-wrap: nowrap;
          cursor: pointer;
          width: auto;
          margin-right: 40px;
        }

        .todayCP {
          color: #717278;
          font-size: 8px;
          line-height: 10.91px;
          width: max-content;
          p {
            font-size: 8px;
            line-height: 10.91px;
            color: #49CDEE;
            margin: 0px;
            padding: 0px;
            span {
              font-size: 18px;
              line-height: 21.6px;
            }
          }
        }

        .tips {
          text-wrap: nowrap;
          white-space: nowrap;
          cursor: pointer;
          width: min-content;
          height: min-content;
          font-size: 8px;
          line-height: 9.38px;
          background: #F4CA70;
          color: #0A0C19;
          padding: 2px 5px;
          border-radius: 4px;
          position: absolute;
          top: 15px;
          right: 0px;
        }
        .navDropDown {
          font-family: IBM_Plex_Sans;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          color: #EEEEEE;
          &:hover {
            color: #48CDEE;
          }
        }
        .dropDownSelector {
          background-color: #23262f;
          width: fit-content;
          min-width: 235px;
          > button {
            display: block;
            width: 100%;
            p {
              text-align: left;
              padding: 0;
              margin-top: 18px;
              margin-bottom: 18px !important;
            }
            p:nth-child(1) {
              color: var(--l-white);
            }
            p:nth-child(2) {
              color: #707070;
              font-size: 12px;
              margin-top: -2px;
            }
          }
        }
      }
    }

    .functionality {
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      // margin-right: 52px;
      margin-right: 40px;
      figure {
        margin: 0 30px 0 0;
        cursor: pointer;
      }
  
      .loading {
        width: 2rem;
        height: 2rem;
      }
  
      .user {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 8px;
        > div:nth-child(1),
        > div:nth-child(2) {
          &:hover {
            color: #48CDEE;
          }
        }
        .assetSelector {
          > button {
            text-wrap: nowrap;
            width: 100%;
            p {
              padding: 0;
              margin-top: 18px;
              margin-bottom: 18px !important;
            }
            p:nth-child(1) {
              color: var(--l-white);
            }
            p:nth-child(2) {
              color: #707070;
              font-size: 12px;
              margin-top: -2px;
            }
          }
        }
        .historySelector {
          > button {
            text-wrap: nowrap;
            width: 100%;
            p {
              padding: 0;
              margin-top: 18px;
              margin-bottom: 18px !important;
            }
            p:nth-child(1) {
              color: var(--l-white);
            }
            p:nth-child(2) {
              color: #707070;
              font-size: 12px;
              margin-top: -2px;
            }
          }
        }
        .profileSelector {
          margin-top: -9px;
        }
        .profileIconCon {
          fill: white;
          margin-right: 20px
        }
        button:nth-child(1) {
          margin-right: 20px;
        }
        > button {
          padding: 0.5rem 1rem;
          // font-size: 0.875rem;
          // margin-right: 40px;
          margin-right: 18px;
        }
        .login {
          font-family: IBM_Plex_Sans;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          // width: 150px;
          height: 40px;
          min-height: 40px;
          color: #48CDEE;
          border-radius: 23px;
        }
        .register {
          font-size: 14px;
          line-height: 18px;
          width: 104px;
          height: 34px;
          min-height: 34px;
          background-color: #48CDEE;
          border-radius: 20px;
          color: #000000;
          white-space: nowrap;
          font-weight: 500;
        }
      }
  
      .downLoadInfo {
        display: flex;
        align-items: center;
        width: 410px;
        height: 168px;
        // background-color: #23262f;
        background: #191B20;
        margin-top: 12px;
        padding: 16px 18px;

        .icons {
          display: flex;
          height: 192px;
          flex-direction: column;
          align-items: flex-start;
          > a {
            flex: 1;
            display: flex;
            margin: 0;
            align-items: center;
            > svg {
              margin-right: 8px;
            }
          }
        }

        .downLoadDropDown {
          display: flex;
          .qrcode {
            display: flex;
            align-items: center;
            > figure {
              margin: 0;
              position: relative;            
              width: 125px;
              height: 125px;
              border-radius: 10px;
              border: 8px solid #FFFFFF;
              cursor: default;
            }
          }
          .downLoadRight {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-left: 15px;
            .firstRow {
              display: flex;
              color: #7A7C85;
              font-size: 12px;
              font-weight: 400;
              span {
                display: flex;
                align-items: flex-end;
                margin-left: 3px;
              }
            }
            .secondRow {
              color: #FFFFFF;
              font-size: 14px;
              font-weight: 300;
              text-align: left;
            }
            .thirdRow {
              background: #2D3739;
              color: #49CDEE;
              font-size: 14px;
              font-weight: 500;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 10px;
              height: 32px;
              cursor: pointer;
            }
          }
        }
      }
      
      .profile {
        .account {
          display: flex;
          justify-content: space-between;
          .kycTag {
            margin-top: auto;
            white-space: nowrap;
            padding: 8px;
            border-radius: 8px 0 0 8px;
            font-size: 8px;
          }
          .unAuth {
            color: #777e90;
            background-color: rgba(#777e90, 0.1);
          }
          .basicAuth {
            color: #01A4FF;
            background-color: rgba(#01A4FF, 0.1);
          }
          .advanceAuth {
            color: #ffd140;
            background-color: rgba(#ffd140, 0.1);
          }
  
          .id {
            padding: 0 16px;
            white-space: nowrap;
            font-size: 8px;
            color: #777e90;
          }
        }
        .profileNav {
          > button {
            justify-content: flex-start;
            width: 100%;
            p {
              padding: 0;
              margin-top: 18px;
              margin-bottom: 18px !important;
            }
            p:nth-child(1) {
              color: var(--l-white);
            }
            p:nth-child(2) {
              color: #707070;
              font-size: 12px;
              margin-top: -2px;
            }
          }
        }
      }

      .navDropDown {
        text-wrap: nowrap;
        // .triangle {
        //   margin-left: 0.5rem;
        //   width: 0;
        //   height: 0;
        //   border-style: solid;
        //   transition: border 300ms ease;
        //   border-width: 0.375rem 0.375rem 0 0.375rem;
        //   border-color: #777e90 transparent transparent transparent;
        // }
  
        // &:hover {
        //   .triangle {
        //     border-width: 0 0.375rem 0.375rem 0.375rem;
        //     border-color: transparent transparent #777e90 transparent;
        //   }
        // }
  
        > button {
          box-shadow: none;
          padding: 0.75rem;
          // font-size: 0.875rem;
        }
      }
  
      .languageSelector {
        background-color: #23262f;
        min-width: 8rem;
        margin-right: 30px;
        margin-top: 13px;
        > button {
          text-wrap: nowrap;
          padding: 0;
          display: block;
          width: 100%;
          p {
            padding: 0;
            margin-top: 18px;
            margin-bottom: 18px !important;
          }
          p:nth-child(1) {
            color: var(--l-white);
          }
          p:nth-child(2) {
            color: #707070;
            font-size: 12px;
            margin-top: -2px;
          }
        }
      }
    }
  }
  .mobile {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .container {
    .desktop {
      // display: none;
    }

    .mobile {
      display: flex;
      height: 100%;
      padding: 0 19px;
      figure {
        margin: 0;
      }
      .functionality {
        margin-right: 0px;
        .login {
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: IBM_Plex_Sans;
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          width: 106px;
          height: 26px;
          background-color: #48CDEE;
          border-radius: 13px;
          color: #000000;
          margin-right: 20px;
          cursor: pointer;
        }
      }
      .overlay {
        background-color: rgba(19, 17, 17, 0.6);
        position: fixed;
        display: none;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100%;
        overflow: hidden;
        font-size: 15px;
        &.open {
          display: block;
        }

        .hamburgerMenu {
          position: absolute;
          top: 0;
          right: 0;
          height: 100%;
          width: 280px;
          border-radius: 1.5rem 0 0 0;
          background-color: #1A1B1D;
          padding: 1rem 1.5rem;
          animation: menuAnimation 0.2s ease-in-out;
          .cross {
            display: flex;
            justify-content: flex-end;
            padding: 2px 0;
            cursor: pointer;
          }
          .btns {
            > div {
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 2rem;
              margin: 1rem 0;
              height: 42px;
              cursor: pointer;
              &.register {
                color: #000000;
                background-color: #48CDEE;
              }
            }
          }
          .routeCon {
            height: 77%;
            overflow-y: scroll;
            scrollbar-color: transparent transparent;
            &.loggedIn {
              height: 90%;
            }
          }
          ::-webkit-scrollbar {
            width: 0px;
            background: transparent; /* make scrollbar transparent */
          }
          .route {
            cursor: pointer;
            padding: 18.5px 0;
            display: flex;
            align-items: center;
            svg {
              margin-right: 20px;
              width: 16px;
              height: 16px;
            }
            .tips {
              text-wrap: nowrap;
              white-space: nowrap;
              cursor: pointer;
              width: min-content;
              height: min-content;
              font-size: 8px;
              line-height: 9.38px;
              background: #F4CA70;
              color: #0A0C19;
              padding: 2px 5px;
              border-radius: 4px;
              margin-left: 10px;
            }
          }
          .subroute {
            padding: 13px calc(20px + 16px + 10px);
            cursor: pointer;
          }
          details{
            summary{
            }

            summary::-webkit-details-marker,
            summary::marker{
              content: "";
              display: none;
            }

            summary::after{
              content: url("../../../../res/header/mobile/arrow_down.svg");
              margin-left: auto;
              svg {
                color: #7C7E87;
                // width: 10px !important;
              }
            }
          }

          details[open]{
            summary::after{
              content: url("../../../../res/header/mobile/arrow_up.svg");
            }
          }
        }
      }
    }
  }
}

/* galaxy fold sb */
@media screen and (max-width: 280px) {
  .container {
    .mobile {
      .functionality {
        .login {
          display: none;
        }
      }
    }
  }
}

@keyframes menuAnimation {
  0% {
    right: -100%;
  }

  100% {
    right: 0%;
  }
}

@import './../../trade/style/color.scss';
.module-quoteView{
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 70px;
    .qv-list{
        .slick-list{
            margin: 0 70px;
        }
        .slick-dots{
            fill: #353945;
            bottom: -50px;
            .slick-active{
                fill: #E8ECF1;
            }
        }
    }
}

.module-quoteGroupView{
    background: $bg8;
    border-radius: 10px 10px 0px 0px;
    width: 500px;
    
    .qgv-title{
        font-size: 23px;
        font-weight: 500;
        color: #fff;
        border-bottom: 1px solid $lc3;
        height: 60px;
        line-height: 60px;
        text-align: center;
        position: relative;
        &::after{
            content: '';
            height: 3px;
            width: 24px;
            position: absolute;
            bottom: 0;
            background: $active;
            left: 0;
            right: 0;
            margin: 0 auto;
        }
    }
    .qgv-listBox{
        .qgv-listBox-header{
            display: flex;
            padding: 0 34px;
            .qgv-listBox-header-text{
                font-size: 14px;
                color: $fc1;
                font-weight: 500;
                height: 54px;
                line-height: 54px;
            }
        }
        .qgv-listBox-body{
            .qgv-listBox-body-cellBox{
                padding: 0 34px;
                cursor: pointer;
                display: block;
                .qgv-listBox-body-cellBox-cell{
                    display: flex;
                    .qgv-listBox-body-cellBox-cell-row{
                        display: flex;
                        align-items: center;
                        .qgv-listBox-body-cellBox-cell-row-image{
                            width: 24px;
                            height: 24px;
                            margin-right: 14px;
                            svg, img{
                                width: 100%;
                            }
                        }
                        .qgv-listBox-body-cellBox-cell-row-text{
                            font-size: 16px;
                            font-family: DIN-Medium;
                            font-weight: 500;
                            color: #fff;;
                            &.green{
                                color: $raise;
                            }
                            &.red{
                                color: $fall;
                            }
                            &.white{
                              color: #FFF;
                            }
                        }
                    }
                    
                }
                &:hover{
                    background: #242730;
                }
            }
        }
        
        .nameWidth{
            flex: 1;
        }
        .chartWidth{
            width: 25%;
            text-align: center;
            justify-content: flex-end;
        }
        .rateWidth{
            width: 25%;
            text-align: right;
            justify-content: flex-end;
        }
        .moreWidth{
            margin-right: -10px;
        }
        .priceWidth{
            width: 28%;
            text-align: right;
            justify-content: flex-end;
        }
    }
    &.right{
        margin-left: auto;
    }
}

.module-bannerArrow{
    position: absolute;
    top: 40%;
    z-index: 5;
    background: rgba($color: #151518, $alpha: .7);
    border-radius: 24px;
    cursor: pointer;
    &.left{
        left: 15px;
    }
    &.right{
        right: 15px;
        transform: rotate(180deg);
    }
    &:hover{
        background: #fff;
    }
    &.slick-next::before, &.slick-prev::before{
        content: none;
    }
}
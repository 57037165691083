@import './../../style/color.scss';
.stage-setting{
    background: #fafafa;
    .s-title{
        margin-left: -18px;
        margin-right: -20px;
        height: 88px;
        line-height: 88px;
        background: #fff;
        font-size: 32px;
        font-weight: 500;
        color: $fc5;
        padding-left: 20px;
    }
    .s-main{
        background: #fff;
        margin-top: 20px;
        min-height: 888px;
        .s-main-title{
            height: 60px;
            line-height: 60px;
            font-size: 16px;
            color: $fc5;
            font-weight: 500;
            padding-left: 30px;
        }
        .s-main-row{
            height: 80px;
            border-bottom: 1px solid $lc4;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 0 30px;
            .s-main-row-left{
                .s-main-row-left-title{
                    font-size: 14px;
                    color: $fc5;
                    line-height: 1;
                    font-weight: 500;
                }
                .s-main-row-left-desc{
                    font-size: 12px;
                    color: #848E9C;
                    line-height: 1;
                    font-weight: 400;
                    margin-top: 10px;
                }
            }
            .s-main-row-right{
                display: flex;
                .s-main-row-right-image{
                    width: 40px;
                    height: 40px;
                    border-radius: 40px;
                    overflow: hidden;
                    margin-right: 20px;
                    img{
                        width: 100%;
                    }
                }
                .s-main-row-right-uploadBtn{
                    width: 200px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid $lc4;
                    border-radius: 4px;
                    .s-main-row-right-uploadBtn-image{
                        display: flex;
                        align-items: center;
                    }
                    .s-main-row-right-uploadBtn-text{
                        font-size: 14px;
                        color: $fc5;
                        font-weight: 400;
                        margin-left: 10px;
                    }
                }
                .s-main-row-right-dd{
                    width: 200px;
                    height: 40px;
                    background: #f5f5f5;
                    display: flex;
                    cursor: pointer;
                    .s-main-row-right-dd-text{
                        font-size: 14px;
                        color: $fc5;
                        font-weight: 400;
                        display: flex;
                        flex: 1;
                        align-items: center;
                        margin-left: 20px;
                    }
                    .s-main-row-right-dd-image{
                        display: flex;
                        align-items: center;
                        margin-right: 20px;
                    }
                }
            }
        }
    }
}

.s-dd-menu{
    .s-dd-menu-cell{
        .s-dd-menu-cell-text{
            font-size: 14px;
            color: $fc5;
            font-weight: 400;
            height: 40px;
            line-height: 40px;
            font-size: 14px;
            font-weight: 500;
        }
    }
    .s-dd-menu-cell:hover{
        background: #f5f5f5;
        .s-dd-menu-cell-text{
            color: $active;
        }
    }
}
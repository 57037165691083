.default {
  align-items: center;
  appearance: none;
  background-color: transparent;
  border-radius: 8px;
  border-style: none;
  box-shadow: rgba(0, 0, 0, .2) 0 3px 5px -1px,rgba(0, 0, 0, .14) 0 6px 10px 0,rgba(0, 0, 0, .12) 0 1px 18px 0;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  display: inline-flex;
  fill: currentcolor;
  font-size: inherit;
  font-weight: inherit;
  min-height: 3rem;
  justify-content: center;
  letter-spacing: 0.25px;
  line-height: normal;
  max-width: 100%;
  overflow: visible;
  padding: 2px 24px;
  position: relative;
  text-align: center;
  text-transform: none;
  text-wrap: nowrap;
  transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1),opacity 15ms linear 30ms,transform 270ms cubic-bezier(0, 0, .2, 1) 0;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: auto;
  will-change: transform,opacity;
  box-shadow: 0 4px 4px 0 transparent, 0 8px 12px 6px transparent;
  border: 2px solid transparent;
  z-index: 0;
  &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: 8px;
      background-color: transparent;
      transition: background-color 0.3s;
  }

  &:hover {
      &::after {
          background-color: rgba(246, 249, 254, .2);
      }
  }

  &:active {
      box-shadow: 0 4px 4px 0 rgb(60, 64, 67 / 30%), 0 8px 12px 6px rgb(60, 64, 67 / 15%);
      outline: none;
  }

  &:focus {
      outline: none;
      border: 2px solid rgba(255, 209, 64, 0.75);
  }

  &:not(:disabled) {
      box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
  }

  &:not(:disabled):hover {
      box-shadow: rgba(60, 64, 67, .3) 0 2px 3px 0, rgba(60, 64, 67, .15) 0 6px 10px 4px;
  }

  &:not(:disabled):focus {
      box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
  }

  &:not(:disabled):active {
      box-shadow: rgba(60, 64, 67, .3) 0 4px 4px 0, rgba(60, 64, 67, .15) 0 8px 12px 6px;
  }

  &:disabled {
      box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
  }
}

.plane {
  position: relative;
  align-items: center;
  background-color: transparent;
  border: 0;
  border-radius: 8px;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  display: inline-flex;
  font-size: inherit;
  font-weight: inherit;
  justify-content: center;
  line-height: 20px;
  min-height: 3rem;
  min-width: 0;
  overflow: hidden;
  padding: 0 12px;
  text-align: center;
  text-wrap: nowrap;
  touch-action: manipulation;
  transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: transparent;
      transition: background-color 0.3s;
  }

  &:focus,
  &:hover {
      &::after {
          background-color: rgba(246, 249, 254, .1);
      }
  }

  &:active {
      &::after {
          background-color: rgba(255, 209, 64, .25);
      }
  }

  &:disabled {
      cursor: not-allowed;
      background: rgba(0, 0, 0, .08);
      color: rgba(0, 0, 0, .3);
  }
}

.empty {
  position: relative;
  align-items: center;
  background-color: transparent;
  border: 0;
  border-radius: 8px;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  display: inline-flex;
  font-size: inherit;
  font-weight: inherit;
  justify-content: center;
  line-height: 20px;
  min-height: 3rem;
  min-width: 0;
  overflow: hidden;
  // padding: 0 1.5rem;
  text-align: center;
  text-wrap: nowrap;
  touch-action: manipulation;
  transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: transparent;
      transition: background-color 0.3s;
  }

  // &:focus,
  // &:hover {
  //     &::after {
  //         background-color: rgba(246, 249, 254, .1);
  //     }
  // }

  // &:active {
  //     &::after {
  //         background-color: rgba(255, 209, 64, .25);
  //     }
  // }

  &:disabled {
      cursor: not-allowed;
      background: rgba(0, 0, 0, .08);
      color: rgba(0, 0, 0, .3);
  }
}

@import './../../../style/color.scss';
.stage-typeNumberChartView{
    background: #fff;
    width: 100%;
    padding: 30px;
    .tncv-catBox{
        display: flex;
        .tncv-catBox-cat{
            font-size: 14px;
            color: #151519;
            font-weight: 500;
            margin-right: 30px;
            line-height: 1;
            position: relative;
            cursor: pointer;
        }
        .tncv-catBox-cat.active::after{
            content: "";
            position: absolute;
            bottom: -21px;
            left: 0;
            width: 100%;
            height: 3px;
            background: $active;
        }
    }
    .tncv-filterBox{
        margin-top: 48px;
        display: flex;
        .tncv-filterBox-typeBox{
            display: flex;
            .tncv-filterBox-typeBox-type{
                margin-right: 24px;
                font-size: 14px;
                color: $fc7;
                font-weight: 500;
                border-radius: 6px;
                width: 104px;
                height: 34px;
                line-height: 34px;
                text-align: center;
                cursor: pointer;
            }
            .tncv-filterBox-typeBox-type.active{
                background: $bg9;
                color: $fc5;
            }
        }
        .tncv-filterBox-pickerBox{
            margin-right: 24px;
            .tncv-filterBox-pickerBox-picker{
                height: 34px;
                .ant-calendar-picker-input{
                    height: 34px;
                    input{
                        font-size: 14px;
                        font-family: DIN-Medium;
                        font-weight: 500;
                        color: $fc6;
                        width: 71px;
                    }
                }
            }
        }
        .tncv-filterBox-switchBox{
            margin-right: 24px;
            .module-switchView{
                height: 34px;
            }
        }
    }
    .tncv-chartBox{
        margin-top: 30px;
    }
}
.content{
    margin: 0 auto;
    .tabs{
        display: flex;
        border-bottom: 1px solid #EAECEF;
        color: #848E9C;
        margin: 0 calc((100% - 1200px) / 2) 1rem;
        .tab{
          font-size: 1.1rem;
          font-weight: bold;
            padding: 15px 0;
            cursor: pointer;
            &:first-child{
                margin-right: 40px;
            }
            &.active{
                position: relative;
                &::before{
                    position: absolute;
                    bottom: -1px;
                    left: 0;
                    display: block;
                    content: '';
                    width: 100%;
                    height: 4px;
                    background: #FFD140;
                }
               
            }
        }
    }
    .tabContent{
        border: 1px solid #E6E8EC;
        padding: 27px 24px;
        margin: 40px calc((100% - 1200px) / 2) 0;
    }
}
.moduleCInfoView {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #EAECEF;
    padding: 30px 0;
    
    div:last-of-type {
        width: 100%;
    }

    >div {
        width: 16.66%;

        .title {
            color: #707A8A;
        }

        .content {
            span {
                font-size: 20px;
                margin-right: 3px;
                color: #000;
            }
        }
    }
}
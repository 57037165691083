b {
    display: block;
    font-size: 16px;
    font-family: PingFangSC-Medium;
    font-weight: 500;
    text-align: left;
    color: #1e2129;
    line-height: 16px;
    margin-bottom: 30px;
}

.Itemview {
    margin-bottom: 30px;

    .tips{
        color: #CF4855;
    }

    span {
        display: block;
        font-size: 14px;
        font-family: PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        color: #1e2129;
        line-height: 14px;
        margin-bottom: 10px;
    }
    .ant-select{
        height: 48px;
        display: flex;
        align-items: center;
        border: 1px solid #eef3fc;
    }
    .ant-select-selection:focus, .ant-select-selection:active{
        border-color:#eef3fc;
        box-shadow:none;
    }
    .ant-input{
        height: 48px;
        border: 1px solid #e6e8ec;
    }
}

.Itemview:nth-child(1) {
    cursor: pointer;
    div {
        cursor: pointer;
        div:nth-child(2) {
            cursor: pointer;
            width: 555px;
            position: relative;
            margin: -48px 0 0 10px;
            opacity: 0;
            div:nth-child(2) {
                cursor: pointer;
                margin: 0 0 0 -65px;
            }
        }
    }
}

.forgetPwd {
    margin-bottom: 10px;
    text-align: left;
    span {
        color: #FFD140;
        cursor: pointer;
    }
}

.submit {
    background-color: #FFD140;
    height: 48px;
    border-radius: 4px;
    font-size: 16px;
    font-family: PingFangSC-Medium;
    font-weight: 500;
    text-align: center;
    color: #1e2129;
    line-height: 48px;
    cursor: pointer;
    opacity: 1;
}

.no-submit {
    @extend .submit;
    opacity: 0.5;
    cursor: default;
}
.switch {
    margin-top: 20px;
    text-align: center;
    span:nth-child(1) {
        margin-right:3px;
    }
}
@charset "UTF-8";
.stage-mining {
  background: url("./../../res/mining/挖礦_Images01.png") no-repeat;
  background-size: cover;
  width: 100%;
  height: calc(100vh - 64px);
  display: flex;
  align-items: center;
  position: relative; }
  .stage-mining .m-main {
    width: 1200px;
    height: 567px;
    margin: auto;
    display: flex; }
    .stage-mining .m-main .m-main-mineBox {
      flex: 1; }
      .stage-mining .m-main .m-main-mineBox .flashIcon {
        position: absolute;
        width: 64px;
        height: 90px;
        left: 0;
        top: 0;
        z-index: 100;
        animation: anim-mine-show 1s cubic-bezier(0.175, 0.885, 0.32, 1.5) forwards;
        cursor: pointer; }
        .stage-mining .m-main .m-main-mineBox .flashIcon .countTxt {
          font-weight: 400;
          line-height: 22px;
          color: #fff;
          margin-top: 4px;
          text-align: center; }
      .stage-mining .m-main .m-main-mineBox .floating {
        animation: anim-floating 2s linear infinite running; }
      .stage-mining .m-main .m-main-mineBox .shake {
        animation-play-state: paused;
        animation: anim-shake .1s linear infinite; }
      .stage-mining .m-main .m-main-mineBox .flyForce {
        animation: anim-fly-force .3s ease-in forwards; }
      .stage-mining .m-main .m-main-mineBox .flashXRP {
        position: absolute;
        width: 20.8px;
        height: 10px;
        top: calc((500 / 900) * 100%);
        left: calc((300 / 1200) * 100%);
        border-radius: 100px;
        margin: 0 auto;
        -webkit-animation-timing-function: ease-in-out;
        -webkit-animation-name: breatheXRP;
        -webkit-animation-duration: 1000ms;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-direction: alternate; }
      .stage-mining .m-main .m-main-mineBox .miningXRP {
        position: absolute;
        top: calc((290 / 900) * 100%);
        left: calc((200 / 1200) * 100%); }
      .stage-mining .m-main .m-main-mineBox .XRP {
        width: 40.8px; }

@-webkit-keyframes breatheXRP {
  0% {
    display: none;
    opacity: .2;
    border-radius: 100px;
    box-shadow: 0 1px 2px rgba(255, 255, 255, 0.9); }
  100% {
    opacity: 1;
    border-radius: 100px;
    background: rgba(2, 255, 255, 0.1);
    box-shadow: 0 -10px 20px #02ffff, inset -0.25rem 0 0 0.5rem rgba(14, 10, 10, 0.1); } }
      .stage-mining .m-main .m-main-mineBox .flashETH {
        position: absolute;
        top: calc((500 / 1200) * 100%);
        left: calc((200 / 900) * 100%);
        width: 20px;
        height: 15.5px;
        -webkit-animation-timing-function: ease-in-out;
        -webkit-animation-name: breatheETH;
        -webkit-animation-duration: 1200ms;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-direction: alternate; }
      .stage-mining .m-main .m-main-mineBox .ETH {
        width: 35.5px; }
      .stage-mining .m-main .m-main-mineBox .miningETH {
        position: absolute;
        top: calc((450 / 1200) * 100%);
        left: calc((480 / 1200) * 100%);
        opacity: 0.8; }

@-webkit-keyframes breatheETH {
  0% {
    opacity: .2;
    border-radius: 100px;
    box-shadow: 0 1px 2px rgba(255, 255, 255, 0.1); }
  100% {
    opacity: 1;
    border-radius: 100px;
    background: rgba(255, 195, 151, 0.2);
    box-shadow: 0 -10px 20px #fcab71, inset -0.25rem 0 0 0.5rem rgba(14, 10, 10, 0.1); } }
      .stage-mining .m-main .m-main-mineBox .miningBTC {
        position: absolute;
        top: calc((220 / 900) * 100%);
        left: calc((660 / 1200) * 100%); }
      .stage-mining .m-main .m-main-mineBox .BTC {
        width: 42px; }
      .stage-mining .m-main .m-main-mineBox .flashBTC {
        position: absolute;
        top: calc((280 / 900) * 100%);
        left: calc((320 / 1200) * 100%);
        width: 18px;
        height: 18px;
        -webkit-animation-timing-function: ease-in-out;
        -webkit-animation-name: breatheBTC;
        -webkit-animation-duration: 1000ms;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-direction: alternate; }

@-webkit-keyframes breatheBTC {
  0% {
    opacity: .2;
    border-radius: 100px;
    box-shadow: 0 1px 2px rgba(255, 255, 255, 0.1); }
  100% {
    opacity: 1;
    border-radius: 100px;
    box-shadow: 0 -8px 30px #f4df9e; } }
      .stage-mining .m-main .m-main-mineBox .miningE-USDT0 {
        position: absolute;
        top: calc((125 / 900) * 100%);
        left: calc((935 / 1200) * 100%); }
      .stage-mining .m-main .m-main-mineBox .E-USDT0 {
        width: 52px; }
      .stage-mining .m-main .m-main-mineBox .flashE-USDT0 {
        position: absolute;
        top: calc((200 / 900) * 100%);
        left: calc((300 / 1200) * 100%);
        width: 25px;
        height: 25px;
        -webkit-animation-timing-function: ease-in-out;
        -webkit-animation-name: breatheE-USDT0;
        -webkit-animation-duration: 1050ms;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-direction: alternate; }

@-webkit-keyframes breatheE-USDT0 {
  0% {
    opacity: .2;
    border-radius: 100px;
    box-shadow: 0 1px 2px rgba(255, 255, 255, 0.1); }
  100% {
    opacity: 1;
    border-radius: 100px;
    box-shadow: 0 -8px 30px #f4df9e; } }
      .stage-mining .m-main .m-main-mineBox .miningLTC {
        top: calc((555 / 900) * 100%);
        left: calc((60 / 1200) * 100%);
        position: absolute; }
      .stage-mining .m-main .m-main-mineBox .LTC {
        width: 46px; }
      .stage-mining .m-main .m-main-mineBox .flashLTC {
        position: absolute;
        top: calc((300 / 900) * 100%);
        left: calc((300 / 1200) * 100%);
        width: 20px;
        height: 20px;
        border-radius: 100px;
        -webkit-animation-timing-function: ease-in-out;
        -webkit-animation-name: breatheLTC;
        -webkit-animation-duration: 1100ms;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-direction: alternate; }

@-webkit-keyframes breatheLTC {
  0% {
    opacity: .2;
    border-radius: 100px;
    box-shadow: 0 1px 2px rgba(255, 255, 255, 0.1); }
  100% {
    opacity: 1;
    border-radius: 100px;
    box-shadow: 0 -10px 20px #b885fa; } }
      .stage-mining .m-main .m-main-mineBox .flashEOS {
        position: absolute;
        top: calc((320 / 900) * 100%);
        left: calc((340 / 1200) * 100%);
        width: 15px;
        height: 20px;
        -webkit-animation-timing-function: ease-in-out;
        -webkit-animation-name: breatheEOS;
        -webkit-animation-duration: 900ms;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-direction: alternate; }
      .stage-mining .m-main .m-main-mineBox .EOS {
        width: 35px; }
      .stage-mining .m-main .m-main-mineBox .miningEOS {
        position: absolute;
        top: calc((414 / 900) * 100%);
        left: calc((320 / 1200) * 100%); }

@-webkit-keyframes breatheEOS {
  0% {
    opacity: .2;
    border-radius: 100px;
    box-shadow: 0 1px 2px rgba(255, 255, 255, 0.1); }
  100% {
    opacity: 1;
    border-radius: 100px;
    box-shadow: 0 -10px 20px #c0c9f9;
    .miningEOS {
      opacity: 1; } } }
      .stage-mining .m-main .m-main-mineBox .miningBCH {
        position: absolute;
        top: calc((360 / 900) * 100%);
        left: calc((830 / 1200) * 100%); }
      .stage-mining .m-main .m-main-mineBox .BCH {
        width: 45px; }
      .stage-mining .m-main .m-main-mineBox .flashBCH {
        position: absolute;
        width: 20px;
        height: 20px;
        top: calc((300 / 900) * 100%);
        left: calc((350 / 1200) * 100%);
        -webkit-animation-timing-function: ease-in-out;
        -webkit-animation-name: breatheBCH;
        -webkit-animation-duration: 1200ms;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-direction: alternate; }

@-webkit-keyframes breatheBCH {
  0% {
    opacity: .2;
    border-radius: 100px;
    box-shadow: 0 1px 2px rgba(255, 255, 255, 0.1); }
  100% {
    opacity: 1;
    border-radius: 100px;
    box-shadow: 0 -6px 20px #7ac3ff; } }
      .stage-mining .m-main .m-main-mineBox .miningTRX {
        top: calc((680 / 900) * 100%);
        left: calc((100 / 1200) * 100%);
        position: absolute; }
      .stage-mining .m-main .m-main-mineBox .TRX {
        width: 66px; }
      .stage-mining .m-main .m-main-mineBox .flashTRX {
        left: calc((300 / 1200) * 100%);
        top: calc((200 / 900) * 100%);
        position: absolute;
        width: 35px;
        height: 35px;
        -webkit-animation-timing-function: ease-in-out;
        -webkit-animation-name: breatheTRX;
        -webkit-animation-duration: 1300ms;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-direction: alternate; }

@-webkit-keyframes breatheTRX {
  0% {
    opacity: .2;
    border-radius: 100px;
    box-shadow: 0 1px 2px rgba(255, 255, 255, 0.1); }
  100% {
    opacity: 1;
    border-radius: 100px;
    box-shadow: 0 -6px 30px #f59ea7; } }
      .stage-mining .m-main .m-main-mineBox .miningE-USDT2 {
        position: absolute;
        top: calc((665 / 900) * 100%);
        left: calc((440 / 1200) * 100%); }
      .stage-mining .m-main .m-main-mineBox .E-USDT2 {
        width: 54px; }
      .stage-mining .m-main .m-main-mineBox .flashE-USDT2 {
        position: absolute;
        width: 25px;
        height: 25px;
        top: calc((300 / 900) * 100%);
        left: calc((350 / 1200) * 100%);
        -webkit-animation-timing-function: ease-in-out;
        -webkit-animation-name: breatheE-USDT2;
        -webkit-animation-duration: 1000ms;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-direction: alternate; }

@-webkit-keyframes breatheE-USDT2 {
  0% {
    opacity: .2;
    border-radius: 100px;
    box-shadow: 0 1px 2px rgba(255, 255, 255, 0.1); }
  100% {
    opacity: 1;
    border-radius: 100px;
    box-shadow: 0 -4px 20px #f7ce5e; } }
      .stage-mining .m-main .m-main-mineBox .miningE-USDT1 {
        position: absolute;
        top: calc((795 / 900) * 100%);
        left: calc((210 / 1200) * 100%); }
      .stage-mining .m-main .m-main-mineBox .E-USDT1 {
        width: 40px; }
      .stage-mining .m-main .m-main-mineBox .flashE-USDT1 {
        position: absolute;
        width: 25px;
        height: 25px;
        top: calc((180 / 900) * 100%);
        left: calc((250 / 1200) * 100%);
        -webkit-animation-timing-function: ease-in-out;
        -webkit-animation-name: breatheE-USDT1;
        -webkit-animation-duration: 1000ms;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-direction: alternate; }

@-webkit-keyframes breatheE-USDT1 {
  0% {
    opacity: .2;
    border-radius: 100px;
    box-shadow: 0 1px 2px rgba(255, 255, 255, 0.1); }
  100% {
    opacity: 1;
    border-radius: 100px;
    box-shadow: 0 -4px 20px #f7ce5e; } }
      .stage-mining .m-main .m-main-mineBox .miningM-USDT0 {
        position: absolute;
        top: calc((665 / 900) * 100%);
        left: calc((440 / 1200) * 100%); }
      .stage-mining .m-main .m-main-mineBox .M-USDT0 {
        width: 54px; }
      .stage-mining .m-main .m-main-mineBox .flashM-USDT0 {
        position: absolute;
        width: 25px;
        height: 25px;
        top: calc((300 / 900) * 100%);
        left: calc((350 / 1200) * 100%);
        -webkit-animation-timing-function: ease-in-out;
        -webkit-animation-name: breatheM-USDT0;
        -webkit-animation-duration: 1000ms;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-direction: alternate; }

@-webkit-keyframes breatheM-USDT0 {
  0% {
    opacity: .2;
    border-radius: 100px;
    box-shadow: 0 1px 2px rgba(255, 255, 255, 0.1); }
  100% {
    opacity: 1;
    border-radius: 100px;
    box-shadow: 0 -4px 20px #f7ce5e; } }
      .stage-mining .m-main .m-main-mineBox .miningM-USDT1 {
        position: absolute;
        top: calc((590 / 900) * 100%);
        left: calc((730 / 1200) * 100%); }
      .stage-mining .m-main .m-main-mineBox .M-USDT1 {
        width: 57px; }
      .stage-mining .m-main .m-main-mineBox .flashM-USDT1 {
        position: absolute;
        width: 30px;
        height: 20px;
        top: calc((300 / 900) * 100%);
        left: calc((300 / 1200) * 100%);
        -webkit-animation-timing-function: ease-in-out;
        -webkit-animation-name: breatheM-USDT1;
        -webkit-animation-duration: 900ms;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-direction: alternate; }

@-webkit-keyframes breatheM-USDT1 {
  0% {
    opacity: .2;
    border-radius: 100px;
    box-shadow: 0 1px 2px rgba(255, 255, 255, 0.1); }
  100% {
    opacity: 1;
    border-radius: 100px;
    box-shadow: 0 -4px 20px #f7ce5e; } }
      .stage-mining .m-main .m-main-mineBox .miningM-USDT2 {
        position: absolute;
        top: calc((520 / 900) * 100%);
        left: calc((1100 / 1200) * 100%); }
      .stage-mining .m-main .m-main-mineBox .M-USDT2 {
        width: 54px; }
      .stage-mining .m-main .m-main-mineBox .flashM-USDT2 {
        position: absolute;
        width: 30px;
        height: 20px;
        top: calc((320 / 900) * 100%);
        left: calc((280 / 1200) * 100%);
        -webkit-animation-timing-function: ease-in-out;
        -webkit-animation-name: breatheM-USDT2;
        -webkit-animation-duration: 1000ms;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-direction: alternate; }

@-webkit-keyframes breatheM-USDT2 {
  0% {
    opacity: .2;
    border-radius: 100px;
    box-shadow: 0 1px 2px rgba(255, 255, 255, 0.1); }
  100% {
    opacity: 1;
    border-radius: 100px;
    box-shadow: 0 -4px 20px #f7ce5e; } }
      .stage-mining .m-main .m-main-mineBox .miningM-USDT0 {
        position: absolute;
        top: calc((760 / 900) * 100%);
        left: calc((320 / 1200) * 100%); }
      .stage-mining .m-main .m-main-mineBox .M-USDT0 {
        width: 54px; }
      .stage-mining .m-main .m-main-mineBox .flashM-USDT0 {
        position: absolute;
        width: 28px;
        height: 20px;
        top: calc((400 / 900) * 100%);
        left: calc((300 / 1200) * 100%);
        -webkit-animation-timing-function: ease-in-out;
        -webkit-animation-name: breatheM-USDT0;
        -webkit-animation-duration: 1300ms;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-direction: alternate; }

@-webkit-keyframes breatheM-USDT0 {
  0% {
    opacity: .2;
    border-radius: 100px;
    box-shadow: 0 1px 2px rgba(255, 255, 255, 0.1); }
  100% {
    opacity: 1;
    border-radius: 100px;
    box-shadow: 0 -10px 20px #f2d78e; } }
      .stage-mining .m-main .m-main-mineBox .miningE-USDT3 {
        position: absolute;
        top: calc((730 / 900) * 100%);
        left: calc((660 / 1200) * 100%); }
      .stage-mining .m-main .m-main-mineBox .E-USDT3 {
        width: 79px; }
      .stage-mining .m-main .m-main-mineBox .flashE-USDT3 {
        position: absolute;
        width: 20px;
        height: 10px;
        left: calc((150 / 900) * 100%);
        top: calc((800 / 900) * 100%);
        -webkit-animation-timing-function: ease-in-out;
        -webkit-animation-name: breatheUSDTMid;
        -webkit-animation-duration: 1100ms;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-direction: alternate; }
      .stage-mining .m-main .m-main-mineBox .flashUSDTMidMid {
        position: absolute;
        width: 10px;
        height: 10px;
        top: calc((640 / 900) * 100%);
        left: calc((600 / 1200) * 100%);
        -webkit-animation-timing-function: ease-in-out;
        -webkit-animation-name: breatheUSDTMid;
        -webkit-animation-duration: 1000ms;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-direction: alternate; }
      .stage-mining .m-main .m-main-mineBox .flashUSDTMidRight {
        position: absolute;
        width: 25px;
        height: 30px;
        top: calc((200 / 900) * 100%);
        left: calc((600 / 1200) * 100%);
        -webkit-animation-timing-function: ease-in-out;
        -webkit-animation-name: breatheUSDTMidRight;
        -webkit-animation-duration: 1200ms;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-direction: alternate; }

@-webkit-keyframes breatheUSDTMidRight {
  0% {
    opacity: .2;
    border-radius: 100px;
    box-shadow: 0 1px 2px rgba(255, 255, 255, 0.1); }
  100% {
    opacity: 1;
    border-radius: 100px;
    box-shadow: 0 -10px 20px #fae6af; } }

@-webkit-keyframes breatheUSDTMid {
  0% {
    opacity: .2;
    border-radius: 100px;
    box-shadow: 0 1px 2px rgba(255, 255, 255, 0.1); }
  100% {
    opacity: 1;
    border-radius: 100px;
    box-shadow: 0 -8px 10px #f2d78e; } }
      .stage-mining .m-main .m-main-mineBox .miningE-USDT4 {
        position: absolute;
        top: calc((665 / 900) * 100%);
        left: calc((860 / 1200) * 100%); }
      .stage-mining .m-main .m-main-mineBox .E-USDT4 {
        width: 33px; }
      .stage-mining .m-main .m-main-mineBox .flashE-USDT4 {
        position: absolute;
        width: 18px;
        height: 15px;
        top: calc((300 / 900) * 100%);
        left: calc((300 / 1200) * 100%);
        -webkit-animation-timing-function: ease-in-out;
        -webkit-animation-name: breatheE-USDT4;
        -webkit-animation-duration: 900ms;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-direction: alternate; }

@-webkit-keyframes breatheE-USDT4 {
  0% {
    opacity: .2;
    border-radius: 100px;
    box-shadow: 0 1px 2px rgba(255, 255, 255, 0.1); }
  100% {
    opacity: 1;
    border-radius: 100px;
    box-shadow: 0 -4px 20px #f7ce5e; } }
      .stage-mining .m-main .m-main-mineBox .miningE-USDT5 {
        position: absolute;
        top: calc((775 / 900) * 100%);
        left: calc((970 / 1200) * 100%); }
      .stage-mining .m-main .m-main-mineBox .E-USDT5 {
        width: 66px; }
      .stage-mining .m-main .m-main-mineBox .flashE-USDT5 {
        position: absolute;
        width: 35px;
        height: 30px;
        top: calc((250 / 900) * 100%);
        left: calc((250 / 1200) * 100%);
        -webkit-animation-timing-function: ease-in-out;
        -webkit-animation-name: breatheE-USDT5;
        -webkit-animation-duration: 1000ms;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-direction: alternate; }

@-webkit-keyframes breatheE-USDT5 {
  0% {
    opacity: .2;
    border-radius: 100px;
    box-shadow: 0 1px 2px rgba(255, 255, 255, 0.1); }
  100% {
    opacity: 1;
    border-radius: 100px;
    box-shadow: 0 -4px 20px #f7ce5e; } }
      .stage-mining .m-main .m-main-mineBox .miningA-USDT {
        position: absolute;
        top: calc((330 / 900) * 100%);
        left: calc((25 / 1200) * 100%); }
      .stage-mining .m-main .m-main-mineBox .flashA-USDT {
        position: absolute;
        width: 35px;
        height: 30px;
        top: calc((330 / 900) * 100%);
        left: calc((25 / 1200) * 100%);
        -webkit-animation-timing-function: ease-in-out;
        -webkit-animation-name: breatheE-USDT3;
        -webkit-animation-duration: 1000ms;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-direction: alternate; }
    .stage-mining .m-main .m-main-assetBox {
      width: 448px;
      height: 567px;
      background: rgba(232, 236, 241, 0.1);
      border-radius: 6px;
      backdrop-filter: blur(10px);
      padding: 0 26px; }
      .stage-mining .m-main .m-main-assetBox .m-main-assetBox-header {
        border-bottom: 1px solid #494E5B;
        height: 96px;
        display: flex;
        justify-content: space-between; }
        .stage-mining .m-main .m-main-assetBox .m-main-assetBox-header .m-main-assetBox-header-left {
          display: flex; }
          .stage-mining .m-main .m-main-assetBox .m-main-assetBox-header .m-main-assetBox-header-left .m-main-assetBox-header-left-block {
            margin-right: 40px; }
            .stage-mining .m-main .m-main-assetBox .m-main-assetBox-header .m-main-assetBox-header-left .m-main-assetBox-header-left-block .m-main-assetBox-header-left-block-title {
              color: #E6E8EC;
              font-size: 16px;
              font-weight: 500;
              line-height: 1;
              margin-top: 16px;
              display: flex; }
              .stage-mining .m-main .m-main-assetBox .m-main-assetBox-header .m-main-assetBox-header-left .m-main-assetBox-header-left-block .m-main-assetBox-header-left-block-title span {
                width: 16px;
                height: 16px;
                margin-left: 4px; }
                .stage-mining .m-main .m-main-assetBox .m-main-assetBox-header .m-main-assetBox-header-left .m-main-assetBox-header-left-block .m-main-assetBox-header-left-block-title span svg {
                  width: 100%; }
            .stage-mining .m-main .m-main-assetBox .m-main-assetBox-header .m-main-assetBox-header-left .m-main-assetBox-header-left-block .m-main-assetBox-header-left-block-content {
              color: #E6E8EC;
              font-family: DIN-Medium;
              font-size: 32px;
              font-weight: 500;
              line-height: 1;
              margin-top: 16px; }
        .stage-mining .m-main .m-main-assetBox .m-main-assetBox-header .m-main-assetBox-header-right {
          display: flex;
          align-items: center; }
          .stage-mining .m-main .m-main-assetBox .m-main-assetBox-header .m-main-assetBox-header-right .m-main-assetBox-header-right-btn {
            cursor: pointer; }
      .stage-mining .m-main .m-main-assetBox .m-main-assetBox-assetList .m-main-assetBox-assetList-cellBox {
        display: flex; }
        .stage-mining .m-main .m-main-assetBox .m-main-assetBox-assetList .m-main-assetBox-assetList-cellBox .m-main-assetBox-assetList-cellBox-cell {
          height: 49px;
          width: 100%;
          display: flex;
          justify-content: space-between; }
          .stage-mining .m-main .m-main-assetBox .m-main-assetBox-assetList .m-main-assetBox-assetList-cellBox .m-main-assetBox-assetList-cellBox-cell .m-main-assetBox-assetList-cellBox-cell-info {
            display: flex;
            align-items: center; }
            .stage-mining .m-main .m-main-assetBox .m-main-assetBox-assetList .m-main-assetBox-assetList-cellBox .m-main-assetBox-assetList-cellBox-cell .m-main-assetBox-assetList-cellBox-cell-info .m-main-assetBox-assetList-cellBox-cell-info-image {
              width: 18px;
              height: 18px; }
              .stage-mining .m-main .m-main-assetBox .m-main-assetBox-assetList .m-main-assetBox-assetList-cellBox .m-main-assetBox-assetList-cellBox-cell .m-main-assetBox-assetList-cellBox-cell-info .m-main-assetBox-assetList-cellBox-cell-info-image svg {
                width: 100%; }
            .stage-mining .m-main .m-main-assetBox .m-main-assetBox-assetList .m-main-assetBox-assetList-cellBox .m-main-assetBox-assetList-cellBox-cell .m-main-assetBox-assetList-cellBox-cell-info .m-main-assetBox-assetList-cellBox-cell-info-name {
              font-size: 18px;
              font-family: DIN-Medium;
              font-weight: 500;
              color: #E6E8EC;
              margin-left: 16px; }
          .stage-mining .m-main .m-main-assetBox .m-main-assetBox-assetList .m-main-assetBox-assetList-cellBox .m-main-assetBox-assetList-cellBox-cell .m-main-assetBox-assetList-cellBox-cell-value {
            color: #E6E8EC;
            font-family: DIN-Medium;
            font-size: 18px;
            font-weight: 500;
            display: flex;
            align-items: center; }
      .stage-mining .m-main .m-main-assetBox .m-main-assetBox-btnArea {
        display: flex;
        justify-content: space-between; }
        .stage-mining .m-main .m-main-assetBox .m-main-assetBox-btnArea .m-main-assetBox-btnArea-btn {
          cursor: pointer;
          width: 116px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          background: #FFD140;
          border-radius: 4px;
          font-size: 14px;
          font-weight: 600;
          color: #181A20;
          font-family: PingFang SC; }

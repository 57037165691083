@charset "UTF-8";
@import url(~antd/dist/antd.css);
.ant-tooltip-inner {
  background: #fff;
  color: #333; }

.ant-tooltip-arrow::before {
  background: white; }

.ant-input-number:hover {
  border-color: #F3AD00; }

.ant-input-number-focused {
  border-color: transparent; }

.wrapperLayer__25Osd {
  position: fixed;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999; }

/* 与 anim.ts 同步 */
.baseButton__18U5B {
  z-index: 1000;
  box-sizing: border-box;
  cursor: pointer; }

.baseButton__18U5B > svg {
  display: block;
  width: 2rem;
  height: 2rem; }

.controls__3BU3E {
  box-sizing: border-box;
  position: absolute;
  top: 0.6rem;
  right: 0.6rem;
  opacity: 0;
  display: flex;
  z-index: 1000;
  border-radius: 5rem;
  transform: translateX(100%);
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms; }

.controls__3BU3E.show__1tLY6 {
  opacity: 0.8;
  transform: translateX(0); }

.controls__3BU3E .pinButton__MnPeR {
  z-index: 1000;
  box-sizing: border-box;
  cursor: pointer;
  margin: 0.4em 0;
  width: 2rem;
  height: 2rem;
  transition: transform 175ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1); }

.controls__3BU3E .pinButton__MnPeR > svg {
  display: block;
  width: 2rem;
  height: 2rem; }

.controls__3BU3E .pinButton__MnPeR:hover {
  opacity: 0.8 !important;
  transform: scale(1.1) !important; }

.controls__3BU3E .pinButton__MnPeR:active {
  opacity: 1 !important;
  transform: scale(1) !important; }

.controls__3BU3E .pinButton__MnPeR:first-of-type {
  margin-left: 0.4rem; }

.controls__3BU3E .pinButton__MnPeR:last-of-type {
  margin-right: 0.4rem; }

.controls__3BU3E .rotate__1DjN2 {
  z-index: 1000;
  box-sizing: border-box;
  cursor: pointer;
  margin: 0.4em 0;
  width: 2rem;
  height: 2rem;
  transition: transform 175ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1); }

.controls__3BU3E .rotate__1DjN2 > svg {
  display: block;
  width: 2rem;
  height: 2rem; }

.controls__3BU3E .rotate__1DjN2:hover {
  opacity: 0.8 !important;
  transform: scale(1.1) !important; }

.controls__3BU3E .rotate__1DjN2:active {
  opacity: 1 !important;
  transform: scale(1) !important; }

.controls__3BU3E .rotate__1DjN2:first-of-type {
  margin-left: 0.4rem; }

.controls__3BU3E .rotate__1DjN2:last-of-type {
  margin-right: 0.4rem; }

.controls__3BU3E .rotate__1DjN2 svg {
  width: 1.75rem;
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1); }

.controls__3BU3E .rotateLeft__1KOxL {
  z-index: 1000;
  box-sizing: border-box;
  cursor: pointer;
  margin: 0.4em 0;
  width: 2rem;
  height: 2rem;
  transition: transform 175ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1); }

.controls__3BU3E .rotateLeft__1KOxL > svg {
  display: block;
  width: 2rem;
  height: 2rem; }

.controls__3BU3E .rotateLeft__1KOxL:hover {
  opacity: 0.8 !important;
  transform: scale(1.1) !important; }

.controls__3BU3E .rotateLeft__1KOxL:active {
  opacity: 1 !important;
  transform: scale(1) !important; }

.controls__3BU3E .rotateLeft__1KOxL:first-of-type {
  margin-left: 0.4rem; }

.controls__3BU3E .rotateLeft__1KOxL:last-of-type {
  margin-right: 0.4rem; }

.controls__3BU3E .rotateLeft__1KOxL svg {
  width: 1.75rem;
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1); }

.controls__3BU3E .rotateLeft__1KOxL:hover svg {
  transform: rotate(-30deg); }

.controls__3BU3E .rotateRight__3kYbB {
  z-index: 1000;
  box-sizing: border-box;
  cursor: pointer;
  margin: 0.4em 0;
  width: 2rem;
  height: 2rem;
  transition: transform 175ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1); }

.controls__3BU3E .rotateRight__3kYbB > svg {
  display: block;
  width: 2rem;
  height: 2rem; }

.controls__3BU3E .rotateRight__3kYbB:hover {
  opacity: 0.8 !important;
  transform: scale(1.1) !important; }

.controls__3BU3E .rotateRight__3kYbB:active {
  opacity: 1 !important;
  transform: scale(1) !important; }

.controls__3BU3E .rotateRight__3kYbB:first-of-type {
  margin-left: 0.4rem; }

.controls__3BU3E .rotateRight__3kYbB:last-of-type {
  margin-right: 0.4rem; }

.controls__3BU3E .rotateRight__3kYbB svg {
  width: 1.75rem;
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1); }

.controls__3BU3E .rotateRight__3kYbB:hover svg {
  transform: rotate(30deg); }

.controls__3BU3E .download__1o8td {
  z-index: 1000;
  box-sizing: border-box;
  cursor: pointer;
  margin: 0.4em 0;
  width: 2rem;
  height: 2rem;
  transition: transform 175ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1); }

.controls__3BU3E .download__1o8td > svg {
  display: block;
  width: 2rem;
  height: 2rem; }

.controls__3BU3E .download__1o8td:hover {
  opacity: 0.8 !important;
  transform: scale(1.1) !important; }

.controls__3BU3E .download__1o8td:active {
  opacity: 1 !important;
  transform: scale(1) !important; }

.controls__3BU3E .download__1o8td:first-of-type {
  margin-left: 0.4rem; }

.controls__3BU3E .download__1o8td:last-of-type {
  margin-right: 0.4rem; }

.controls__3BU3E .download__1o8td svg {
  margin-top: -0.06rem;
  width: 1.75rem; }

.controls__3BU3E .zoom__15F8y {
  z-index: 1000;
  box-sizing: border-box;
  cursor: pointer;
  margin: 0.4em 0;
  width: 2rem;
  height: 2rem;
  transition: transform 175ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1); }

.controls__3BU3E .zoom__15F8y > svg {
  display: block;
  width: 2rem;
  height: 2rem; }

.controls__3BU3E .zoom__15F8y:hover {
  opacity: 0.8 !important;
  transform: scale(1.1) !important; }

.controls__3BU3E .zoom__15F8y:active {
  opacity: 1 !important;
  transform: scale(1) !important; }

.controls__3BU3E .zoom__15F8y:first-of-type {
  margin-left: 0.4rem; }

.controls__3BU3E .zoom__15F8y:last-of-type {
  margin-right: 0.4rem; }

.controls__3BU3E .close__wP1v9 {
  z-index: 1000;
  box-sizing: border-box;
  cursor: pointer;
  margin: 0.4em 0;
  width: 2rem;
  height: 2rem;
  transition: transform 175ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1); }

.controls__3BU3E .close__wP1v9 > svg {
  display: block;
  width: 2rem;
  height: 2rem; }

.controls__3BU3E .close__wP1v9:hover {
  opacity: 0.8 !important;
  transform: scale(1.1) !important; }

.controls__3BU3E .close__wP1v9:active {
  opacity: 1 !important;
  transform: scale(1) !important; }

.controls__3BU3E .close__wP1v9:first-of-type {
  margin-left: 0.4rem; }

.controls__3BU3E .close__wP1v9:last-of-type {
  margin-right: 0.4rem; }

.sideButton__3xQsl {
  z-index: 1000;
  box-sizing: border-box;
  cursor: pointer;
  opacity: 0;
  position: absolute;
  top: 50%;
  padding: 0.4rem;
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1); }

.sideButton__3xQsl > svg {
  display: block;
  width: 2rem;
  height: 2rem; }

.sideButton__3xQsl:hover {
  opacity: 0.8 !important;
  transform: translateX(0) translateY(-50%) !important; }

.sideButton__3xQsl:active {
  opacity: 1 !important; }

.sideButton__3xQsl.show__1tLY6 {
  opacity: 0.8; }

.flipLeft__3VB5y {
  z-index: 1000;
  box-sizing: border-box;
  cursor: pointer;
  opacity: 0;
  position: absolute;
  top: 50%;
  padding: 0.4rem;
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1);
  left: 0;
  padding-left: 0.6rem;
  border-radius: 0 0.5em 0.5em 0;
  transform: translateX(-100%) translateY(-50%); }

.flipLeft__3VB5y > svg {
  display: block;
  width: 2rem;
  height: 2rem; }

.flipLeft__3VB5y:hover {
  opacity: 0.8 !important;
  transform: translateX(0) translateY(-50%) !important; }

.flipLeft__3VB5y:active {
  opacity: 1 !important; }

.flipLeft__3VB5y.show__1tLY6 {
  opacity: 0.8; }

.flipLeft__3VB5y:active {
  transform: translate(-0.2em) translateY(-50%) !important; }

.flipLeft__3VB5y.show__1tLY6 {
  opacity: 0.8;
  transform: translate(-0.2em) translateY(-50%); }

.flipRight__1EqiX {
  z-index: 1000;
  box-sizing: border-box;
  cursor: pointer;
  opacity: 0;
  position: absolute;
  top: 50%;
  padding: 0.4rem;
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1);
  right: 0;
  padding-right: 0.6rem;
  border-radius: 0.5rem 0 0 0.5rem;
  transform: translateX(100%) translateY(-50%); }

.flipRight__1EqiX > svg {
  display: block;
  width: 2rem;
  height: 2rem; }

.flipRight__1EqiX:hover {
  opacity: 0.8 !important;
  transform: translateX(0) translateY(-50%) !important; }

.flipRight__1EqiX:active {
  opacity: 1 !important; }

.flipRight__1EqiX.show__1tLY6 {
  opacity: 0.8; }

.flipRight__1EqiX:active {
  transform: translate(0.2em) translateY(-50%) !important; }

.flipRight__1EqiX.show__1tLY6 {
  opacity: 0.8;
  transform: translate(0.2em) translateY(-50%); }

.pages__IwQb3 {
  box-sizing: border-box;
  display: flex;
  position: absolute;
  left: 50%;
  bottom: 0.6rem;
  z-index: 110;
  opacity: 0;
  border-radius: 2rem;
  transform: translate(-50%, 100px);
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1); }

.pages__IwQb3.show__1tLY6 {
  opacity: 0.8;
  transform: translate(-50%, 0); }

.pages__IwQb3.mobile__1L-Sn {
  bottom: 2rem; }

.pages__IwQb3 .dot__3z2uK {
  cursor: pointer;
  margin: 0.45rem 0.25rem;
  display: block;
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 1.2rem;
  background: black;
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1), width 350ms cubic-bezier(0.6, 0, 0.1, 1); }

.pages__IwQb3 .dot__3z2uK:first-of-type {
  margin-left: 0.6rem; }

.pages__IwQb3 .dot__3z2uK:last-of-type {
  margin-right: 0.6rem; }

.pages__IwQb3 .blackDot__2Zvud {
  cursor: pointer;
  margin: 0.45rem 0.25rem;
  display: block;
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 1.2rem;
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1), width 350ms cubic-bezier(0.6, 0, 0.1, 1);
  cursor: initial;
  width: 1rem;
  background: black; }

.pages__IwQb3 .blackDot__2Zvud:first-of-type {
  margin-left: 0.6rem; }

.pages__IwQb3 .blackDot__2Zvud:last-of-type {
  margin-right: 0.6rem; }

.pages__IwQb3 .whiteDot__31yN0 {
  cursor: pointer;
  margin: 0.45rem 0.25rem;
  display: block;
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 1.2rem;
  background: black;
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1), width 350ms cubic-bezier(0.6, 0, 0.1, 1);
  background: #999; }

.pages__IwQb3 .whiteDot__31yN0:first-of-type {
  margin-left: 0.6rem; }

.pages__IwQb3 .whiteDot__31yN0:last-of-type {
  margin-right: 0.6rem; }

.pages__IwQb3 .whiteDot__31yN0:hover {
  opacity: 0.8 !important;
  transform: scale(1.1) !important; }

.pages__IwQb3 .whiteDot__31yN0:active {
  opacity: 1 !important;
  transform: scale(1) !important; }

/* 与 anim.ts 同步 */
.imageLayer__207N- {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1), -webkit-clip-path 350ms cubic-bezier(0.6, 0, 0.1, 1);
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1), clip-path 350ms cubic-bezier(0.6, 0, 0.1, 1);
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1), clip-path 350ms cubic-bezier(0.6, 0, 0.1, 1), -webkit-clip-path 350ms cubic-bezier(0.6, 0, 0.1, 1);
  position: absolute;
  left: 50%;
  top: 50%;
  will-change: transform, top, opacity, clip-path; }

.imageLayer__207N-.zooming__2StSE {
  transition-timing-function: cubic-bezier(0, 0.1, 0.1, 1);
  -ms-transition-duration: 0ms; }

.imageLayer__207N-.invalidate__lVCQH {
  opacity: 0 !important;
  pointer-events: none; }

/* 与 anim.ts 同步 */
.loadingContainer__13wfa {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: absolute;
  left: 50%;
  top: 50%;
  opacity: 0;
  transition: opacity cubic-bezier(0.6, 0, 0.1, 1) 175ms; }

.loadingContainer__13wfa.show__29ULf {
  opacity: 1; }

.loadingContainer__13wfa .reload__2xUtP {
  border: 2px solid;
  border-radius: 5px;
  font-size: 1rem;
  padding: 0.5rem;
  cursor: pointer;
  outline: none;
  transform: translate(-50%, -50%); }

.loadingContainer__13wfa .reload__2xUtP:hover {
  opacity: 0.8; }

.loadingContainer__13wfa .reload__2xUtP:hover svg {
  transform: rotate(30deg); }

.loadingContainer__13wfa .reload__2xUtP:active {
  opacity: 1; }

.loadingContainer__13wfa .reload__2xUtP svg {
  display: block;
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1); }

.loadingContainer__13wfa .loading__2jKYg {
  width: 24px;
  height: 24px;
  transform: translate(-50%, -50%);
  -webkit-animation: spin__1Iuta 1s linear infinite;
  animation: spin__1Iuta 1s linear infinite; }

@-webkit-keyframes fadeIn__3yb2M {
  0% {
    opacity: 0; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn__3yb2M {
  0% {
    opacity: 0; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes fadeOut__1lwK5 {
  0% {
    opacity: 1; }
  50% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@keyframes fadeOut__1lwK5 {
  0% {
    opacity: 1; }
  50% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@-webkit-keyframes spin__1Iuta {
  0% {
    transform: translate(-50%, -50%) rotate(0deg); }
  100% {
    transform: translate(-50%, -50%) rotate(360deg); } }

@keyframes spin__1Iuta {
  0% {
    transform: translate(-50%, -50%) rotate(0deg); }
  100% {
    transform: translate(-50%, -50%) rotate(360deg); } }

/* 与 anim.ts 同步 */
.backgroundLayer__L59ju {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: zoom-out;
  background-color: #ffffff;
  transition: opacity 0.2s;
  will-change: opacity;
  -webkit-tap-highlight-color: transparent; }

/*文字排版*/
.fs12 {
  font-size: 12px; }

.fs13 {
  font-size: 13px; }

.fs14 {
  font-size: 14px; }

.fs18 {
  font-size: 18px; }

.fs16 {
  font-size: 16px; }

.fs20 {
  font-size: 20px; }

.fs30 {
  font-size: 30px; }

.fs40 {
  font-size: 40px; }

.fb {
  font-weight: bold; }

.fn {
  font-weight: normal; }

.t2 {
  text-indent: 2em; }

.lh100 {
  line-height: 100%; }

.lh150 {
  line-height: 150%; }

.lh180 {
  line-height: 180%; }

.lh200 {
  line-height: 200%; }

.lh300 {
  line-height: 300%; }

.lh500 {
  line-height: 500%; }

.lh600 {
  line-height: 600%; }

.lh700 {
  line-height: 700%; }

.lh800 {
  line-height: 800%; }

.lh900 {
  line-height: 900%; }

.lh1000 {
  line-height: 1000%; }

.unl {
  text-decoration: underline; }

.no_un {
  text-decoration: none; }

/*定位*/
.tl {
  text-align: left; }

.tc {
  text-align: center; }

.tr {
  text-align: right; }

.bc {
  margin-left: auto;
  margin-right: auto; }

/*全称是“。blockCenter”，作用为使块级元素居中。直接试用她是不能使块级元素居中的，还需设定宽度。*/
.fl {
  float: left;
  display: inline; }

/*设置display：inline 为了解决IE6的双外边距bug。在IE6下，如果对元素设置了浮动，同时设置了margin-left或者margin-right，margin值会加倍，如设置margin-left：10px在IE6下会显示为margin-left：20px。解决办法就是设置display：inline。*/
.fr {
  float: right;
  display: inline; }

.cb {
  clear: both; }

.cl {
  clear: left; }

.cr {
  clear: right; }

.clearfix:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden; }

.clearfix {
  display: inline-block; }

/*用于在父容器直接清除子元素浮动。*/
* html .clearfix {
  height: 1%; }

/*只在IE6中生效*/
.Clearfix {
  display: block; }

.vm {
  vertical-align: middle; }

.pr {
  position: relative; }

.pa {
  position: absolute; }

.abs-right {
  position: absolute;
  right: 0; }

.zoom {
  zoom: 1; }

/*(IE特有属性)触发hasLayout*/
.hidden {
  visibility: hidden; }

.none {
  display: none; }

/*长度高度*/
.w10 {
  width: 10px; }

.w20 {
  width: 20px; }

.w30 {
  width: 30px; }

.w40 {
  width: 40px; }

.w50 {
  width: 50px; }

.w60 {
  width: 60px; }

.w70 {
  width: 70px; }

.w80 {
  width: 80px; }

.w90 {
  width: 90px; }

.w100 {
  width: 100px; }

.w200 {
  width: 200px; }

.w250 {
  width: 250px; }

.w300 {
  width: 300px; }

.w400 {
  width: 400px; }

.w500 {
  width: 500px; }

.w600 {
  width: 600px; }

.w700 {
  width: 700px; }

.w800 {
  width: 800px; }

.w {
  width: 100%; }

.h50 {
  height: 50px; }

.h80 {
  height: 80px; }

.h100 {
  height: 100px; }

.h200 {
  height: 200px; }

.h {
  height: 100%; }

/*边距*/
.m10 {
  margin: 10px; }

.m15 {
  margin: 15px; }

.m30 {
  margin: 30px; }

.mt5 {
  margin-top: 5px; }

.mt10 {
  margin-top: 10px; }

.mt15 {
  margin-top: 15px; }

.mt20 {
  margin-top: 20px; }

.mt30 {
  margin-top: 30px; }

.mt40 {
  margin-top: 40px; }

.mt50 {
  margin-top: 50px; }

.mt100 {
  margin-top: 100px; }

.mb5 {
  margin-bottom: 5px; }

.mb10 {
  margin-bottom: 10px; }

.mb15 {
  margin-bottom: 15px; }

.mb20 {
  margin-bottom: 20px; }

.mb30 {
  margin-bottom: 30px; }

.mb50 {
  margin-bottom: 50px; }

.mb100 {
  margin-bottom: 100px; }

.ml5 {
  margin-left: 5px; }

.ml10 {
  margin-left: 10px; }

.ml15 {
  margin-left: 15px; }

.ml20 {
  margin-left: 20px; }

.ml25 {
  margin-left: 25px; }

.ml30 {
  margin-left: 30px; }

.ml50 {
  margin-left: 50px; }

.ml100 {
  margin-left: 100px; }

.mr5 {
  margin-right: 5px; }

.mr10 {
  margin-right: 10px; }

.mr15 {
  margin-right: 15px; }

.mr20 {
  margin-right: 20px; }

.mr30 {
  margin-right: 30px; }

.mr40 {
  margin-right: 40px; }

.mr50 {
  margin-right: 50px; }

.mr100 {
  margin-right: 100px; }

.p10 {
  padding: 10px; }

.p15 {
  padding: 15px; }

.p30 {
  padding: 30px; }

.pt5 {
  padding-top: 5px; }

.pt10 {
  padding-top: 10px; }

.pt15 {
  padding-top: 15px; }

.pt20 {
  padding-top: 20px; }

.pt30 {
  padding-top: 30px; }

.pt50 {
  padding-top: 50px; }

.pt100 {
  padding-top: 100px; }

.pb5 {
  padding-bottom: 5px; }

.pb10 {
  padding-bottom: 10px; }

.pb15 {
  padding-bottom: 15px; }

.pb20 {
  padding-bottom: 20px; }

.pb30 {
  padding-bottom: 30px; }

.pb50 {
  padding-bottom: 50px; }

.pb100 {
  padding-bottom: 100px; }

.pl5 {
  padding-left: 5px; }

.pl10 {
  padding-left: 10px; }

.pl15 {
  padding-left: 15px; }

.pl20 {
  padding-left: 20px; }

.pl30 {
  padding-left: 30px; }

.pl50 {
  padding-left: 50px; }

.pl100 {
  padding-left: 100px; }

.pr5 {
  padding-right: 5px; }

.pr10 {
  padding-right: 10px; }

.pr15 {
  padding-right: 15px; }

.pr20 {
  padding-right: 20px; }

.pr30 {
  padding-right: 30px; }

.pr50 {
  padding-right: 50px; }

.pr100 {
  padding-right: 100px; }

.shadow {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); }

.br2 {
  border-radius: 2px;
  overflow: hidden; }

.br5 {
  border-radius: 5px;
  overflow: hidden; }

.br10 {
  border-radius: 10px;
  overflow: hidden; }

body {
  color: #333; }

.center-area {
  width: 1080px;
  margin-left: auto;
  margin-right: auto; }

.raise {
  color: #2CBC70; }

.fall {
  color: #CF4855; }

.white {
  color: #fff; }

.bold {
  font-weight: bold; }

p {
  margin: 0;
  padding: 0;
  margin-bottom: 0em !important;
  -webkit-margin-before: 0em;
  -webkit-margin-after: 0em; }

ul, ol, dl {
  margin-bottom: 0; }

ul, li {
  list-style: none; }

i, em, b {
  font-style: normal; }

input {
  outline: none; }

/*控制整个滚动条*/
::-webkit-scrollbar {
  background-color: #fff;
  width: 5px;
  height: 5px;
  background-clip: padding-box; }

/*滚动条两端方向按钮*/
::-webkit-scrollbar-button {
  background-color: white; }

/*滚动条中间滑动部分*/
::-webkit-scrollbar-thumb {
  background-color: lightgrey;
  border-radius: 5px; }

/*滚动条右下角区域*/
::-webkit-scrollbar-corner {
  background-color: white; }

.ant-tooltip-inner {
  background: #fff;
  color: #333; }

.ant-tooltip-arrow::before {
  background: white; }

.ant-input-number:hover {
  border-color: #F3AD00; }

.ant-input-number-focused {
  border-color: transparent; }

.wrapperLayer__25Osd {
  position: fixed;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999; }

/* 与 anim.ts 同步 */
.baseButton__18U5B {
  z-index: 1000;
  box-sizing: border-box;
  cursor: pointer; }

.baseButton__18U5B > svg {
  display: block;
  width: 2rem;
  height: 2rem; }

.controls__3BU3E {
  box-sizing: border-box;
  position: absolute;
  top: 0.6rem;
  right: 0.6rem;
  opacity: 0;
  display: flex;
  z-index: 1000;
  border-radius: 5rem;
  transform: translateX(100%);
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms; }

.controls__3BU3E.show__1tLY6 {
  opacity: 0.8;
  transform: translateX(0); }

.controls__3BU3E .pinButton__MnPeR {
  z-index: 1000;
  box-sizing: border-box;
  cursor: pointer;
  margin: 0.4em 0;
  width: 2rem;
  height: 2rem;
  transition: transform 175ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1); }

.controls__3BU3E .pinButton__MnPeR > svg {
  display: block;
  width: 2rem;
  height: 2rem; }

.controls__3BU3E .pinButton__MnPeR:hover {
  opacity: 0.8 !important;
  transform: scale(1.1) !important; }

.controls__3BU3E .pinButton__MnPeR:active {
  opacity: 1 !important;
  transform: scale(1) !important; }

.controls__3BU3E .pinButton__MnPeR:first-of-type {
  margin-left: 0.4rem; }

.controls__3BU3E .pinButton__MnPeR:last-of-type {
  margin-right: 0.4rem; }

.controls__3BU3E .rotate__1DjN2 {
  z-index: 1000;
  box-sizing: border-box;
  cursor: pointer;
  margin: 0.4em 0;
  width: 2rem;
  height: 2rem;
  transition: transform 175ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1); }

.controls__3BU3E .rotate__1DjN2 > svg {
  display: block;
  width: 2rem;
  height: 2rem; }

.controls__3BU3E .rotate__1DjN2:hover {
  opacity: 0.8 !important;
  transform: scale(1.1) !important; }

.controls__3BU3E .rotate__1DjN2:active {
  opacity: 1 !important;
  transform: scale(1) !important; }

.controls__3BU3E .rotate__1DjN2:first-of-type {
  margin-left: 0.4rem; }

.controls__3BU3E .rotate__1DjN2:last-of-type {
  margin-right: 0.4rem; }

.controls__3BU3E .rotate__1DjN2 svg {
  width: 1.75rem;
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1); }

.controls__3BU3E .rotateLeft__1KOxL {
  z-index: 1000;
  box-sizing: border-box;
  cursor: pointer;
  margin: 0.4em 0;
  width: 2rem;
  height: 2rem;
  transition: transform 175ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1); }

.controls__3BU3E .rotateLeft__1KOxL > svg {
  display: block;
  width: 2rem;
  height: 2rem; }

.controls__3BU3E .rotateLeft__1KOxL:hover {
  opacity: 0.8 !important;
  transform: scale(1.1) !important; }

.controls__3BU3E .rotateLeft__1KOxL:active {
  opacity: 1 !important;
  transform: scale(1) !important; }

.controls__3BU3E .rotateLeft__1KOxL:first-of-type {
  margin-left: 0.4rem; }

.controls__3BU3E .rotateLeft__1KOxL:last-of-type {
  margin-right: 0.4rem; }

.controls__3BU3E .rotateLeft__1KOxL svg {
  width: 1.75rem;
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1); }

.controls__3BU3E .rotateLeft__1KOxL:hover svg {
  transform: rotate(-30deg); }

.controls__3BU3E .rotateRight__3kYbB {
  z-index: 1000;
  box-sizing: border-box;
  cursor: pointer;
  margin: 0.4em 0;
  width: 2rem;
  height: 2rem;
  transition: transform 175ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1); }

.controls__3BU3E .rotateRight__3kYbB > svg {
  display: block;
  width: 2rem;
  height: 2rem; }

.controls__3BU3E .rotateRight__3kYbB:hover {
  opacity: 0.8 !important;
  transform: scale(1.1) !important; }

.controls__3BU3E .rotateRight__3kYbB:active {
  opacity: 1 !important;
  transform: scale(1) !important; }

.controls__3BU3E .rotateRight__3kYbB:first-of-type {
  margin-left: 0.4rem; }

.controls__3BU3E .rotateRight__3kYbB:last-of-type {
  margin-right: 0.4rem; }

.controls__3BU3E .rotateRight__3kYbB svg {
  width: 1.75rem;
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1); }

.controls__3BU3E .rotateRight__3kYbB:hover svg {
  transform: rotate(30deg); }

.controls__3BU3E .download__1o8td {
  z-index: 1000;
  box-sizing: border-box;
  cursor: pointer;
  margin: 0.4em 0;
  width: 2rem;
  height: 2rem;
  transition: transform 175ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1); }

.controls__3BU3E .download__1o8td > svg {
  display: block;
  width: 2rem;
  height: 2rem; }

.controls__3BU3E .download__1o8td:hover {
  opacity: 0.8 !important;
  transform: scale(1.1) !important; }

.controls__3BU3E .download__1o8td:active {
  opacity: 1 !important;
  transform: scale(1) !important; }

.controls__3BU3E .download__1o8td:first-of-type {
  margin-left: 0.4rem; }

.controls__3BU3E .download__1o8td:last-of-type {
  margin-right: 0.4rem; }

.controls__3BU3E .download__1o8td svg {
  margin-top: -0.06rem;
  width: 1.75rem; }

.controls__3BU3E .zoom__15F8y {
  z-index: 1000;
  box-sizing: border-box;
  cursor: pointer;
  margin: 0.4em 0;
  width: 2rem;
  height: 2rem;
  transition: transform 175ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1); }

.controls__3BU3E .zoom__15F8y > svg {
  display: block;
  width: 2rem;
  height: 2rem; }

.controls__3BU3E .zoom__15F8y:hover {
  opacity: 0.8 !important;
  transform: scale(1.1) !important; }

.controls__3BU3E .zoom__15F8y:active {
  opacity: 1 !important;
  transform: scale(1) !important; }

.controls__3BU3E .zoom__15F8y:first-of-type {
  margin-left: 0.4rem; }

.controls__3BU3E .zoom__15F8y:last-of-type {
  margin-right: 0.4rem; }

.controls__3BU3E .close__wP1v9 {
  z-index: 1000;
  box-sizing: border-box;
  cursor: pointer;
  margin: 0.4em 0;
  width: 2rem;
  height: 2rem;
  transition: transform 175ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1); }

.controls__3BU3E .close__wP1v9 > svg {
  display: block;
  width: 2rem;
  height: 2rem; }

.controls__3BU3E .close__wP1v9:hover {
  opacity: 0.8 !important;
  transform: scale(1.1) !important; }

.controls__3BU3E .close__wP1v9:active {
  opacity: 1 !important;
  transform: scale(1) !important; }

.controls__3BU3E .close__wP1v9:first-of-type {
  margin-left: 0.4rem; }

.controls__3BU3E .close__wP1v9:last-of-type {
  margin-right: 0.4rem; }

.sideButton__3xQsl {
  z-index: 1000;
  box-sizing: border-box;
  cursor: pointer;
  opacity: 0;
  position: absolute;
  top: 50%;
  padding: 0.4rem;
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1); }

.sideButton__3xQsl > svg {
  display: block;
  width: 2rem;
  height: 2rem; }

.sideButton__3xQsl:hover {
  opacity: 0.8 !important;
  transform: translateX(0) translateY(-50%) !important; }

.sideButton__3xQsl:active {
  opacity: 1 !important; }

.sideButton__3xQsl.show__1tLY6 {
  opacity: 0.8; }

.flipLeft__3VB5y {
  z-index: 1000;
  box-sizing: border-box;
  cursor: pointer;
  opacity: 0;
  position: absolute;
  top: 50%;
  padding: 0.4rem;
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1);
  left: 0;
  padding-left: 0.6rem;
  border-radius: 0 0.5em 0.5em 0;
  transform: translateX(-100%) translateY(-50%); }

.flipLeft__3VB5y > svg {
  display: block;
  width: 2rem;
  height: 2rem; }

.flipLeft__3VB5y:hover {
  opacity: 0.8 !important;
  transform: translateX(0) translateY(-50%) !important; }

.flipLeft__3VB5y:active {
  opacity: 1 !important; }

.flipLeft__3VB5y.show__1tLY6 {
  opacity: 0.8; }

.flipLeft__3VB5y:active {
  transform: translate(-0.2em) translateY(-50%) !important; }

.flipLeft__3VB5y.show__1tLY6 {
  opacity: 0.8;
  transform: translate(-0.2em) translateY(-50%); }

.flipRight__1EqiX {
  z-index: 1000;
  box-sizing: border-box;
  cursor: pointer;
  opacity: 0;
  position: absolute;
  top: 50%;
  padding: 0.4rem;
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1);
  right: 0;
  padding-right: 0.6rem;
  border-radius: 0.5rem 0 0 0.5rem;
  transform: translateX(100%) translateY(-50%); }

.flipRight__1EqiX > svg {
  display: block;
  width: 2rem;
  height: 2rem; }

.flipRight__1EqiX:hover {
  opacity: 0.8 !important;
  transform: translateX(0) translateY(-50%) !important; }

.flipRight__1EqiX:active {
  opacity: 1 !important; }

.flipRight__1EqiX.show__1tLY6 {
  opacity: 0.8; }

.flipRight__1EqiX:active {
  transform: translate(0.2em) translateY(-50%) !important; }

.flipRight__1EqiX.show__1tLY6 {
  opacity: 0.8;
  transform: translate(0.2em) translateY(-50%); }

.pages__IwQb3 {
  box-sizing: border-box;
  display: flex;
  position: absolute;
  left: 50%;
  bottom: 0.6rem;
  z-index: 110;
  opacity: 0;
  border-radius: 2rem;
  transform: translate(-50%, 100px);
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1); }

.pages__IwQb3.show__1tLY6 {
  opacity: 0.8;
  transform: translate(-50%, 0); }

.pages__IwQb3.mobile__1L-Sn {
  bottom: 2rem; }

.pages__IwQb3 .dot__3z2uK {
  cursor: pointer;
  margin: 0.45rem 0.25rem;
  display: block;
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 1.2rem;
  background: black;
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1), width 350ms cubic-bezier(0.6, 0, 0.1, 1); }

.pages__IwQb3 .dot__3z2uK:first-of-type {
  margin-left: 0.6rem; }

.pages__IwQb3 .dot__3z2uK:last-of-type {
  margin-right: 0.6rem; }

.pages__IwQb3 .blackDot__2Zvud {
  cursor: pointer;
  margin: 0.45rem 0.25rem;
  display: block;
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 1.2rem;
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1), width 350ms cubic-bezier(0.6, 0, 0.1, 1);
  cursor: initial;
  width: 1rem;
  background: black; }

.pages__IwQb3 .blackDot__2Zvud:first-of-type {
  margin-left: 0.6rem; }

.pages__IwQb3 .blackDot__2Zvud:last-of-type {
  margin-right: 0.6rem; }

.pages__IwQb3 .whiteDot__31yN0 {
  cursor: pointer;
  margin: 0.45rem 0.25rem;
  display: block;
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 1.2rem;
  background: black;
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1), width 350ms cubic-bezier(0.6, 0, 0.1, 1);
  background: #999; }

.pages__IwQb3 .whiteDot__31yN0:first-of-type {
  margin-left: 0.6rem; }

.pages__IwQb3 .whiteDot__31yN0:last-of-type {
  margin-right: 0.6rem; }

.pages__IwQb3 .whiteDot__31yN0:hover {
  opacity: 0.8 !important;
  transform: scale(1.1) !important; }

.pages__IwQb3 .whiteDot__31yN0:active {
  opacity: 1 !important;
  transform: scale(1) !important; }

/* 与 anim.ts 同步 */
.imageLayer__207N- {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1), -webkit-clip-path 350ms cubic-bezier(0.6, 0, 0.1, 1);
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1), clip-path 350ms cubic-bezier(0.6, 0, 0.1, 1);
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1), clip-path 350ms cubic-bezier(0.6, 0, 0.1, 1), -webkit-clip-path 350ms cubic-bezier(0.6, 0, 0.1, 1);
  position: absolute;
  left: 50%;
  top: 50%;
  will-change: transform, top, opacity, clip-path; }

.imageLayer__207N-.zooming__2StSE {
  transition-timing-function: cubic-bezier(0, 0.1, 0.1, 1);
  -ms-transition-duration: 0ms; }

.imageLayer__207N-.invalidate__lVCQH {
  opacity: 0 !important;
  pointer-events: none; }

/* 与 anim.ts 同步 */
.loadingContainer__13wfa {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: absolute;
  left: 50%;
  top: 50%;
  opacity: 0;
  transition: opacity cubic-bezier(0.6, 0, 0.1, 1) 175ms; }

.loadingContainer__13wfa.show__29ULf {
  opacity: 1; }

.loadingContainer__13wfa .reload__2xUtP {
  border: 2px solid;
  border-radius: 5px;
  font-size: 1rem;
  padding: 0.5rem;
  cursor: pointer;
  outline: none;
  transform: translate(-50%, -50%); }

.loadingContainer__13wfa .reload__2xUtP:hover {
  opacity: 0.8; }

.loadingContainer__13wfa .reload__2xUtP:hover svg {
  transform: rotate(30deg); }

.loadingContainer__13wfa .reload__2xUtP:active {
  opacity: 1; }

.loadingContainer__13wfa .reload__2xUtP svg {
  display: block;
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1); }

.loadingContainer__13wfa .loading__2jKYg {
  width: 24px;
  height: 24px;
  transform: translate(-50%, -50%);
  -webkit-animation: spin__1Iuta 1s linear infinite;
  animation: spin__1Iuta 1s linear infinite; }

@-webkit-keyframes fadeIn__3yb2M {
  0% {
    opacity: 0; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn__3yb2M {
  0% {
    opacity: 0; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes fadeOut__1lwK5 {
  0% {
    opacity: 1; }
  50% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@keyframes fadeOut__1lwK5 {
  0% {
    opacity: 1; }
  50% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@-webkit-keyframes spin__1Iuta {
  0% {
    transform: translate(-50%, -50%) rotate(0deg); }
  100% {
    transform: translate(-50%, -50%) rotate(360deg); } }

@keyframes spin__1Iuta {
  0% {
    transform: translate(-50%, -50%) rotate(0deg); }
  100% {
    transform: translate(-50%, -50%) rotate(360deg); } }

/* 与 anim.ts 同步 */
.backgroundLayer__L59ju {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: zoom-out;
  background-color: #ffffff;
  transition: opacity 0.2s;
  will-change: opacity;
  -webkit-tap-highlight-color: transparent; }

@font-face {
  font-family: 'cereal';
  src: url("./style/AirbnbCereal-Light.ttf");
  font-weight: 100; }

@font-face {
  font-family: 'cereal';
  src: url("./style/AirbnbCereal-Book.ttf");
  font-weight: 300; }

@font-face {
  font-family: 'cereal';
  src: url("./style/AirbnbCereal-Medium.ttf");
  font-weight: 500; }

@font-face {
  font-family: 'cereal';
  src: url("./style/AirbnbCereal-Bold.ttf");
  font-weight: 700; }

@font-face {
  font-family: 'cereal';
  src: url("./style/AirbnbCereal-ExtraBold.ttf");
  font-weight: 900; }

@font-face {
  font-family: DINPro;
  src: url("./style/dingp300.otf");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: DINPro;
  src: url("./style/dingp400.otf");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: DINPro;
  src: url("./style/dingp800.otf");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: DIN-Light;
  src: url("./font/DIN-Light.otf"); }

@font-face {
  font-family: DIN-Black;
  src: url("./font/DIN-Black.otf"); }

@font-face {
  font-family: DIN-Bold;
  src: url("./font/DIN-Bold.otf"); }

@font-face {
  font-family: DIN-Medium;
  src: url("./font/DIN-Medium.otf"); }

@font-face {
  font-family: DIN-Regular;
  src: url("./font/DIN-Regular.otf"); }

@font-face {
  font-family: IBM_Plex_Sans_Bold;
  src: url("./style/IBM_Plex_Sans/IBMPlexSans-Bold.ttf") format("truetype"); }

@font-face {
  font-family: IBM_Plex_Sans_Normal;
  src: url("./style/IBM_Plex_Sans/IBMPlexSans-Regular.ttf") format("truetype"); }

@font-face {
  font-family: IBM_Plex_Sans_Medium;
  src: url("./style/IBM_Plex_Sans/IBMPlexSans-Medium.ttf") format("truetype"); }

@font-face {
  font-family: IBM_Plex_Sans;
  font-weight: 100;
  font-display: swap;
  font-style: normal;
  src: url("./style/IBM_Plex_Sans/IBMPlexSans-Thin.ttf") format("truetype"); }

@font-face {
  font-family: IBM_Plex_Sans;
  font-weight: 100;
  font-display: swap;
  font-style: italic;
  src: url("./style/IBM_Plex_Sans/IBMPlexSans-ThinItalic.ttf") format("truetype"); }

@font-face {
  font-family: IBM_Plex_Sans;
  font-weight: 200;
  font-display: swap;
  font-style: normal;
  src: url("./style/IBM_Plex_Sans/IBMPlexSans-ExtraLight.ttf") format("truetype"); }

@font-face {
  font-family: IBM_Plex_Sans;
  font-weight: 200;
  font-display: swap;
  font-style: italic;
  src: url("./style/IBM_Plex_Sans/IBMPlexSans-ExtraLightItalic.ttf") format("truetype"); }

@font-face {
  font-family: IBM_Plex_Sans;
  font-weight: 300;
  font-display: swap;
  font-style: normal;
  src: url("./style/IBM_Plex_Sans/IBMPlexSans-Light.ttf") format("truetype"); }

@font-face {
  font-family: IBM_Plex_Sans;
  font-weight: 300;
  font-display: swap;
  font-style: italic;
  src: url("./style/IBM_Plex_Sans/IBMPlexSans-LightItalic.ttf") format("truetype"); }

@font-face {
  font-family: IBM_Plex_Sans;
  font-weight: 400;
  font-display: swap;
  font-style: normal;
  src: url("./style/IBM_Plex_Sans/IBMPlexSans-Regular.ttf") format("truetype"); }

@font-face {
  font-family: IBM_Plex_Sans;
  font-weight: 500;
  font-display: swap;
  font-style: normal;
  src: url("./style/IBM_Plex_Sans/IBMPlexSans-Medium.ttf") format("truetype"); }

@font-face {
  font-family: IBM_Plex_Sans;
  font-weight: 500;
  font-display: swap;
  font-style: italic;
  src: url("./style/IBM_Plex_Sans/IBMPlexSans-MediumItalic.ttf") format("truetype"); }

@font-face {
  font-family: IBM_Plex_Sans;
  font-weight: 600;
  font-display: swap;
  font-style: normal;
  src: url("./style/IBM_Plex_Sans/IBMPlexSans-SemiBold.ttf") format("truetype"); }

@font-face {
  font-family: IBM_Plex_Sans;
  font-weight: 600;
  font-display: swap;
  font-style: italic;
  src: url("./style/IBM_Plex_Sans/IBMPlexSans-SemiBoldItalic.ttf") format("truetype"); }

@font-face {
  font-family: IBM_Plex_Sans;
  font-weight: 700;
  font-display: swap;
  font-style: normal;
  src: url("./style/IBM_Plex_Sans/IBMPlexSans-Bold.ttf") format("truetype"); }

@font-face {
  font-family: IBM_Plex_Sans;
  font-weight: 700;
  font-display: swap;
  font-style: italic;
  src: url("./style/IBM_Plex_Sans/IBMPlexSans-BoldItalic.ttf") format("truetype"); }

* {
  text-decoration: none;
  box-sizing: border-box !important;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

body,
html {
  height: 100%;
  min-width: 1300px;
  font-size: 13px;
  font-family: DIN-Medium, PingFangSC-Regular, PingFang SC; }

#root {
  margin: 0 auto;
  font-family: DIN-Medium, PingFangSC-Regular, PingFang SC;
  height: 100%;
  overflow-x: hidden; }
  #root > div {
    height: 100%; }
  #root.vi-VN {
    font-family: PingFang SC, 'Source Sans Pro', sans-serif; }
  #root.ru-RU {
    font-family: Helvetica, Arial, sans-serif; }
  #root.ja-JP {
    font-family: 'Noto Sans JP', sans-serif; }

img {
  width: auto; }

input {
  border: 0; }

.message-global-content {
  vertical-align: middle; }

.message-global svg {
  vertical-align: middle;
  margin-right: 5px; }

.ant-tooltip {
  max-width: 1000px !important; }
  .ant-tooltip .ant-tooltip-inner {
    white-space: nowrap;
    word-break: keep-all; }

@charset "UTF-8";
.ant-tooltip-inner {
  background: #fff;
  color: #333; }

.ant-tooltip-arrow::before {
  background: white; }

.ant-input-number:hover {
  border-color: #F3AD00; }

.ant-input-number-focused {
  border-color: transparent; }

.wrapperLayer__25Osd {
  position: fixed;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999; }

/* 与 anim.ts 同步 */
.baseButton__18U5B {
  z-index: 1000;
  box-sizing: border-box;
  cursor: pointer; }

.baseButton__18U5B > svg {
  display: block;
  width: 2rem;
  height: 2rem; }

.controls__3BU3E {
  box-sizing: border-box;
  position: absolute;
  top: 0.6rem;
  right: 0.6rem;
  opacity: 0;
  display: flex;
  z-index: 1000;
  border-radius: 5rem;
  transform: translateX(100%);
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms; }

.controls__3BU3E.show__1tLY6 {
  opacity: 0.8;
  transform: translateX(0); }

.controls__3BU3E .pinButton__MnPeR {
  z-index: 1000;
  box-sizing: border-box;
  cursor: pointer;
  margin: 0.4em 0;
  width: 2rem;
  height: 2rem;
  transition: transform 175ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1); }

.controls__3BU3E .pinButton__MnPeR > svg {
  display: block;
  width: 2rem;
  height: 2rem; }

.controls__3BU3E .pinButton__MnPeR:hover {
  opacity: 0.8 !important;
  transform: scale(1.1) !important; }

.controls__3BU3E .pinButton__MnPeR:active {
  opacity: 1 !important;
  transform: scale(1) !important; }

.controls__3BU3E .pinButton__MnPeR:first-of-type {
  margin-left: 0.4rem; }

.controls__3BU3E .pinButton__MnPeR:last-of-type {
  margin-right: 0.4rem; }

.controls__3BU3E .rotate__1DjN2 {
  z-index: 1000;
  box-sizing: border-box;
  cursor: pointer;
  margin: 0.4em 0;
  width: 2rem;
  height: 2rem;
  transition: transform 175ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1); }

.controls__3BU3E .rotate__1DjN2 > svg {
  display: block;
  width: 2rem;
  height: 2rem; }

.controls__3BU3E .rotate__1DjN2:hover {
  opacity: 0.8 !important;
  transform: scale(1.1) !important; }

.controls__3BU3E .rotate__1DjN2:active {
  opacity: 1 !important;
  transform: scale(1) !important; }

.controls__3BU3E .rotate__1DjN2:first-of-type {
  margin-left: 0.4rem; }

.controls__3BU3E .rotate__1DjN2:last-of-type {
  margin-right: 0.4rem; }

.controls__3BU3E .rotate__1DjN2 svg {
  width: 1.75rem;
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1); }

.controls__3BU3E .rotateLeft__1KOxL {
  z-index: 1000;
  box-sizing: border-box;
  cursor: pointer;
  margin: 0.4em 0;
  width: 2rem;
  height: 2rem;
  transition: transform 175ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1); }

.controls__3BU3E .rotateLeft__1KOxL > svg {
  display: block;
  width: 2rem;
  height: 2rem; }

.controls__3BU3E .rotateLeft__1KOxL:hover {
  opacity: 0.8 !important;
  transform: scale(1.1) !important; }

.controls__3BU3E .rotateLeft__1KOxL:active {
  opacity: 1 !important;
  transform: scale(1) !important; }

.controls__3BU3E .rotateLeft__1KOxL:first-of-type {
  margin-left: 0.4rem; }

.controls__3BU3E .rotateLeft__1KOxL:last-of-type {
  margin-right: 0.4rem; }

.controls__3BU3E .rotateLeft__1KOxL svg {
  width: 1.75rem;
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1); }

.controls__3BU3E .rotateLeft__1KOxL:hover svg {
  transform: rotate(-30deg); }

.controls__3BU3E .rotateRight__3kYbB {
  z-index: 1000;
  box-sizing: border-box;
  cursor: pointer;
  margin: 0.4em 0;
  width: 2rem;
  height: 2rem;
  transition: transform 175ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1); }

.controls__3BU3E .rotateRight__3kYbB > svg {
  display: block;
  width: 2rem;
  height: 2rem; }

.controls__3BU3E .rotateRight__3kYbB:hover {
  opacity: 0.8 !important;
  transform: scale(1.1) !important; }

.controls__3BU3E .rotateRight__3kYbB:active {
  opacity: 1 !important;
  transform: scale(1) !important; }

.controls__3BU3E .rotateRight__3kYbB:first-of-type {
  margin-left: 0.4rem; }

.controls__3BU3E .rotateRight__3kYbB:last-of-type {
  margin-right: 0.4rem; }

.controls__3BU3E .rotateRight__3kYbB svg {
  width: 1.75rem;
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1); }

.controls__3BU3E .rotateRight__3kYbB:hover svg {
  transform: rotate(30deg); }

.controls__3BU3E .download__1o8td {
  z-index: 1000;
  box-sizing: border-box;
  cursor: pointer;
  margin: 0.4em 0;
  width: 2rem;
  height: 2rem;
  transition: transform 175ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1); }

.controls__3BU3E .download__1o8td > svg {
  display: block;
  width: 2rem;
  height: 2rem; }

.controls__3BU3E .download__1o8td:hover {
  opacity: 0.8 !important;
  transform: scale(1.1) !important; }

.controls__3BU3E .download__1o8td:active {
  opacity: 1 !important;
  transform: scale(1) !important; }

.controls__3BU3E .download__1o8td:first-of-type {
  margin-left: 0.4rem; }

.controls__3BU3E .download__1o8td:last-of-type {
  margin-right: 0.4rem; }

.controls__3BU3E .download__1o8td svg {
  margin-top: -0.06rem;
  width: 1.75rem; }

.controls__3BU3E .zoom__15F8y {
  z-index: 1000;
  box-sizing: border-box;
  cursor: pointer;
  margin: 0.4em 0;
  width: 2rem;
  height: 2rem;
  transition: transform 175ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1); }

.controls__3BU3E .zoom__15F8y > svg {
  display: block;
  width: 2rem;
  height: 2rem; }

.controls__3BU3E .zoom__15F8y:hover {
  opacity: 0.8 !important;
  transform: scale(1.1) !important; }

.controls__3BU3E .zoom__15F8y:active {
  opacity: 1 !important;
  transform: scale(1) !important; }

.controls__3BU3E .zoom__15F8y:first-of-type {
  margin-left: 0.4rem; }

.controls__3BU3E .zoom__15F8y:last-of-type {
  margin-right: 0.4rem; }

.controls__3BU3E .close__wP1v9 {
  z-index: 1000;
  box-sizing: border-box;
  cursor: pointer;
  margin: 0.4em 0;
  width: 2rem;
  height: 2rem;
  transition: transform 175ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1); }

.controls__3BU3E .close__wP1v9 > svg {
  display: block;
  width: 2rem;
  height: 2rem; }

.controls__3BU3E .close__wP1v9:hover {
  opacity: 0.8 !important;
  transform: scale(1.1) !important; }

.controls__3BU3E .close__wP1v9:active {
  opacity: 1 !important;
  transform: scale(1) !important; }

.controls__3BU3E .close__wP1v9:first-of-type {
  margin-left: 0.4rem; }

.controls__3BU3E .close__wP1v9:last-of-type {
  margin-right: 0.4rem; }

.sideButton__3xQsl {
  z-index: 1000;
  box-sizing: border-box;
  cursor: pointer;
  opacity: 0;
  position: absolute;
  top: 50%;
  padding: 0.4rem;
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1); }

.sideButton__3xQsl > svg {
  display: block;
  width: 2rem;
  height: 2rem; }

.sideButton__3xQsl:hover {
  opacity: 0.8 !important;
  transform: translateX(0) translateY(-50%) !important; }

.sideButton__3xQsl:active {
  opacity: 1 !important; }

.sideButton__3xQsl.show__1tLY6 {
  opacity: 0.8; }

.flipLeft__3VB5y {
  z-index: 1000;
  box-sizing: border-box;
  cursor: pointer;
  opacity: 0;
  position: absolute;
  top: 50%;
  padding: 0.4rem;
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1);
  left: 0;
  padding-left: 0.6rem;
  border-radius: 0 0.5em 0.5em 0;
  transform: translateX(-100%) translateY(-50%); }

.flipLeft__3VB5y > svg {
  display: block;
  width: 2rem;
  height: 2rem; }

.flipLeft__3VB5y:hover {
  opacity: 0.8 !important;
  transform: translateX(0) translateY(-50%) !important; }

.flipLeft__3VB5y:active {
  opacity: 1 !important; }

.flipLeft__3VB5y.show__1tLY6 {
  opacity: 0.8; }

.flipLeft__3VB5y:active {
  transform: translate(-0.2em) translateY(-50%) !important; }

.flipLeft__3VB5y.show__1tLY6 {
  opacity: 0.8;
  transform: translate(-0.2em) translateY(-50%); }

.flipRight__1EqiX {
  z-index: 1000;
  box-sizing: border-box;
  cursor: pointer;
  opacity: 0;
  position: absolute;
  top: 50%;
  padding: 0.4rem;
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1);
  right: 0;
  padding-right: 0.6rem;
  border-radius: 0.5rem 0 0 0.5rem;
  transform: translateX(100%) translateY(-50%); }

.flipRight__1EqiX > svg {
  display: block;
  width: 2rem;
  height: 2rem; }

.flipRight__1EqiX:hover {
  opacity: 0.8 !important;
  transform: translateX(0) translateY(-50%) !important; }

.flipRight__1EqiX:active {
  opacity: 1 !important; }

.flipRight__1EqiX.show__1tLY6 {
  opacity: 0.8; }

.flipRight__1EqiX:active {
  transform: translate(0.2em) translateY(-50%) !important; }

.flipRight__1EqiX.show__1tLY6 {
  opacity: 0.8;
  transform: translate(0.2em) translateY(-50%); }

.pages__IwQb3 {
  box-sizing: border-box;
  display: flex;
  position: absolute;
  left: 50%;
  bottom: 0.6rem;
  z-index: 110;
  opacity: 0;
  border-radius: 2rem;
  transform: translate(-50%, 100px);
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1); }

.pages__IwQb3.show__1tLY6 {
  opacity: 0.8;
  transform: translate(-50%, 0); }

.pages__IwQb3.mobile__1L-Sn {
  bottom: 2rem; }

.pages__IwQb3 .dot__3z2uK {
  cursor: pointer;
  margin: 0.45rem 0.25rem;
  display: block;
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 1.2rem;
  background: black;
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1), width 350ms cubic-bezier(0.6, 0, 0.1, 1); }

.pages__IwQb3 .dot__3z2uK:first-of-type {
  margin-left: 0.6rem; }

.pages__IwQb3 .dot__3z2uK:last-of-type {
  margin-right: 0.6rem; }

.pages__IwQb3 .blackDot__2Zvud {
  cursor: pointer;
  margin: 0.45rem 0.25rem;
  display: block;
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 1.2rem;
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1), width 350ms cubic-bezier(0.6, 0, 0.1, 1);
  cursor: initial;
  width: 1rem;
  background: black; }

.pages__IwQb3 .blackDot__2Zvud:first-of-type {
  margin-left: 0.6rem; }

.pages__IwQb3 .blackDot__2Zvud:last-of-type {
  margin-right: 0.6rem; }

.pages__IwQb3 .whiteDot__31yN0 {
  cursor: pointer;
  margin: 0.45rem 0.25rem;
  display: block;
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 1.2rem;
  background: black;
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1), width 350ms cubic-bezier(0.6, 0, 0.1, 1);
  background: #999; }

.pages__IwQb3 .whiteDot__31yN0:first-of-type {
  margin-left: 0.6rem; }

.pages__IwQb3 .whiteDot__31yN0:last-of-type {
  margin-right: 0.6rem; }

.pages__IwQb3 .whiteDot__31yN0:hover {
  opacity: 0.8 !important;
  transform: scale(1.1) !important; }

.pages__IwQb3 .whiteDot__31yN0:active {
  opacity: 1 !important;
  transform: scale(1) !important; }

/* 与 anim.ts 同步 */
.imageLayer__207N- {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1), -webkit-clip-path 350ms cubic-bezier(0.6, 0, 0.1, 1);
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1), clip-path 350ms cubic-bezier(0.6, 0, 0.1, 1);
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1), clip-path 350ms cubic-bezier(0.6, 0, 0.1, 1), -webkit-clip-path 350ms cubic-bezier(0.6, 0, 0.1, 1);
  position: absolute;
  left: 50%;
  top: 50%;
  will-change: transform, top, opacity, clip-path; }

.imageLayer__207N-.zooming__2StSE {
  transition-timing-function: cubic-bezier(0, 0.1, 0.1, 1);
  -ms-transition-duration: 0ms; }

.imageLayer__207N-.invalidate__lVCQH {
  opacity: 0 !important;
  pointer-events: none; }

/* 与 anim.ts 同步 */
.loadingContainer__13wfa {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: absolute;
  left: 50%;
  top: 50%;
  opacity: 0;
  transition: opacity cubic-bezier(0.6, 0, 0.1, 1) 175ms; }

.loadingContainer__13wfa.show__29ULf {
  opacity: 1; }

.loadingContainer__13wfa .reload__2xUtP {
  border: 2px solid;
  border-radius: 5px;
  font-size: 1rem;
  padding: 0.5rem;
  cursor: pointer;
  outline: none;
  transform: translate(-50%, -50%); }

.loadingContainer__13wfa .reload__2xUtP:hover {
  opacity: 0.8; }

.loadingContainer__13wfa .reload__2xUtP:hover svg {
  transform: rotate(30deg); }

.loadingContainer__13wfa .reload__2xUtP:active {
  opacity: 1; }

.loadingContainer__13wfa .reload__2xUtP svg {
  display: block;
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1); }

.loadingContainer__13wfa .loading__2jKYg {
  width: 24px;
  height: 24px;
  transform: translate(-50%, -50%);
  -webkit-animation: spin__1Iuta 1s linear infinite;
  animation: spin__1Iuta 1s linear infinite; }

@-webkit-keyframes fadeIn__3yb2M {
  0% {
    opacity: 0; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn__3yb2M {
  0% {
    opacity: 0; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes fadeOut__1lwK5 {
  0% {
    opacity: 1; }
  50% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@keyframes fadeOut__1lwK5 {
  0% {
    opacity: 1; }
  50% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@-webkit-keyframes spin__1Iuta {
  0% {
    transform: translate(-50%, -50%) rotate(0deg); }
  100% {
    transform: translate(-50%, -50%) rotate(360deg); } }

@keyframes spin__1Iuta {
  0% {
    transform: translate(-50%, -50%) rotate(0deg); }
  100% {
    transform: translate(-50%, -50%) rotate(360deg); } }

/* 与 anim.ts 同步 */
.backgroundLayer__L59ju {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: zoom-out;
  background-color: #ffffff;
  transition: opacity 0.2s;
  will-change: opacity;
  -webkit-tap-highlight-color: transparent; }

.mask {
  position: fixed;
  z-index: 999;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: block; }
  .mask .dialog {
    width: 520px;
    background-color: #FFF;
    position: relative;
    top: 5%;
    margin: 0 auto;
    border-radius: 5px;
    overflow: hidden; }
    .mask .dialog .title {
      height: 60px;
      background: #F7F7F7;
      padding: 0 20px;
      line-height: 60px;
      display: flex;
      justify-content: space-between; }
      .mask .dialog .title span {
        color: #101010; }
    .mask .dialog .top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 20px; }
      .mask .dialog .top .left {
        display: flex;
        flex-direction: row; }
        .mask .dialog .top .left .img .avatar {
          width: 58px;
          height: 58px;
          border-radius: 30px; }
        .mask .dialog .top .left .mid {
          display: flex;
          flex-direction: column;
          margin-left: 10px;
          margin-top: 5px; }
          .mask .dialog .top .left .mid .tradeName {
            color: #333333;
            font-size: 20px; }
          .mask .dialog .top .left .mid .tags {
            display: flex;
            flex-direction: row;
            margin-top: 5px; }
            .mask .dialog .top .left .mid .tags .tag, .mask .dialog .top .left .mid .tags .black .row .choose .active, .mask .dialog .black .row .choose .top .left .mid .tags .active {
              font-size: 12px;
              font-weight: 400;
              color: #858E9D;
              border-radius: 10px;
              border: 1px solid #E5E5E5;
              padding: 0 5px; }
      .mask .dialog .top .right {
        display: flex;
        justify-content: flex-end;
        flex-direction: column; }
        .mask .dialog .top .right div {
          text-align: right; }
          .mask .dialog .top .right div:last-child {
            color: #28A17C;
            margin-left: 20px;
            display: flex;
            align-items: center; }
    .mask .dialog .line {
      margin: 0 20px;
      width: calc(100% - 40px);
      height: 1px;
      background: #ECEFF1; }
    .mask .dialog .tabs {
      width: 180px;
      margin-top: 15px;
      padding: 0 20px;
      font-size: 16px; }
    .mask .dialog .hide {
      display: none; }
    .mask .dialog .channel {
      width: calc(100% - 40px);
      margin: 0 auto; }
      .mask .dialog .channel .header {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        margin-top: 5px;
        margin-bottom: 10px; }
        .mask .dialog .channel .header .left {
          font-size: 14px;
          font-weight: bold;
          color: #333; }
      .mask .dialog .channel .noted {
        color: #717a8b;
        background-color: #ebecf0;
        padding: 8px 5px;
        border-radius: 4px;
        font-size: 12px; }
    .mask .dialog .black {
      padding: 0 20px; }
      .mask .dialog .black .row {
        width: 100%; }
        .mask .dialog .black .row .header {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          height: 40px; }
          .mask .dialog .black .row .header .label {
            display: flex;
            flex-direction: row;
            justify-content: flex-start; }
            .mask .dialog .black .row .header .label img {
              margin-left: 5px; }
          .mask .dialog .black .row .header .noted {
            color: #F5465D; }
        .mask .dialog .black .row .header2 {
          display: flex;
          align-items: center;
          margin: 10px 0; }
          .mask .dialog .black .row .header2 img {
            margin-left: 5px; }
        .mask .dialog .black .row .body {
          border-radius: 4px;
          height: 36px;
          background-color: #F4F5F7; }
        .mask .dialog .black .row .noted {
          margin-top: 5px;
          color: #858e9d;
          font-size: 12px;
          text-align: left; }
        .mask .dialog .black .row .choose {
          display: flex;
          flex-direction: row;
          justify-content: space-between; }
          .mask .dialog .black .row .choose .tag, .mask .dialog .black .row .choose .active {
            width: 105px;
            height: 36px;
            color: #858E9D;
            background: #F4F5F7;
            border-radius: 4px;
            text-align: center;
            line-height: 36px;
            cursor: pointer; }
          .mask .dialog .black .row .choose .active {
            color: #101010;
            border: 1px solid #F3AD00; }
        .mask .dialog .black .row .tagRow {
          display: flex;
          flex-direction: row;
          justify-content: space-between; }
          .mask .dialog .black .row .tagRow .normal, .mask .dialog .black .row .tagRow .selected {
            width: 80px;
            color: #858E9D;
            font-size: 12px;
            height: 36px;
            background: #F4F5F7;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer; }
          .mask .dialog .black .row .tagRow .selected {
            border: 1px solid #F3AD00;
            color: #101010; }
        .mask .dialog .black .row .button {
          margin: 15px 0;
          height: 50px;
          font-size: 16px;
          color: #101010;
          background: #F3AD00;
          border-radius: 4px;
          line-height: 50px;
          text-align: center;
          cursor: pointer; }
      .mask .dialog .black .row2 {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 20px 0; }
        .mask .dialog .black .row2 .cancel, .mask .dialog .black .row2 .button {
          width: 230px;
          height: 36px;
          border-radius: 4px;
          border: 1px solid #CED4DA;
          font-size: 14px;
          font-weight: 500;
          color: #101010;
          text-align: center;
          line-height: 36px;
          cursor: pointer; }
        .mask .dialog .black .row2 .button {
          background: #F3AD00;
          border: none; }

.hide {
  display: none; }

:global(.am-stepper-input-wrap) {
  height: 48px !important;
  border: 0 !important; }

:global(.am-stepper-handler) {
  border: 0; }

:global(.am-stepper-handler-up) {
  width: 46px !important;
  height: 46px !important;
  color: transparent !important;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background: url("../../../images/svg/svgImg/handler-up.svg");
  background-size: cover; }

:global(.am-stepper-handler-down) {
  width: 46px !important;
  height: 46px !important;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  color: transparent !important;
  background: url("../../../images/svg/svgImg/handler-down.svg");
  background-size: cover; }

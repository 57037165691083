
.C-Svg {
  width: inherit;
  height: inherit;
  font-size: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;

  > div {
    align-self: center;
    display: flex;
  }
}


.imgtest-container-body {
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  z-index: 99999;

  &.fadeIn {
    .img-test-container-mask {
      background: rgba(0, 0, 0, .6);
    }

    .imgtest-container-contain {
      opacity: 1;
      width: 310px;
      height: 270px;
    }
  }

  .img-test-container-mask {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0);
    transition: all .3s ease-out;
    z-index: 0;
  }

  .imgtest-container-contain {
    background: #fff;
    width: 0;
    height: 0;
    z-index: 1;
    border-radius: 4px;
    opacity: 0;
    transition: all .3s ease-out;
    &.dark {
      background: #272727;
      // border: 1px solid #ffffff;
    }
    .imgtest-container-header {
      position: relative;
      display: flex;
      justify-content: center;
      height: 46px;
      line-height: 46px;
      border-bottom: 1px solid #EAEAEA;

      >span{
        font-size: 14px;
      }

      .C-Svg {
        position: absolute;
        margin-right: 15px;
        cursor: pointer;
        right: 0;
      }

      svg {
        width: 14px;
        height: 14px;
      }

      &.dark {
        border-bottom: 1px solid #7C7E88;
        > span {
          color: #FFFFFF;
        }

        svg {
          fill: #FFFFFF;
        }

      }
    }

    .imgtest-container-img {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      height: 36px;
      margin: 14px 0 17px;

      .C-Svg {
        position: absolute;
        right: 65px;
      }

      .imgtest-icon-refresh {
        cursor: pointer;
        &.imgtest-icon-loading {
          svg {
            animation: loading 1s linear infinite;
          }
        }
      }
    }
    .imgtest-container-input{
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      padding: 15px 30px;
      background: #F5F5F5;
      &.dark {
        background: #272727;
        color: #FFFFFF;
      }
      >input{
        width: 100%;
        font-size: 30px;
        border: 1px solid #8B9BB0;
        border-radius: 4px;
        margin: 0 5px;
        background: transparent;
        outline: none;
        -webkit-tap-highlight-color: rgba(0,0,0,0);
        text-align: center;
        &.dark {
          border: 1px solid #7C7E88;
        }
      }
    }
    .imgtest-container-submit{
      height: 40px;
      line-height: 40px;
      width: 200px;
      margin: 23px auto 26px;
      font-size: 16px;
      text-align: center;
      background: linear-gradient(to right,#FFCD6D,#FFB31F);
      color: #333333;
      border-radius: 6px;
      cursor: pointer;
      &.dark {
        background: #49CDEE;
        color: #010201;
      }
    }
  }
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
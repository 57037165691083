@charset "UTF-8";
.ant-tooltip-inner {
  background: #fff;
  color: #333; }

.ant-tooltip-arrow::before {
  background: white; }

.ant-input-number:hover {
  border-color: #F3AD00; }

.ant-input-number-focused {
  border-color: transparent; }

.wrapperLayer__25Osd {
  position: fixed;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999; }

/* 与 anim.ts 同步 */
.baseButton__18U5B {
  z-index: 1000;
  box-sizing: border-box;
  cursor: pointer; }

.baseButton__18U5B > svg {
  display: block;
  width: 2rem;
  height: 2rem; }

.controls__3BU3E {
  box-sizing: border-box;
  position: absolute;
  top: 0.6rem;
  right: 0.6rem;
  opacity: 0;
  display: flex;
  z-index: 1000;
  border-radius: 5rem;
  transform: translateX(100%);
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms; }

.controls__3BU3E.show__1tLY6 {
  opacity: 0.8;
  transform: translateX(0); }

.controls__3BU3E .pinButton__MnPeR {
  z-index: 1000;
  box-sizing: border-box;
  cursor: pointer;
  margin: 0.4em 0;
  width: 2rem;
  height: 2rem;
  transition: transform 175ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1); }

.controls__3BU3E .pinButton__MnPeR > svg {
  display: block;
  width: 2rem;
  height: 2rem; }

.controls__3BU3E .pinButton__MnPeR:hover {
  opacity: 0.8 !important;
  transform: scale(1.1) !important; }

.controls__3BU3E .pinButton__MnPeR:active {
  opacity: 1 !important;
  transform: scale(1) !important; }

.controls__3BU3E .pinButton__MnPeR:first-of-type {
  margin-left: 0.4rem; }

.controls__3BU3E .pinButton__MnPeR:last-of-type {
  margin-right: 0.4rem; }

.controls__3BU3E .rotate__1DjN2 {
  z-index: 1000;
  box-sizing: border-box;
  cursor: pointer;
  margin: 0.4em 0;
  width: 2rem;
  height: 2rem;
  transition: transform 175ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1); }

.controls__3BU3E .rotate__1DjN2 > svg {
  display: block;
  width: 2rem;
  height: 2rem; }

.controls__3BU3E .rotate__1DjN2:hover {
  opacity: 0.8 !important;
  transform: scale(1.1) !important; }

.controls__3BU3E .rotate__1DjN2:active {
  opacity: 1 !important;
  transform: scale(1) !important; }

.controls__3BU3E .rotate__1DjN2:first-of-type {
  margin-left: 0.4rem; }

.controls__3BU3E .rotate__1DjN2:last-of-type {
  margin-right: 0.4rem; }

.controls__3BU3E .rotate__1DjN2 svg {
  width: 1.75rem;
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1); }

.controls__3BU3E .rotateLeft__1KOxL {
  z-index: 1000;
  box-sizing: border-box;
  cursor: pointer;
  margin: 0.4em 0;
  width: 2rem;
  height: 2rem;
  transition: transform 175ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1); }

.controls__3BU3E .rotateLeft__1KOxL > svg {
  display: block;
  width: 2rem;
  height: 2rem; }

.controls__3BU3E .rotateLeft__1KOxL:hover {
  opacity: 0.8 !important;
  transform: scale(1.1) !important; }

.controls__3BU3E .rotateLeft__1KOxL:active {
  opacity: 1 !important;
  transform: scale(1) !important; }

.controls__3BU3E .rotateLeft__1KOxL:first-of-type {
  margin-left: 0.4rem; }

.controls__3BU3E .rotateLeft__1KOxL:last-of-type {
  margin-right: 0.4rem; }

.controls__3BU3E .rotateLeft__1KOxL svg {
  width: 1.75rem;
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1); }

.controls__3BU3E .rotateLeft__1KOxL:hover svg {
  transform: rotate(-30deg); }

.controls__3BU3E .rotateRight__3kYbB {
  z-index: 1000;
  box-sizing: border-box;
  cursor: pointer;
  margin: 0.4em 0;
  width: 2rem;
  height: 2rem;
  transition: transform 175ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1); }

.controls__3BU3E .rotateRight__3kYbB > svg {
  display: block;
  width: 2rem;
  height: 2rem; }

.controls__3BU3E .rotateRight__3kYbB:hover {
  opacity: 0.8 !important;
  transform: scale(1.1) !important; }

.controls__3BU3E .rotateRight__3kYbB:active {
  opacity: 1 !important;
  transform: scale(1) !important; }

.controls__3BU3E .rotateRight__3kYbB:first-of-type {
  margin-left: 0.4rem; }

.controls__3BU3E .rotateRight__3kYbB:last-of-type {
  margin-right: 0.4rem; }

.controls__3BU3E .rotateRight__3kYbB svg {
  width: 1.75rem;
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1); }

.controls__3BU3E .rotateRight__3kYbB:hover svg {
  transform: rotate(30deg); }

.controls__3BU3E .download__1o8td {
  z-index: 1000;
  box-sizing: border-box;
  cursor: pointer;
  margin: 0.4em 0;
  width: 2rem;
  height: 2rem;
  transition: transform 175ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1); }

.controls__3BU3E .download__1o8td > svg {
  display: block;
  width: 2rem;
  height: 2rem; }

.controls__3BU3E .download__1o8td:hover {
  opacity: 0.8 !important;
  transform: scale(1.1) !important; }

.controls__3BU3E .download__1o8td:active {
  opacity: 1 !important;
  transform: scale(1) !important; }

.controls__3BU3E .download__1o8td:first-of-type {
  margin-left: 0.4rem; }

.controls__3BU3E .download__1o8td:last-of-type {
  margin-right: 0.4rem; }

.controls__3BU3E .download__1o8td svg {
  margin-top: -0.06rem;
  width: 1.75rem; }

.controls__3BU3E .zoom__15F8y {
  z-index: 1000;
  box-sizing: border-box;
  cursor: pointer;
  margin: 0.4em 0;
  width: 2rem;
  height: 2rem;
  transition: transform 175ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1); }

.controls__3BU3E .zoom__15F8y > svg {
  display: block;
  width: 2rem;
  height: 2rem; }

.controls__3BU3E .zoom__15F8y:hover {
  opacity: 0.8 !important;
  transform: scale(1.1) !important; }

.controls__3BU3E .zoom__15F8y:active {
  opacity: 1 !important;
  transform: scale(1) !important; }

.controls__3BU3E .zoom__15F8y:first-of-type {
  margin-left: 0.4rem; }

.controls__3BU3E .zoom__15F8y:last-of-type {
  margin-right: 0.4rem; }

.controls__3BU3E .close__wP1v9 {
  z-index: 1000;
  box-sizing: border-box;
  cursor: pointer;
  margin: 0.4em 0;
  width: 2rem;
  height: 2rem;
  transition: transform 175ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1); }

.controls__3BU3E .close__wP1v9 > svg {
  display: block;
  width: 2rem;
  height: 2rem; }

.controls__3BU3E .close__wP1v9:hover {
  opacity: 0.8 !important;
  transform: scale(1.1) !important; }

.controls__3BU3E .close__wP1v9:active {
  opacity: 1 !important;
  transform: scale(1) !important; }

.controls__3BU3E .close__wP1v9:first-of-type {
  margin-left: 0.4rem; }

.controls__3BU3E .close__wP1v9:last-of-type {
  margin-right: 0.4rem; }

.sideButton__3xQsl {
  z-index: 1000;
  box-sizing: border-box;
  cursor: pointer;
  opacity: 0;
  position: absolute;
  top: 50%;
  padding: 0.4rem;
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1); }

.sideButton__3xQsl > svg {
  display: block;
  width: 2rem;
  height: 2rem; }

.sideButton__3xQsl:hover {
  opacity: 0.8 !important;
  transform: translateX(0) translateY(-50%) !important; }

.sideButton__3xQsl:active {
  opacity: 1 !important; }

.sideButton__3xQsl.show__1tLY6 {
  opacity: 0.8; }

.flipLeft__3VB5y {
  z-index: 1000;
  box-sizing: border-box;
  cursor: pointer;
  opacity: 0;
  position: absolute;
  top: 50%;
  padding: 0.4rem;
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1);
  left: 0;
  padding-left: 0.6rem;
  border-radius: 0 0.5em 0.5em 0;
  transform: translateX(-100%) translateY(-50%); }

.flipLeft__3VB5y > svg {
  display: block;
  width: 2rem;
  height: 2rem; }

.flipLeft__3VB5y:hover {
  opacity: 0.8 !important;
  transform: translateX(0) translateY(-50%) !important; }

.flipLeft__3VB5y:active {
  opacity: 1 !important; }

.flipLeft__3VB5y.show__1tLY6 {
  opacity: 0.8; }

.flipLeft__3VB5y:active {
  transform: translate(-0.2em) translateY(-50%) !important; }

.flipLeft__3VB5y.show__1tLY6 {
  opacity: 0.8;
  transform: translate(-0.2em) translateY(-50%); }

.flipRight__1EqiX {
  z-index: 1000;
  box-sizing: border-box;
  cursor: pointer;
  opacity: 0;
  position: absolute;
  top: 50%;
  padding: 0.4rem;
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1);
  right: 0;
  padding-right: 0.6rem;
  border-radius: 0.5rem 0 0 0.5rem;
  transform: translateX(100%) translateY(-50%); }

.flipRight__1EqiX > svg {
  display: block;
  width: 2rem;
  height: 2rem; }

.flipRight__1EqiX:hover {
  opacity: 0.8 !important;
  transform: translateX(0) translateY(-50%) !important; }

.flipRight__1EqiX:active {
  opacity: 1 !important; }

.flipRight__1EqiX.show__1tLY6 {
  opacity: 0.8; }

.flipRight__1EqiX:active {
  transform: translate(0.2em) translateY(-50%) !important; }

.flipRight__1EqiX.show__1tLY6 {
  opacity: 0.8;
  transform: translate(0.2em) translateY(-50%); }

.pages__IwQb3 {
  box-sizing: border-box;
  display: flex;
  position: absolute;
  left: 50%;
  bottom: 0.6rem;
  z-index: 110;
  opacity: 0;
  border-radius: 2rem;
  transform: translate(-50%, 100px);
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1); }

.pages__IwQb3.show__1tLY6 {
  opacity: 0.8;
  transform: translate(-50%, 0); }

.pages__IwQb3.mobile__1L-Sn {
  bottom: 2rem; }

.pages__IwQb3 .dot__3z2uK {
  cursor: pointer;
  margin: 0.45rem 0.25rem;
  display: block;
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 1.2rem;
  background: black;
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1), width 350ms cubic-bezier(0.6, 0, 0.1, 1); }

.pages__IwQb3 .dot__3z2uK:first-of-type {
  margin-left: 0.6rem; }

.pages__IwQb3 .dot__3z2uK:last-of-type {
  margin-right: 0.6rem; }

.pages__IwQb3 .blackDot__2Zvud {
  cursor: pointer;
  margin: 0.45rem 0.25rem;
  display: block;
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 1.2rem;
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1), width 350ms cubic-bezier(0.6, 0, 0.1, 1);
  cursor: initial;
  width: 1rem;
  background: black; }

.pages__IwQb3 .blackDot__2Zvud:first-of-type {
  margin-left: 0.6rem; }

.pages__IwQb3 .blackDot__2Zvud:last-of-type {
  margin-right: 0.6rem; }

.pages__IwQb3 .whiteDot__31yN0 {
  cursor: pointer;
  margin: 0.45rem 0.25rem;
  display: block;
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 1.2rem;
  background: black;
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1), width 350ms cubic-bezier(0.6, 0, 0.1, 1);
  background: #999; }

.pages__IwQb3 .whiteDot__31yN0:first-of-type {
  margin-left: 0.6rem; }

.pages__IwQb3 .whiteDot__31yN0:last-of-type {
  margin-right: 0.6rem; }

.pages__IwQb3 .whiteDot__31yN0:hover {
  opacity: 0.8 !important;
  transform: scale(1.1) !important; }

.pages__IwQb3 .whiteDot__31yN0:active {
  opacity: 1 !important;
  transform: scale(1) !important; }

/* 与 anim.ts 同步 */
.imageLayer__207N- {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1), -webkit-clip-path 350ms cubic-bezier(0.6, 0, 0.1, 1);
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1), clip-path 350ms cubic-bezier(0.6, 0, 0.1, 1);
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1), opacity 350ms cubic-bezier(0.6, 0, 0.1, 1), clip-path 350ms cubic-bezier(0.6, 0, 0.1, 1), -webkit-clip-path 350ms cubic-bezier(0.6, 0, 0.1, 1);
  position: absolute;
  left: 50%;
  top: 50%;
  will-change: transform, top, opacity, clip-path; }

.imageLayer__207N-.zooming__2StSE {
  transition-timing-function: cubic-bezier(0, 0.1, 0.1, 1);
  -ms-transition-duration: 0ms; }

.imageLayer__207N-.invalidate__lVCQH {
  opacity: 0 !important;
  pointer-events: none; }

/* 与 anim.ts 同步 */
.loadingContainer__13wfa {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: absolute;
  left: 50%;
  top: 50%;
  opacity: 0;
  transition: opacity cubic-bezier(0.6, 0, 0.1, 1) 175ms; }

.loadingContainer__13wfa.show__29ULf {
  opacity: 1; }

.loadingContainer__13wfa .reload__2xUtP {
  border: 2px solid;
  border-radius: 5px;
  font-size: 1rem;
  padding: 0.5rem;
  cursor: pointer;
  outline: none;
  transform: translate(-50%, -50%); }

.loadingContainer__13wfa .reload__2xUtP:hover {
  opacity: 0.8; }

.loadingContainer__13wfa .reload__2xUtP:hover svg {
  transform: rotate(30deg); }

.loadingContainer__13wfa .reload__2xUtP:active {
  opacity: 1; }

.loadingContainer__13wfa .reload__2xUtP svg {
  display: block;
  transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1); }

.loadingContainer__13wfa .loading__2jKYg {
  width: 24px;
  height: 24px;
  transform: translate(-50%, -50%);
  -webkit-animation: spin__1Iuta 1s linear infinite;
  animation: spin__1Iuta 1s linear infinite; }

@-webkit-keyframes fadeIn__3yb2M {
  0% {
    opacity: 0; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn__3yb2M {
  0% {
    opacity: 0; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes fadeOut__1lwK5 {
  0% {
    opacity: 1; }
  50% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@keyframes fadeOut__1lwK5 {
  0% {
    opacity: 1; }
  50% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@-webkit-keyframes spin__1Iuta {
  0% {
    transform: translate(-50%, -50%) rotate(0deg); }
  100% {
    transform: translate(-50%, -50%) rotate(360deg); } }

@keyframes spin__1Iuta {
  0% {
    transform: translate(-50%, -50%) rotate(0deg); }
  100% {
    transform: translate(-50%, -50%) rotate(360deg); } }

/* 与 anim.ts 同步 */
.backgroundLayer__L59ju {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: zoom-out;
  background-color: #ffffff;
  transition: opacity 0.2s;
  will-change: opacity;
  -webkit-tap-highlight-color: transparent; }

.emulate-menu {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 0;
  align-items: center; }
  .emulate-menu .indicator {
    display: none; }
  .emulate-menu.horizontal {
    flex-direction: row; }
    .emulate-menu.horizontal .emulate-menu-indicator {
      display: block;
      position: absolute;
      height: 2px;
      background: #FFD140;
      bottom: 0;
      transition: left .3s;
      width: 70px;
      left: 0; }
  .emulate-menu .emulate-menu-item {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 100%;
    padding: 0 6px;
    margin: 0 12px;
    color: #fff;
    font-size: 14px !important;
    cursor: pointer; }
    .emulate-menu .emulate-menu-item > a {
      color: #fff; }
    .emulate-menu .emulate-menu-item > .subscript {
      position: absolute;
      top: 8px;
      right: -26px;
      height: 13px;
      line-height: 13px;
      font-size: 11px;
      color: #0E1A35;
      background: linear-gradient(to right, #FFCD6D, #FFB31F);
      padding: 0 4px;
      border-radius: 100px 100px 100px 0; }
    .emulate-menu .emulate-menu-item.emulate-menu-submenu-display {
      color: #FFD140; }
      .emulate-menu .emulate-menu-item.emulate-menu-submenu-display .emulate-menu-submenu {
        transform: scaleY(1); }
    .emulate-menu .emulate-menu-item:hover .emulate-menu-submenu {
      transform: scaleY(1); }
  .emulate-menu .emulate-menu-submenu {
    position: absolute;
    background: #181A1F;
    transition: all 0.3s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transform-origin: top;
    transform: scaleY(0);
    z-index: 99;
    min-width: 100px; }
    .emulate-menu .emulate-menu-submenu .emulate-menu-item {
      width: inherit;
      min-height: 40px;
      padding: 0 6px;
      margin: 0 12px;
      white-space: nowrap;
      word-break: keep-all; }
      .emulate-menu .emulate-menu-submenu .emulate-menu-item:first-child {
        border: none; }
      .emulate-menu .emulate-menu-submenu .emulate-menu-item.emulate-menu-item-selected {
        font-weight: bold; }
